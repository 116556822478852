import { View, Text, StyleSheet } from 'react-native'
import React, {useEffect, useState} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import CustomTextInput from '../../../components/customTextInput'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'
import CustomButton from '../../../components/customButton'

const BuyWpackage = () => {

    const [numofmessages, setNumOfMessages] = useState('0')

    const styles = StyleSheet.create({
        mn:{
            marginTop:20,
            alignItems:'center'
        },
        txt:{
            fontSize:14,
            color:appColor.txheadblack,
            fontFamily:appfont.semibold
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Buy Package'} hidect={true} />
        <View style={styles.mn}>
            <CustomTextInput title={'Enter number of messages'} value={numofmessages} onchange={setNumOfMessages} keytpe={'number-pad'}/>
            <Text style={styles.txt}>Total Charges: <Text style={{color:appColor.green}}>₹{0.25 * parseInt(numofmessages)}</Text></Text>

            <CustomButton title={'Buy Now'} mt={20} ht={55} bg={appColor.primary} tc={appColor.background}/>
        </View>
    </CustomSafeArea>
  )
}

export default BuyWpackage