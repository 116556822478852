import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native'
import React from 'react'
import Icon from 'react-native-vector-icons/AntDesign';
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font';
import { useNavigation } from '@react-navigation/native';

export default function DocumentCard({data, indxnum, onpress, hide1, type}) {
    const navigation = useNavigation()

    const styles = StyleSheet.create({
        mn:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            backgroundColor:appColor.background,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
            borderRadius:10,
            marginLeft:2, 
            marginRight:2,
            paddingLeft:5,
            paddingRight:5,
            marginTop:7,
            height:60
        },
        rt:{
            flexDirection:"row",
            justifyContent:"center",
            alignItems:"center"
        },
        mdl:{
            flex:1,
            marginLeft:10,
            marginRight:10
        },
        txt1:{
            fontSize:14,
            color:appColor.txheadblack,
            fontFamily:appfont.semibold,
        },
        txt:{
            fontSize:14,
            color:appColor.txheadblack,
            fontFamily:appfont.semibold,
        },
        txt2:{
            fontSize:12,
            color:appColor.txgray,
            fontFamily:appfont.semibold,
        },
        stcard:{
            paddingTop:5,
            paddingBottom:5,
            paddingLeft:12,
            paddingRight:12,
            borderRadius:4,
            borderWidth:1,
            borderColor: data.verificationstatus ? data.verificationstatus.color : '#F29727'
        },
        sttxt:{
            fontFamily:appfont.semibold,
            color:data.verificationstatus ? data.verificationstatus.color : '#F29727',
            fontSize:10
            
        }
    })
  return (
    <TouchableOpacity style={styles.mn} onPress={() => navigation.navigate('viewfile', {filedata:data, type:type})}>
        <View>
            <Text style={styles.txt}>{indxnum+1}</Text>
        </View>
        <View style={styles.mdl}>
            <Text style={styles.txt1}>{data.category.name}</Text>
            <Text style={styles.txt2}>{data.documentNumber}</Text>
        </View>
        <View style={styles.rt}>
            {
                data.verificationstatus == null ? 
                <View style={styles.stcard}>
                <Text style={styles.sttxt}>Pending</Text>
                </View>
            :
            <View style={styles.stcard}>
                <Text style={styles.sttxt}>{data.verificationstatus.name}</Text>
            </View>
            }
        </View>
    </TouchableOpacity>
  )
}