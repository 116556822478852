import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useWindowDimensions } from "react-native";
import WelcomePage from "../webpages/WelcomePage";
import SignupPage from "../webpages/SignupPage";
import CheckUserWeb from "../webpages/CheckUserWeb";
import AppPage from "../webpages/AppPage";
import CheckVendorWeb from "../webpages/CheckVendor";
import VendorSignupPage from "../webpages/VendorSignUp/VendorSignupPage";
import BusinessCategoryAdd from "../webpages/VendorSignUp/BusinessCategory";
import MainCategoryAdd from "../webpages/VendorSignUp/MainCategory";
import VendorAppPage from "../webpages/VendorAppPage";
import PayHome from "../webpages/creditpayment/PayHome";
import PaymentVerification from "../webpages/creditpayment/PaymentVerification";
import PaymentMobileVerify from "../webpages/creditpayment/PaymentMobileVerify";
import UserSignupPage from "../webpages/usersignup/UserSignupPage";
import CheckBuyer from "../webpages/CheckBuyer";
import CustomerAppPage from "../webpages/CustomerAppPage";
import ResetPasswordWeb from "../webpages/password/ResetPasswordWeb";
import PasswordSuccess from "../webpages/password/PasswordSuccess";
import ProductRefScreen from "../webpages/referal/ProductRefScreen";

const Stack = createNativeStackNavigator()

const WebStackNavigator = () => {
    const {width} = useWindowDimensions()
    const mobileDevice = width < 600
    return (
        <Stack.Navigator
         screenOptions={{
            headerShown:false,
            tabBarShowLabel:false,
         }}
            
         >  
            <Stack.Screen
                name="Webhome"
                component={WelcomePage}
            />
            <Stack.Screen
                name="SignUp"
                component={SignupPage}
            />
            <Stack.Screen
                name="VendorSignUp"
                component={VendorSignupPage}
            />
            <Stack.Screen
                name="UserSignupPage"
                component={UserSignupPage}
            />
            <Stack.Screen
                name="Checkuser"
                component={CheckUserWeb}
            />
            <Stack.Screen
                name="Checkvendor"
                component={CheckVendorWeb}
            />
            <Stack.Screen
                name="CheckBuyer"
                component={CheckBuyer}
            />
            <Stack.Screen
                name="BusinessCatAdd"
                component={BusinessCategoryAdd}
            />
            <Stack.Screen
                name="MainCatAdd"
                component={MainCategoryAdd}
            />
            <Stack.Screen
                name="AppPage"
                component={AppPage}
            />
            <Stack.Screen
                name="VendorAppPage"
                component={VendorAppPage}
            />
            <Stack.Screen
                name="CustomerAppPage"
                component={CustomerAppPage}
            />
            <Stack.Screen
                name="ResetPasswordWeb"
                component={ResetPasswordWeb}
            />
             <Stack.Screen
                name="PasswordSuccess"
                component={PasswordSuccess}
            />
            {/** Temporary Credit Card Payment*/}
            <Stack.Screen
                name="CreditMobileVerification"
                component={PaymentMobileVerify}
            />
            <Stack.Screen
                name="PayHome"
                component={PayHome}
            />
            <Stack.Screen
                name="PayVarify"
                component={PaymentVerification}
            />
            <Stack.Screen
                name="ProductRefScreen"
                component={ProductRefScreen}
            />

        </Stack.Navigator>
    )
}


export default WebStackNavigator