import { View, Text, FlatList, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'

const NotificationPage = ({navigation}) => {
    const [pagenumber, setPageNumber] = useState(1)
    const [alertdata, setAlertData] = useState([])
    const [hasmore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const tknsts = useSelector((state) => state.appredux.userToken)


    useEffect(() => {
        if (hasmore == true) {
            function getalertdata() {
                setLoading(true)
                axios.get(`${ServerData.admin}/getalertlist/?page=${pagenumber}&catgeory_code=1&token=${tknsts}`)
                .then(response => {
                    setLoading(false)
                    if (response.data.next == null) {
                        setHasMore(false)
                    }if (response.data.results){
                        setAlertData((prevData) => [...prevData, ...response.data.results]);
                    }
                })
                .catch(error => {
                    setLoading(false)
                    setHasMore(false)
                });
            }
            getalertdata()
        }
    },[pagenumber])

    function loadMore(){
        if (hasmore == true){
            setPageNumber(pagenumber+1)
        }
    }

    const styles = StyleSheet.create({
    })

    const footerComponent = () => {
        return(
            <View>
                {
                    loading ?
                    <ActivityIndicator size='large' color={appColor.primary}/>
                    :
                    null
                }

                {
                    !loading && alertdata.length == 0 ? 
                    <Text>No alerts</Text>
                    :
                    null
                }
            </View>
        )
    }

    const renderalerts = ({item}) => {
        return(
            <TouchableOpacity style={{padding:10, backgroundColor: item.alert_status == 'UN_READ' ? '#bfdbfe' : appColor.background, borderBottomWidth:1, borderBottomColor:appColor.background}} onPress={() => navigation.navigate('NotifyDetail', {adata:item})}>
                <Text style={{fontFamily:appfont.semibold, color:appColor.txheadblack, fontSize:14}}>
                    # {item.subject}
                </Text>
                <Text numberOfLines={2}>{item.message}</Text>
            </TouchableOpacity>
        )
    }
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Notifications'} hidect={true}/>
        <FlatList 
            data={alertdata}
            renderItem={renderalerts}
            keyExtractor={(item) => item.id}
            onEndReached={loadMore}
            ListFooterComponent={footerComponent}
        />
    </CustomSafeArea>
  )
}

export default NotificationPage