import { View, Text, useWindowDimensions } from 'react-native'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import WalletPinEntryModal from '../../components/mobile/WalletPinEntryModal'

const Withdraw = ({route}) => {
    const {width} = useWindowDimensions()
    const [amount, setAmount] = useState(null)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [loading, setLoading] = useState(false)
    const navigation = useNavigation()

    const [walletpin, setWalletPin] = useState('')
    const [pinentryvisible, setPinEntryVisible] = useState(false)


    function withdrawAmount(){
        setLoading(true)
        axios.post(`${ServerData.admin}/withdrawamount/`, {
            token:tknsts,
            amount:amount,
            walletpin:walletpin,
            })
            .then(function (response) {
                if (response.data.code == 1){
                    alert(response.data.message)
                    navigation.goBack()
                }else{
                    alert(response.data.message)
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
            });
    }

    function verifidedCashbackPoint(data){
        setWalletPin(data)
        setPinEntryVisible(false)
        withdrawAmount()
    }

    function routePinchange(){
        setPinEntryVisible(false)
        navigation.navigate('ChangeWalletPin')
    }



  return (
    <CustomSafeArea>
        <WalletPinEntryModal visible={pinentryvisible} onpress={() => setPinEntryVisible(false)} onpinverify={verifidedCashbackPoint} pinchangepress={() => routePinchange()} title={'WITHDRAW'}/>
        <HeaderComponent title={'Withdraw Cashback'} hidect={true} adbtn={true} adbtntitle={'Send Withdraw Points'} onpress={() => navigation.navigate('sendCashBackScreen', {type:2})}/>
        <View style={{flex:1, paddingTop:20}}>
            <CustomTextInput title={'Enter Amount'} value={amount} onchange={setAmount} keytpe={'number-pad'}/>
            <CustomButton title={'Withdraw'} bg={appColor.primary} tc={appColor.background} ht={50} mt={10} wt={width-20} onpress={() => setPinEntryVisible(true)} ai={loading}/>
        </View>
    </CustomSafeArea>
  )
}

export default Withdraw