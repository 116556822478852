import { View, Text } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'

const CopyProduct = () => {
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Select Products'} hidect={true}/>
    </CustomSafeArea>
  )
}

export default CopyProduct