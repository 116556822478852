import { View, Text, StyleSheet, useWindowDimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import VendorInfoCard from '../../../components/VendorInfoCard'
import HomeSaleCard from '../../../components/HomeSaleCard'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'


const SingleVendor = ({route}) => {
    const {width} = useWindowDimensions()
    const {id, data} = route.params
    const navigation = useNavigation()
    const [productcount, setProductCount] = useState(null)

    const styles = StyleSheet.create({
        op:{
            marginLeft:10,
            marginRight:10,
            flexDirection:'row',
            justifyContent:'space-between',
            marginBottom:15
        }
    })


    function getVendorAdditionalDetails(){
        axios.get(`${ServerData.uri3}/signglevenordata/${id}/`)
        .then(response => {
            if(response.data.code == 1){
                setProductCount(response.data.vendordata.productcount)
            }
          })
          .catch(error => {
              console.log(error)
          });
    }
    useEffect(() => {
        getVendorAdditionalDetails()
    }, [])


  return (
    <CustomSafeArea>
        <HeaderComponent title={'Seller Dashboard'} hidect={true}/>
        <VendorInfoCard image={data.logo} name={data.storename} mobile={data.user.username} address={`${data.addressLine1}, ${data.addressLine2}, ${data.city}, ${data.state} : ${data.pincode}`}/>
        <View style={{marginTop:30}}></View>
        <View style={styles.op}>
            <HomeSaleCard title={'Verify Product'} numb={productcount} onpress={() => navigation.navigate('productScreen', {id:id})}/>
            <View style={{width:15}}></View>
            <HomeSaleCard title={'Waiver Charges'} onpress={() => navigation.navigate('CashReferReduction', {id:id, data:data})}/>
        </View>
        <View style={styles.op}>
            <HomeSaleCard title={'Verify Store & Documents'} onpress={() => navigation.navigate('sellerdetails', {id:id, data:data})}/>
            <View style={{width:15}}></View>
            <HomeSaleCard title={'Store Viewer'} onpress={() => navigation.navigate('LeadViewer', {id:id, leadtype:2})}/>
        </View>
        <View style={styles.op}>
            <HomeSaleCard title={'Product Viewer'} onpress={() => navigation.navigate('LeadViewer', {id:id, leadtype:1})}/>
            <View style={{width:15}}></View>
            <View style={{width:(width-40)/2}}></View>
        </View>
    </CustomSafeArea>
  )
}

export default SingleVendor