import { View, Text } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'

const LeaderChange = () => {
  return (
    <CustomSafeArea>
        <HeaderComponent hidect={true} title={'Leader Change Request'}/>
        <Text style={{marginTop:10, alignSelf:'center'}}>Comming Soon...</Text>
    </CustomSafeArea>
  )
}

export default LeaderChange