import { View, Text, TouchableOpacity } from 'react-native'
import React, {useState} from 'react'
import CustomButton from './customButton'
import * as DocumentPicker from 'expo-document-picker';
import Icon from 'react-native-vector-icons/AntDesign';
import appColor from '../constants/other/colors';

export default function FileUploadComponent({ onFileSelected }) {

    const [selectedFile, setSelectedFile] = useState(null);

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync();
    if (result.assets) {
      setSelectedFile(result.assets[0]);
      onFileSelected(result.assets[0]); // Call the callback function with the selected file data
    }
  };

  function cancelItemselected() {
    onFileSelected(null)
    setSelectedFile(null)
  }
  return (
    <View>
        {
            selectedFile == null ? 
            <CustomButton title={'Choose Document'} wt={200} ht={35} onpress={() => pickFile()}/>
            :
            <View style={{flexDirection:"row", alignItems:"center"}}>
                <Text numberOfLines={1} ellipsizeMode='tail' style={{flex:1}}>{selectedFile.name}</Text>
                <TouchableOpacity style={{marginLeft:10}} onPress={() => cancelItemselected()}>
                    <Icon name='closecircleo' size={20} color={appColor.red}/>
                </TouchableOpacity>
            </View>
        }
    </View>
  )
}