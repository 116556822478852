import { View, Text, TouchableOpacity, StyleSheet, useWindowDimensions, Platform } from 'react-native'
import React, {useState} from 'react'
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font'

export default function UserCard({selected, fname, lname, mobile, email, city, time, onpress}) {
    const {width} = useWindowDimensions()

    const date = new Date(time);

    // Format the date and time using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
    }).format(date);

    const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
    }).format(date);

const finaltime = `${formattedTime} ${formattedDate}`;


    const styles = StyleSheet.create({
        main:{
            width:width-48,
            alignSelf:'center',
            backgroundColor:appColor.background,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
            marginTop:5,
            borderRadius:10,
            flexDirection:'row',
            alignItems:'center',
            padding:10,
            marginBottom:5
        },
        imgv:{
            height:60,
            width:40,
            backgroundColor:appColor.primary,
            borderRadius:5
        },
        txt:{
            fontFamily:appfont.regular,
            color:appColor.txheadblack,
            fontSize:8,
            marginLeft:10
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:10,
            marginLeft:10
        },
        rbtn:{
            height:20,
            width:20,
            borderRadius:10,
            borderWidth:3,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center'
        },
        rbtn1:{
            height:10,
            width:10,
            backgroundColor:appColor.primary,
            borderRadius:5
        }
    })
  return (
    <TouchableOpacity style={styles.main} onPress={onpress}>
        <View style={styles.imgv}>
            
        </View>
        <View style={{flex:1}}>
            <Text style={styles.txt1}>{fname.substring(0,2)}XXX {lname.substring(0,2)}XX </Text>
            <Text style={styles.txt}>{mobile.substring(0 , 5)}XXXXX</Text>
            <Text style={styles.txt}>{email.substring(0 , 5)}XXXXX</Text>
            <Text style={styles.txt}>{city} {finaltime}</Text>
        </View>
    </TouchableOpacity>
  )
}