import { View, Text, useWindowDimensions, StyleSheet } from 'react-native'
import React, {useEffect, useState} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import CustomTextInput from '../../../components/customTextInput'
import CustomButton from '../../../components/customButton'
import appfont from '../../../constants/other/font'
import appColor from '../../../constants/other/colors'
import ServerData from '../../../constants/other/serverData'
import axios from 'axios'
import { useSelector } from 'react-redux'

const CashReferReduction = ({route}) => {
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const {id} = route.params;
    const [currentCB, setCurrentCB] = useState(null)
    const [currentRF, setCurrentRF] = useState(null)
    const [curerentAGS, setCurrentAGS] = useState(null)
    const [loading, setLoading] = useState(false)
    const [cshbk, setCshbk] = useState('')
    const [rfrn, setRfrn] = useState('')


    function getLimitDetails(){
        setLoading(true)
        axios.post(`${ServerData.uri3}/sellerlimitupdate/`,{
            token:tknsts,
            stage:1,
            vid:id
        })
        .then(response => {
            setLoading(false)
            if(response.data.code == 1){
                const dta = response.data.data
                setCurrentCB(dta.cashback)
                setCurrentRF(dta.referearn)
                setCurrentAGS(dta.agentsales)
            }else{
                alert(response.data.message)
            }
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }
    useEffect(() => {
        getLimitDetails()
    }, [])

    function updateLimits(){
        if(cshbk == '' || rfrn == ''){
            alert('Please enter valid details')
        }else{
            setLoading(true)
            axios.post(`${ServerData.uri3}/sellerlimitupdate/`,{
                token:tknsts,
                stage:2,
                vid:id,
                cashback:cshbk,
                refearn:rfrn
            })
            .then(response => {
                setLoading(false)
                if(response.data.code == 1){
                    alert(response.data.message)
                    getLimitDetails()
                }else{
                    alert(response.data.message)
                }
              })
              .catch(error => {
                setLoading(false)
                  console.log(error)
              });
        }
        }

    const styles = StyleSheet.create({
        mn:{
            padding:10
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12,
            textTransform:'uppercase'
        },
        mn2:{
            marginTop:5
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary,
            marginRight:10
        }
    })

  return (
    <CustomSafeArea>
        <HeaderComponent title={'Limit Reduction'} hidect={true}/>
        {
            loading ?
            null
            :
            <View style={styles.mn}>
            <Text style={styles.txt}>Current Limits</Text>
            <View style={styles.mn2}>
                <Text style={styles.txt2}>Cashback: {currentCB ? currentCB : 2.5}%</Text>
                <Text style={styles.txt2}>Refer & Earn: {currentRF ? currentRF : 2.5}%</Text>
            </View>
        </View>
        }
        <View style={styles.mn}>
            <Text style={styles.txt}>Update Limit</Text>
            <CustomTextInput title={'Cashback %'} keytpe={true} onchange={setCshbk} value={cshbk}/>
            <CustomTextInput title={'Refer & Earn%'} keytpe={true} onchange={setRfrn} value={rfrn}/>
            <CustomButton title={'Update'} bg={appColor.primary} tc={appColor.background} mt={20} onpress={() => updateLimits()} ai={loading}/>
        </View>
    </CustomSafeArea>
  )
}

export default CashReferReduction