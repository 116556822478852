import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, Image } from 'react-native'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'


export default function Splash1Comp() {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        img:{
            width:width-50,
            height:width-50,
            resizeMode:'contain'
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:24,
            textAlign:'center'
        },
        txt2:{
            fontFamily:appfont.regular,
            color:'#6E8597',
            fontSize:14,
            textAlign:'center',
            marginTop:17
        }
    })
  return (
    <View>
      <Image source={require('../../constants/img/s1.jpg')} style={styles.img} />
      <View style={{marginTop:20, justifyContent:'center', alignItems:'center', paddingLeft:24, paddingRight:24}}>
        <Text style={styles.txt1}>Boost Your Profits with Expert Assistance!</Text>
        <Text style={styles.txt2}>Empower your online business with our Partner app! Maximize your earnings and get expert guidance on selling strategies.</Text>
      </View>
    </View>
  )
}