import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";



export default function StoreListCard({onpress, title, location, cashback, type}) {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        lc:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,
            backgroundColor:appColor.background,
            borderRadius:10,
            flexDirection:'row',
            alignItems:'center',
            padding:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        imv:{
            width:width/6,
            height:width/5,
            backgroundColor:appColor.primary,
            borderRadius:10,
            marginRight:15
        },
        img:{
            width:width/6,
            height:width/5,
            borderRadius:10,
            resizeMode:'cover'
        },
        rt:{
            flex:1
        },
        tx1:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.txheadblack,
        },
        tx2:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack,
        },
        tx3:{
            marginTop:10,
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.green,
        },
        txt4:{
            fontFamily:appfont.semibold,
            color:appColor
        }
    })
    return (
        <TouchableOpacity style={styles.lc} onPress={onpress ? onpress : () => navigation.navigate('ShopInfo')}>
            <View style={styles.imv}>
                <Image source={require('../constants/img/store.jpg')} style={styles.img}/>
            </View>
            <View style={styles.rt}>
            <Text style={styles.tx1}>{title}</Text>
            <Text style={styles.tx2}>{type}</Text>
            <Text style={styles.tx2}>{location}</Text>
            </View>
        </TouchableOpacity>
    )
}