import { View, Text, StyleSheet, Image, TouchableOpacity, Linking, Platform } from 'react-native'
import React from 'react'
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font'
import Icon from 'react-native-vector-icons/Feather'
import Icon2 from 'react-native-vector-icons/FontAwesome'


export default function RepotingAgentCard({mobile, agentname, type}) {

    const styles = StyleSheet.create({
        mn:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,
            backgroundColor:(type == 1 ? appColor.primary : '#071952'),
            borderRadius:5,
            flexDirection:'row',
            alignItems:'center',
            padding:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background
        },
        lf:{
            height:60,
            width:60,
            borderRadius:30,
            marginRight:10
        },
        img:{
            height:60,
            width:60,
            borderRadius:30,
        },
        rt:{
            flex:1,
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:16,
            color:appColor.background
        },
        ss:{
            height:30,
            width:30,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.background,
            justifyContent:'center',
            alignItems:'center',
            marginLeft:10
        },
        rr:{
            flexDirection:'row'
        }
    })
  return (
    <View style={styles.mn}>
        <View style={styles.lf}>
            {
                type == 1 ?
                <Image source={require('../constants/asstes/man.png')} style={styles.img}/>
                :
                <Image source={require('../constants/asstes/role-model.png')} style={styles.img}/>
            }
        </View>
        <View style={styles.rt}>
            <Text style={styles.txt}>{type == 1 ? 'Your Leader' : 'Super Leader'}</Text>
            <Text style={styles.txt2}>{agentname ? agentname.toUpperCase() : 'UNAVAILABLE'}</Text>
        </View>
        <View style={styles.rr}>
        {
            mobile ? 
        
        <>
            <TouchableOpacity style={styles.ss}>
                <Icon name='phone-call' size={15} color={appColor.background} onPress={() => Linking.openURL(`tel:+91${mobile}`)}/>
            </TouchableOpacity>
            <TouchableOpacity style={styles.ss}>
                <Icon2 name='whatsapp' size={15} color={appColor.background} onPress={() => Linking.openURL(`https://wa.me/+91${mobile}`)}/>
            </TouchableOpacity>
        </>:
        null}
        </View>
    </View>
  )
}