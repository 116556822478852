import { View, Text } from 'react-native'
import React from 'react'

const SendWithdrawPoint = () => {
  return (
    <View>
      <Text>SendWithdrawPoint</Text>
    </View>
  )
}

export default SendWithdrawPoint