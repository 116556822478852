import React, {useState, useEffect} from 'react'
import { View, Text, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity } from 'react-native'
import HeaderComponent from '../../components/Header'
import CustomSafeArea from '../../components/CustomSfview'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import Icon from 'react-native-vector-icons/AntDesign'

const FeatureScreen = ({route}) => {
    const {width} = useWindowDimensions()
    const {id} = route.params
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [quantity, setQuantity] = useState('')
    const [cashback, setCashback] = useState('')
    const [bundleData, setBundleData] = useState([])
    const [loading, setLoading] = useState(false)

    function addBundleProduct() {
        if(quantity == '' || cashback == '') {
            alert('Enter Valid details')
        }else{
            setLoading(true)
            axios.post(`${ServerData.uri}/addProductFeature/`, {
                token:tknsts,
                pid:id,
                feature:quantity,
                description:cashback
                })
                .then(function (response) {
                    setLoading(false)
                    if(response.data.code ==1){
                        getProductFeatureLists()
                        setQuantity('')
                        setCashback('')
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                });
        }
    }

    function getProductFeatureLists() {
        setLoading(true)
        axios.get(`${ServerData.uri}/productfeatures/${id}/`)
        .then(function (response) {
            setLoading(false)
            setBundleData(response.data)
        })
        .catch(function (error) {
            setLoading(false)
            console.log(error)
        });
    }

    useEffect(() => {
        getProductFeatureLists()
    },[])

    const removeBundle = (value) => {
        axios.post(`${ServerData.uri}/deletefeature/`, {
            token:tknsts,
            fid:value,
            })
            .then(function (response) {
                if(response.data.code ==1){
                    getProductFeatureLists()
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    const styles = StyleSheet.create({
        mn:{
            alignItems:'center',
            marginTop:10
        },
        bndl:{
            backgroundColor:appColor.primary,
            width:width,
            height:30,
            marginTop:10,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:24,
            paddingRight:24
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background
        },
        txt1:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background,
            width:width/5,
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background,
            width:width/2.3,
        },
        inv:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:24,
            marginRight:24,
            marginTop:10,
            alignItems:'center',
            marginBottom:10,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray,
            paddingBottom:5
        },
        tx1:{
            width:width/5,
        },
        tx2:{
            width:width/2,
        }
    })
  return (
    <CustomSafeArea>
      <HeaderComponent title={'Add Feature'} hidect={true}/>
      <View style={styles.mn}>
        <CustomTextInput title={'Feature'} onchange={setQuantity} value={quantity}/>
        <CustomTextInput title={'Description'} onchange={setCashback} value={cashback}/>
        <CustomButton title={'Add Feature'} mt={30} ht={50} bg={appColor.primary} tc={appColor.background} wt={width/1.6} onpress={() => addBundleProduct()} ai={loading}/>
      </View>
      <View style={styles.bndl}>
        <Text style={styles.txt1}>Feature</Text>
        <Text style={styles.txt2}>Description</Text>
        <Text style={styles.txt}>Remove</Text>
      </View>
      <ScrollView>
        {
            bundleData.map((item) => {
                return(
                    <View key={item.id} style={styles.inv}>
                        <Text style={styles.tx1}>{item.name}</Text>
                        <Text style={styles.tx2}>{item.description}</Text>
                        <TouchableOpacity style={styles.rmv} onPress={() => removeBundle(item.id)}>
                            <Icon name='closecircleo' size={20} color={appColor.red}/>
                        </TouchableOpacity>
                    </View>
                )
            })
        }
      </ScrollView>
    </CustomSafeArea>
  )
}

export default FeatureScreen