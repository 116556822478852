import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, ScrollView, Linking } from 'react-native'
import React, {useState, useEffect} from 'react'
import WebSafeArea from '../../webcomponents/WebSafeArea'
import CustomTextInput from '../../../components/customTextInput'
import CustomButton from '../../../components/customButton'
import appfont from '../../../constants/other/font'
import appColor from '../../../constants/other/colors'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import Icon from 'react-native-vector-icons/Feather'

const PaymentVerification = ({route}) => {
    const {paydata} = route.params;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [modalVisible, setModalVisible] = useState(false)

    function processPayment(){
        setModalVisible(true)
        Linking.openURL(paydata.paymentlink)
    }

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24,
            marginTop:20
            
        },
        tp1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
        scrl:{
            paddingTop:20,
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        scrl1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        payverifycard:{
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.10)',
            padding:10,
            borderRadius:10,
            alignItems:'center',
            justifyContent:'center'
        },
        trttxt:{
            fontSize:10,
            color:appColor.txheadblack,
            fontFamily:appfont.semibold,
            marginRight:20,
            width:width > 600 ? width/6 : width/3
        },
        valtxt:{
            fontSize:12,
            color:appColor.txheadblack,
            fontFamily:appfont.semibold,
            textAlign:'left',
        }
    })
  return (
    <WebSafeArea>
            <View style={width > 600 ? styles.scrl1 : styles.scrl}>
            <View style={width > 600 ? styles.tp1 : styles.tp}>
                <Text style={styles.txt}>Verify Payment</Text>
                <Text style={styles.txt2}>Verify your payment details</Text>
                <Text style={styles.txt2}>Payment will be deposited in the next working working day.</Text>
            </View>
            <View style={styles.payverifycard}>
                <View>
                <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Payment Mode</Text>
                    <Text style={styles.valtxt}>{paydata.method == 1 ? 'UPI' : 'Bank Transafer'}</Text>
                </View>
                <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Payee Name</Text>
                    <Text style={styles.valtxt}>{paydata.payeename}</Text>
                </View>
                {
                    paydata.acno ?
                    <>
                    <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Account Number</Text>
                    <Text style={styles.valtxt}>{paydata.acno}</Text>
                    </View>
                    <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>IFSC</Text>
                    <Text style={styles.valtxt}>{paydata.ifsc}</Text>
                </View>
                <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Bank</Text>
                    <Text style={styles.valtxt}>{paydata.bank}</Text>
                </View>
                    </>
                    :
                    null
                }

                {
                    paydata.upi ? 
                    <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>UPI ID</Text>
                    <Text style={styles.valtxt}>{paydata.upi}</Text>
                </View>
                :
                null
                }
                <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Amount</Text>
                    <Text style={styles.valtxt}>{paydata.amount}</Text>
                </View>
                <View style={{flexDirection:"row", alignItems:'center'}}>
                    <Text style={styles.trttxt}>Service Charge</Text>
                    <Text style={styles.valtxt}>{paydata.commision}</Text>
                </View>
                </View>
            </View>

            <View style={{marginTop:20}}>
                <CustomButton title={`Make Payment ₹${paydata.amount + paydata.commision}`} bg={appColor.primary} tc={appColor.background} ht={55} wt={width > 600 ? width/3 : width-48}  ai={modalVisible} onpress={() => processPayment()}/>
            </View>
            </View>
        </WebSafeArea>
  )
}

export default PaymentVerification