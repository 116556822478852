import { View, Text, useWindowDimensions, StyleSheet } from 'react-native'
import React from 'react'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import Icon from 'react-native-vector-icons/Feather'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'
import CustomButton from '../../../components/customButton'
import { useNavigation } from '@react-navigation/native'


const UserDataSell = () => {
    const {width} = useWindowDimensions()
    const navigation = useNavigation()

    const styles = StyleSheet.create({
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.red,
            fontSize:10,
            textAlign:'center',
            alignSelf:'center'
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.green,
            fontSize:16,
            textAlign:'center',
            alignSelf:'center',
            marginBottom:10,
            marginTop:50
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'130 Crore User Data'} hidect={true}/>
        <View>
            <View style={{paddingLeft:24, paddingRight:24}}>
                <Icon name='eye-off' size={width/2} color={appColor.gray} style={{alignSelf:'center', marginTop:100}}/>
                <Text style={styles.txt2}>Price: ₹1250</Text>
                <Text style={styles.txt1}>You don't have enough cash back points to buy the user data. Please purchase points first.</Text>
            </View>
            <CustomButton title={'Buy Cashback Points'} bg={appColor.primary} tc={appColor.background} wt={width-48} mt={50} onpress={() => navigation.navigate('OnlinePayment')}/>
        </View>
    </CustomSafeArea>
  )
}

export default UserDataSell