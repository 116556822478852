import React, {useRef, useState, useEffect} from 'react'
import { View, Text, StyleSheet, Image, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import { WebView } from 'react-native-webview';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';
import CustomButton from '../../components/customButton';
import CustomTextInput from '../../components/customTextInput';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ServerData from '../../constants/other/serverData';
import { useNavigation } from '@react-navigation/native';
import WebSafeAreaView from '../../components/webViewSfView';



const ViewFileScreen = ({route}) => {
    const navigation = useNavigation()
    const webViewRef = useRef(null);
    const [zoomFactor, setZoomFactor] = useState(1);
    const {filedata, type} = route.params;
    const {width} = useWindowDimensions()
    const [loading, setLoading] = useState(false)
    const [approveStage, setApproveStage] = useState(1)
    const [rejectReason, setRejectReason] = useState('')
    const tknsts = useSelector((state) => state.appredux.userToken)



    function docdelete(){
        setLoading(true)
        axios.post(`${ServerData.admin}/deletedocument/`,{
            token:tknsts,
            docid:filedata.id,
        })
        .then(response => {
            setLoading(false)
            if(response.data.code == 1){
                alert(response.data.message)
                navigation.goBack()
            }else{
                alert(response.data.message)
            }
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }

    function deleteDocumentByUser(){
        if(filedata.verificationstatus !== null){
            if(filedata.verificationstatus.code == 1){
                alert('Document Approved, Can not be deleted')
            }else{
                docdelete()
            }
        }else{
            docdelete()
        }
    }


    function docAproveByAgent(apid) {
        setLoading(true)
        axios.post(`${ServerData.uri3}/docveryfybyagent/`,{
            token:tknsts,
            docid:filedata.id,
            apid:apid,
            reason:rejectReason,
        })
        .then(response => {
            setLoading(false)
            if(response.data.code == 1){
                alert(response.data.message)
                navigation.goBack()
            }else{
                alert(response.data.message)
            }
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }



    const handleTouchStart = (e) => {
        // Store the initial touch position
        const initialTouch = e.nativeEvent.touches[0];
        webViewRef.current.initialTouch = initialTouch;
    };

    const handleTouchMove = async (e) => {
        if (!webViewRef.current.initialTouch) return;

        const currentTouch = e.nativeEvent.touches[0];
        const initialTouch = webViewRef.current.initialTouch;

        const distanceX = currentTouch.pageX - initialTouch.pageX;
        const distanceY = currentTouch.pageY - initialTouch.pageY;

        // Calculate the zoom factor based on touch distance
        const zoomFactor = Math.sqrt(distanceX ** 2 + distanceY ** 2) / 100;

        // Wait for the WebView to finish loading
        await webViewRef.current.injectJavaScript(`
        if (document.readyState === 'complete') {
            var viewport = document.querySelector('meta[name="viewport"]');
            if (viewport) {
            var currentScale = parseFloat(viewport.getAttribute('content').match(/initial-scale=([0-9.]+)/)[1]);
            var newScale = currentScale * ${zoomFactor};
            viewport.setAttribute('content', 'initial-scale=' + newScale);
            }
        }
        true;
        `);
    };

    const handleTouchEnd = () => {
        webViewRef.current.initialTouch = null;
    };
  

    const styles = StyleSheet.create({
        webs:{
            flex:1
        },
        btmarea:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:10,
            marginRight:10
        },
        txt:{
            marginLeft:10,
            marginRight:10,
            marginBottom:5,
            fontFamily:appfont.semibold,
            fontSize:12
        },
        txt2:{
            fontSize:18
        },
        sttxt:{
            fontFamily:appfont.semibold,
            color:filedata.verificationstatus ? filedata.verificationstatus.color : '#F29727',
            fontSize:12,
            marginLeft:10,
            marginRight:10,
            marginBottom:10
        }
    })
  return (
    <WebSafeAreaView>
        <HeaderComponent title={filedata.category.name} hidect={true}/>
        <WebView 
            style={styles.webs}
            source={{ uri: filedata.file }}
        />
        <View style={{marginTop:10}}>
        <Text style={styles.txt}>{filedata.category.name} Number: <Text style={styles.txt2}>{filedata.documentNumber}</Text></Text>
        {
            filedata.verificationstatus == null ? 
            <Text style={styles.sttxt}>Status : Pending</Text>
            :
            <View>
                <Text style={styles.sttxt}>Status : {filedata.verificationstatus.name}</Text>
                {
                    filedata.verificationstatus.code == 3 ?
                    <Text style={styles.sttxt}>Reason: {filedata.rejectionReason}</Text>
                    :
                    null
                }
            </View>
        }
            {
                type == 2 ? 
                    <View>
                {
                    approveStage == 1 ? 
                    <View style={styles.btmarea}>
                        <CustomButton title={'Approve'} wt={width/2-15} bg={appColor.green} tc={appColor.background} onpress={() => docAproveByAgent(1)} ai={loading}/>
                        <CustomButton title={'Reject'} wt={width/2-15} bg={appColor.red} tc={appColor.background} onpress={() => setApproveStage(2)} ai={loading}/>
                    </View>
                :
                    <View>
                        <CustomTextInput title={'Rejection reason'} onchange={setRejectReason} value={rejectReason}/>
                        <CustomButton title={'Reject'} bg={appColor.red} tc={appColor.background} wt={width-20} onpress={() => docAproveByAgent(3)} ai={loading}/>
                    </View>
                }

                </View>
                :
                null
            }

            {
                type == 1 ? 
                <CustomButton title={'Delete'} wt={width-10} bg={appColor.red} tc={appColor.background} ai={loading} onpress={() => deleteDocumentByUser()}/>
                :
                null
            }

        </View>
    </WebSafeAreaView>
  )
}

export default ViewFileScreen