import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, ActivityIndicator } from "react-native";
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";


export default function CustomButton({onpress, title, ht, wt, bg, tc, fs, ai, mt}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        cstbtn:{
            height:(ht? ht : 45),
            width:(wt? wt : width-20),
            backgroundColor:(bg ? bg : appColor.background),
            borderRadius:5,
            justifyContent:'center',
            alignItems:'center',
            borderWidth:1,
            borderColor:appColor.gray,
            marginTop: mt ? mt : 0,
            alignSelf:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:(fs ? fs :14),
            color:(tc ? tc : appColor.primary)
        }
    })
    return (
        ai ?
        <View style={styles.cstbtn}>
            <ActivityIndicator size='large' color={appColor.background}/>
        </View>
        :
        <TouchableOpacity style={styles.cstbtn} onPress={onpress}>
            <Text style={styles.txt}>{title}</Text>
        </TouchableOpacity>
    )
}