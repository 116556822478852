import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, Platform, Image, Linking } from 'react-native'
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font'

export default function AgentCard({title, number, vfstatus, proimg, onpress, teamsize, location}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        tch:{
            width:width-48,
            backgroundColor:appColor.background,
            marginTop:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
            borderRadius:10,
            alignSelf:'center',
            flexDirection:"row",
            alignItems:"center",
            paddingTop:5,
            paddingBottom:5
        },
        pimg:{
            height:80,
            width:60,
            backgroundColor:appColor.primary,
            marginLeft:10,
            borderRadius:5
        },
        lft:{
            flex:1,
            marginLeft:10,
            marginRight:10,
            flexDirection:"row",
            justifyContent:'space-between',
        },
        title:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        num:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:10,
            marginTop:5
        },
        txt:{
            fontFamily:appfont.semibold,
            color: vfstatus == 0 ? appColor.red : appColor.green,
            fontSize:8
        },
        primg:{
            height:80,
            width:60,
            borderRadius:5,
            resizeMode:'cover'
        },
        cl:{
            borderWidth:1,
            borderColor:appColor.green,
            marginTop:10,
            borderRadius:5,
            height:18,
            width:40,
            justifyContent:'center',
            alignItems:'center'
        },
        tc:{
            fontFamily:appfont.semibold,
            color:appColor.green,
            fontSize:10
        },
        rt:{
            alignItems:'flex-end'
        }
    })
  return (
    <TouchableOpacity style={styles.tch} onPress={onpress}>
        <View style={styles.pimg}>
            {
                proimg && proimg == null ?
                null :
                <Image source={{uri:proimg}} style={styles.primg}/>
            }
        </View>
        <View style={styles.lft}>
            <View style={{flex:1, marginRight:5}}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.num}>Mob: {number}</Text>
            {
                teamsize || teamsize == 0 ? 
                <Text style={styles.num}>Team size: {teamsize}</Text>
                :
                location ?
                <Text style={styles.num}>Location: {location}</Text>
                :
                null
            }
            </View>
            <View style={styles.rt}>
            {
                vfstatus == 0 ?
                <Text style={styles.txt}>Un Verified</Text>
                :
                <Text style={styles.txt}>Verified</Text>
            }
            <TouchableOpacity style={styles.cl} onPress={() => Linking.openURL(`tel:+91${number}`)}>
                <Text style={styles.tc}>Call</Text>
            </TouchableOpacity>
            </View>
        </View>
    </TouchableOpacity>
  )
}