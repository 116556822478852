import { View, Text, StyleSheet, useWindowDimensions, Image, Linking } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation } from '@react-navigation/native';
import WebSafeArea from '../../webcomponents/WebSafeArea';
import appfont from '../../../constants/other/font';
import appColor from '../../../constants/other/colors';

const PasswordSuccess = () => {
    const {width, height} = useWindowDimensions()


    const styles = StyleSheet.create({
      mn:{
        flex:1,
        flexDirection: width > 600 ? 'row' : 'column-reverse',
      },
      img:{
        width:width >600 ? width/4 : width/3,
        height:width >600 ? width/4 :width/3,
        resizeMode:'contain'
      },
      rt:{
        width:width >600 ? width/2 :width,
        justifyContent:'center',
        alignItems:'center'
      },
      lft:{
        width: width > 600 ? width/2 : width,
        justifyContent:'center',
        paddingLeft:width > 600 ? 50 : 24,
        paddingRight:width > 600 ? 50 : 24
      },
      txt:{
        fontFamily:appfont.semibold,
        color:appColor.txheadblack,
        fontSize:30,
        
      },
      para:{
        fontFamily:appfont.regular,
        color:appColor.txheadblack,
        fontSize:18,
        marginTop:10
      },
      btn:{
        flexDirection:"row",
        justifyContent:'space-between',
        alignItems:'center',
        flexWrap:'wrap'
      },
      txt2:{
        fontFamily:appfont.semibold,
        color:appColor.green,
        fontSize:14,
        marginTop:20
      }
    })
  return (
    <WebSafeArea>
        <View style={styles.mn}>
            <View style={styles.lft}>
              <Text style={styles.txt}>Password Reset Complete</Text>
              <Text style={styles.para}>
                'We have updated your password. Please switch back to your app.'
              </Text>
            </View>
            <View style={styles.rt}>
              <Image source={require('../../../constants/img/award.gif')} style={styles.img}/>
            </View>
        </View>
    </WebSafeArea>
  )
}

export default PasswordSuccess