import { View, Text, ScrollView } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import StoreListCard from '../../../components/StoreListCard'
import { useNavigation } from '@react-navigation/native'

const CopyShopPScreen = () => {
  const navigation = useNavigation()
  return (
    <CustomSafeArea>
      <HeaderComponent title={'Select Shop'} hidect={true}/>
      <ScrollView>
      <StoreListCard title={'Sheetal Associates'} type={'Mobile Shop'} location={'Bhubaneswar'} onpress={() => navigation.navigate('copyproduct')}/>
      </ScrollView>
    </CustomSafeArea>
  )
}

export default CopyShopPScreen