import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Platform } from "react-native";
import appColor from "../constants/other/colors";
import Icon from 'react-native-vector-icons/Feather';
import Icon2 from 'react-native-vector-icons/FontAwesome';
import appfont from "../constants/other/font";




export default function StoreManageCard({onpress, title, icon, colorr, ict}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        cd:{
            width:(width-40)/2,
            backgroundColor:appColor.background,
            borderRadius:10,
            padding:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        ss:{
            height:width/10,
            width:width/10,
            backgroundColor:(colorr ? colorr : appColor.primary),
            borderRadius:5,
            justifyContent:'center',
            alignItems:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:16,
            marginTop:7
        }
    })
    return (
        <TouchableOpacity style={styles.cd} onPress={onpress}>
             <View style={styles.ss}>
             {
                ict ? 
                <Icon2 name={icon} size={width/16} color={appColor.background}/>
                :
                <Icon name={icon} size={width/16} color={appColor.background}/>
             }
             </View>
             <Text style={styles.txt}>{title}</Text>
        </TouchableOpacity>
    )
}