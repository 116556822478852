import { View, Text, StyleSheet, ScrollView, Linking } from 'react-native'
import React, { useEffect } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomButton from '../../components/customButton'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'


const NotifyDetail = ({route}) => {
    const {adata} = route.params;
    const tknsts = useSelector((state) => state.appredux.userToken)


    const styles = StyleSheet.create({
        mn:{
            padding:10
        }
    })

    useEffect(() => {
        function changeAlertStatus(){
            axios.post(`${ServerData.admin}/changealert/`, {
                token:tknsts,
                aid:adata.id
              })
              .then(response => {
              })
              .catch(error => {
                  console.log(error)
              });
        }
        changeAlertStatus()
    },[])
  return (
    <CustomSafeArea>
        <HeaderComponent hidect={true} title={adata.subject}/>
        <ScrollView style={styles.mn}>
            <Text>{adata.message}</Text>
            <View>
                <CustomButton title={'Call Agent'} mt={20} onpress={() => Linking.openURL(adata.linkingLink)}/>
            </View>
        </ScrollView>

    </CustomSafeArea>
  )
}

export default NotifyDetail