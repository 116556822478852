import { View, Text, useWindowDimensions } from 'react-native'
import React from 'react'

const ProductDetailsScreen = ({route}) => {
    const {width} = useWindowDimensions()

  return (
    <View>
      <Text>ProductDetailsScreen</Text>
    </View>
  )
}

export default ProductDetailsScreen