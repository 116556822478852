import { View, Text, ScrollView, useWindowDimensions, StyleSheet, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../../components/CustomSfview'
import HeaderComponent from '../../../../components/Header'
import CustomTextInput from '../../../../components/customTextInput'
import CustomButton from '../../../../components/customButton'
import appColor from '../../../../constants/other/colors'
import ServerData from '../../../../constants/other/serverData'
import axios from 'axios'
import appfont from '../../../../constants/other/font'
import DropdownSearch from '../../../../components/SearchableDropdown'
import { useNavigation } from '@react-navigation/native'

const VerifySellerMobile = () => {
    const navigation = useNavigation()
    const [sellerAdStage, setSellerAdStage] = useState(2)
    const {width} = useWindowDimensions()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [business, setBusiness] = useState('')
    const [gstin, setgstin] = useState('')
    const [manufacturer, setManufacturer] = useState(0)
    const [slectedid, setSelectedId] = useState(null)

    const handleItemSelect = (selectedItem) => {
        setSelectedId(selectedItem.id);
      };

    function checkuser(){
        if(mobile.length !== 10){
            alert('Please enter a valid mobile number')
        }else{
            axios.post(`${ServerData.uri3}/useraddvbyagnet/`, {
                username:mobile,
                usertype:3,
            })
            .then(function (response) {
                if(response.data.code == 1 && response.data.status == 2){
                    alert('Vendor already exsits')
                }else if(response.data.code == 1 && response.data.status !== 2){
                    setSellerAdStage(2)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
        }
    }

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginTop:10,
            marginLeft:10
        },
        rv:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:10,
            marginRight:10
        },
        btv:{
            marginLeft:10,
            marginRight:10
        },
        mv:{
            flexDirection:'row',
            alignItems:'center'
        },
        mtch1:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10,
            backgroundColor:appColor.primary
        },
        mtch2:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10
        },
        mtxt1:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:12
        },
        mtxt2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10

        },
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Add Seller: Step 1'} hidect={true}/>
        <ScrollView>
            {
                sellerAdStage == 1 ? 
                <CustomTextInput title={'Seller mobile number'} mt={10} value={mobile} onchange={setMobile} keytpe={'number-pad'}/>
                :
                <>  
                <Text style={styles.txt}>Personal Details</Text>
                <View style={styles.rv}>
                    <CustomTextInput title={'First Name'} wd={width/2-15}/>
                    <CustomTextInput title={'Last Name'} wd={width/2-15}/>
                </View>
                <CustomTextInput title={'Email'} value={email} onchange={setEmail}/>
                <Text style={styles.txt}>Business Details</Text>
                <View style={styles.btv}>
                    <CustomTextInput title={'Business Name'} value={business} onchange={setBusiness}/>
                    <CustomTextInput title={'GSTIN'} value={gstin} onchange={setgstin}/>
                    <DropdownSearch itemproperty={'typename'} title={'Business Category'} onAddClick={() => navigation.navigate('BusinessCatAdd')} onslect={handleItemSelect} urldta={`${ServerData.uri}/businesstype/`}/>
                    <Text style={styles.txt2}>Is the business a manufacturer?</Text>
                    <View style={styles.mv}>
                        <TouchableOpacity style={manufacturer == 1 ? styles.mtch1: styles.mtch2 } onPress={() => setManufacturer(1)}>
                            <Text style={manufacturer == 1 ? styles.mtxt1 : styles.mtxt2}>Yes</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={manufacturer == 0 ? styles.mtch1: styles.mtch2} onPress={() => setManufacturer(0)}>
                            <Text style={manufacturer == 0 ? styles.mtxt1 : styles.mtxt2}>No</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                </>
            }
            <CustomButton title={'Add Seller'} ht={50} bg={appColor.primary} tc={appColor.background} mt={20} onpress={() => checkuser()}/>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default VerifySellerMobile