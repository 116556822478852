import { View, Text, StyleSheet, FlatList, ActivityIndicator, useWindowDimensions } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import appColor from '../../constants/other/colors'
import CustomButton from '../../components/customButton'
import appfont from '../../constants/other/font'

const AgentChangeScreen = () => {
  const {width} = useWindowDimensions()
  const tknsts = useSelector((state) => state.appredux.userToken)
  const [loading, setLoading] = useState(false)
  const [pagenumber, setPagenumber] = useState(1)
  const [loadmore, setLoadMore] = useState(true)
  const [approvalList, setApprovalList] = useState([])
  const [btnloading, setBtnLOading] = useState(null)


  function approveLimit(rid, type){
    setBtnLOading(rid)
        axios.post(`${ServerData.uri3}/cbapprval/`,{
            token:tknsts,
            rid:rid,
            type:type
        })
        .then(response => {
            setBtnLOading(null)
            if(response.data.code == 1){
              alert(response.data.message)
              const newdata = approvalList.filter(item => item.id !== rid)
              setApprovalList(newdata)
            }
          })
          .catch(error => {
            setBtnLOading(null)
              console.log(error)
          });
  }

  const loadmorewhenEnd = () => {
      if(loadmore == true){
        setPagenumber(pagenumber+1)
      }
    }

    const renderFooter = () =>{
      return(
          <View>
              {
                  loading ? 
                  <ActivityIndicator size='large' color={appColor.primary}/>
                  :
                  <Text style={{fontFamily:appfont.semibold, fontSize:12, alignSelf:'center', marginTop:10}}>No More Approvals</Text>
              }
          </View>
      )
  }


  useEffect(() => {
    function fetchLeadList() {
        setLoading(true)
        axios.get(`${ServerData.uri3}/vendorchargewaiverlist/?page=${pagenumber}&token=${tknsts}`)
          .then(function (response) {
            setLoading(false)
            const newData = response.data.results;
            {/**
                        setApprovalList(prevList => [...prevList, ...newData])

           */}
           setApprovalList(newData)

          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
            setLoadMore(false)
          })
        }
        fetchLeadList()
  },[pagenumber])


    const renderItems = ({item}) => {
      return(
        <View style={styles.card}>
          <Text style={styles.txt1}>Seller: {item.vendor.storename}</Text>
          <Text style={styles.txt3}>Requested Limits</Text>
          <Text style={styles.txt2}>Cashback: {item.cb}%</Text>
          <Text style={styles.txt2}>Refer & Earn: {item.rf}%</Text>
          <Text style={styles.txt2}>Agent Sales Commission: {item.ags}%</Text>
          <View style={{flexDirection:'row', justifyContent:'space-between', marginTop:10}}>
            <CustomButton title={'Approve'} tc={appColor.background} bg={appColor.green} wt={width/2-20} onpress={() => approveLimit(item.id, 2)} ai={btnloading == item.id ? true : false}/>
            <CustomButton title={'Reject'} tc={appColor.background} bg={appColor.red} wt={width/2-20} onpress={() => approveLimit(item.id, 0)} ai={btnloading == item.id ? true : false}/>
          </View>
        </View>
      )
    }

    const styles = StyleSheet.create({
        card:{
          padding:10,
          marginBottom:5,
          borderBottomWidth:1,
          borderBottomColor:appColor.gray
        },
        txt1:{
          fontFamily:appfont.semibold,
          fontSize:10,
          color:appColor.txheadblack,
          textTransform:'uppercase',
          marginBottom:10
        },
        txt3:{
          fontFamily:appfont.semibold,
          fontSize:10,
          color:appColor.txheadblack,
          marginBottom:5
        },
        txt2:{
          fontFamily:appfont.semibold,
          fontSize:12,
          color:appColor.primary,
          marginLeft:20
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Pending Approvals'} hidect={true}/>
        <FlatList 
          data={approvalList}
          keyExtractor={item => item.id}
          renderItem={renderItems}
          ListFooterComponent={renderFooter}
          onEndReached={loadmorewhenEnd}
        />
    </CustomSafeArea>
  )
}

export default AgentChangeScreen