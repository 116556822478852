import { View, Text, StyleSheet, useWindowDimensions } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import QrCodeComponent from '../../components/QrCodeComponent'


const StoreIdQr = () => {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        qv:{
            flex:1,
            justifyContent:'center',
            alignItems:'center'
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Agent QR'} hidect={true}/>
        <View style={styles.qv}>
            <QrCodeComponent />
        </View>
    </CustomSafeArea>
  )
}

export default StoreIdQr