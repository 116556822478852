import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import { WebView } from 'react-native-webview';


const RestrictedItems = () => {
    const styles = StyleSheet.create({
        webs:{
            flex:1
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Restricted Items'} hidect={true} />
        <WebView 
            style={styles.webs}
            source={{ uri: 'https://www.shiprocket.in/x/prohibited-items/' }}
        />
    </CustomSafeArea>
  )
}

export default RestrictedItems