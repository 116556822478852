import { View, Text, ScrollView, useWindowDimensions, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native'
import React, { useEffect, useState } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import HeaderComponent from '../../components/Header'
import CustomButton from '../../components/customButton'
import CustomTextInput from '../../components/customTextInput'

const ChangeWalletPin = ({navigation}) => {
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [otp, setOtp] = useState('')
    const [pin, setPin] = useState('')
    const [cpin, setCpin] = useState('')
    const [loading, setLoading] = useState(false)
    const [otpid, setOtpId] = useState(null)
    const [otpsent, setOtpSent] = useState(false)
    const [timersecond, setTimerSecond] = useState(120)

    const minutes = Math.floor(timersecond / 60);
    const remainingSeconds = timersecond % 60;

    function sendOtpRequest() {
        setLoading(true)
        axios.post(`${ServerData.admin}/changewalletpin/`, {
            token:tknsts,
            stage:1
            })
            .then(function (response) {
                setLoading(false)
                if(response.data.code == 1){
                    setOtpSent(1)
                    setOtpId(response.data.id)
                }else{
                    alert('Unable to process, Please try again later')
                    navigation.goBack()
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
                alert('Unexpected Error')
            });
    }

    function changePin(){
        if(otp.length < 4){
            alert('Please enter valid OTP')
        }else if(pin.length !== 4){
            alert('Please enter valid PIN')
        }else if(pin !== cpin){
            alert('PIN and Confirm did not match')
        }else if(otpid == null){
            alert('Unable To process, Please try again later')
        }else{
            setLoading(true)
            axios.post(`${ServerData.admin}/changewalletpin/`, {
                token:tknsts,
                stage:2,
                otp:otp,
                tmpid:otpid,
                walletpin:pin
                })
                .then(function (response) {
                    setLoading(false)
                    alert(response.data.message)
                    if(response.data.code == 1){
                        navigation.goBack()
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                    alert('Unexpected Error')
                });
        }
    }

    function resendOTP(){
        if(otpid == null){
            alert('Unable to send otp')
        }else{
            setLoading(true)
            axios.post(`${ServerData.admin}/resendotp/`, {
                tmid:otpid,
                purpose:'Wallet PIN Change'
                })
                .then(function (response) {
                    setLoading(false)
                    alert(response.data.message)
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                    alert('Unexpected Error')
                });
        }
    }


    const styles = StyleSheet.create({
        mn:{
            padding:10
        },
        txt1:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.txgray,
            marginBottom:5
        },
        mn2:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },
        tch:{
            alignSelf:'flex-end'
        },
        rtxt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.primary,
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Pin Change'} hidect={true}/>
        <ScrollView>
            <View style={styles.mn}>
                <Text style={styles.txt1}>OTP has been sent to your mobile</Text>
                <CustomTextInput title={'Enter OTP'} onchange={setOtp} value={otp}/>
                {
                    loading ? 
                    <ActivityIndicator size='small' color={appColor.primary} style={{alignSelf:'flex-end'}}/>
                    :
                <View>
                {
                    otpsent ? 
                    <TouchableOpacity style={styles.tch} onPress={() => resendOTP()}>
                    <Text style={styles.rtxt}>Resend OTP</Text>
                </TouchableOpacity>
                :
                <TouchableOpacity style={styles.tch} onPress={() => sendOtpRequest()}>
                    <Text style={styles.rtxt}>Request OTP</Text>
                </TouchableOpacity>
                }
                </View>
                }
                <Text style={styles.txt1}>Set Your Wallet PIN</Text>
                <View style={styles.mn2}>
                    <CustomTextInput title={'Enter PIN'} wd={width/2-20} st={true} mx={4} keytpe={'number-pad'} onchange={setPin} value={pin}/>
                    <CustomTextInput title={'Confirm PIN'} wd={width/2-20} st={true} mx={4} keytpe={'number-pad'} onchange={setCpin} value={cpin}/>
                </View>
            </View>
        </ScrollView>
        <View style={{justifyContent:'center', alignItems:'center'}}>
            <CustomButton title={'Change PIN'} bg={appColor.primary} tc={appColor.background} wt={width-20} onpress={() => changePin()} ai={loading}/>
        </View>
    </CustomSafeArea>
  )
}

export default ChangeWalletPin