import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, Platform, Linking, Image } from 'react-native'
import React from 'react'
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font'

export default function VendorInfoCard({name, mobile, address, state, pincode, image}) {
    const {width} =useWindowDimensions()

    const styles = StyleSheet.create({
        mn:{
            marginLeft:24,
            marginRight:24,
            backgroundColor:appColor.background,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
            borderRadius:10,
            marginTop:10,
            flexDirection:'row',
            alignItems:'center',
            paddingBottom:10,
            paddingTop:10
        },
        img:{
            height:100,
            width:width/4.5,
            backgroundColor:appColor.primary,
            marginLeft:10,
            marginRight:10,
            borderRadius:10
        },
        vv:{
            flex:1,
            marginRight:10
        },
        tx1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:18,
            marginBottom:5
        },
        tx2:{
            fontFamily:appfont.regular,
            color:appColor.txheadblack,
            fontSize:12,
        },
        cc:{
            flexDirection:'row',
            alignItems:'center',
            marginTop:5
        },
        tch1:{
            paddingTop:2,
            paddingBottom:2,
            paddingRight:10,
            paddingLeft:10,
            backgroundColor:appColor.txheadblack,
            borderRadius:5,
            marginRight:5
        },
        tcx1:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:12
        },
        tch3:{
            paddingTop:2,
            paddingBottom:2,
            paddingRight:10,
            paddingLeft:10,
            backgroundColor:appColor.green,
            borderRadius:5,
            marginRight:5
        },
        imgg:{
            height:100,
            width:width/4.5,
            backgroundColor:appColor.primary,
            borderRadius:10,
        }
    })
  return (
    <View style={styles.mn}>
      <View style={styles.img}>
            {
                image ? 
                <Image source={{uri:image}} style={styles.imgg} />
                :
                null
            }
      </View>
      <View style={styles.vv}>
            <Text style={styles.tx1}>{name}</Text>
            <Text style={styles.tx2}>Mobile: {mobile}</Text>
            <Text style={styles.tx2}>Address: {address}</Text>
            <View style={styles.cc}>
                <TouchableOpacity style={styles.tch1} onPress={() => Linking.openURL(`tel:+91${mobile}`)}>
                    <Text style={styles.tcx1}>Call</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.tch3} onPress={() => Linking.openURL(`https://wa.me/+91${mobile}`)}>
                    <Text style={styles.tcx1}>Whatsapp</Text>
                </TouchableOpacity>
            </View>
      </View>
      
    </View>
  )
}