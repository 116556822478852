import React, {useState} from "react";
import { View, Text, useWindowDimensions, StyleSheet } from "react-native";
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";
import { TextInput } from "react-native-paper";





export default function CustomTextInput({value, onchange, title, wd, ht, ml, st, keytpe, mx, disable, mt}) {
    const {width} = useWindowDimensions()
    const htt = (ht ? ht : 50)
    const [stxtentry, setStextEntry] = useState(true)

    function handlesTextEntry(){
        if(stxtentry == true){
            setStextEntry(false)
        }else{
            setStextEntry(true)
        }
    }

    const wdth = (wd ? wd : width - 20)

    const styles = StyleSheet.create({
        tpt:{
            width:wdth,
            fontFamily:appfont.semibold,
            fontWeight:'500',
            color:appColor.txheadblack,
            fontSize:14,
            marginBottom:10,
            borderColor:appColor.primary,
            alignSelf:'center',
            marginTop:mt ? mt : 1,
        },
    })
    return (
            <TextInput 
                label={title}
                style={styles.tpt}
                onChangeText={onchange}
                value={value}
                placeholderTextColor={appColor.primary}
                multiline={ml? ml : false}
                secureTextEntry={st ? stxtentry : false}
                mode="outlined"
                selectionColor={appColor.primary}   
                textColor={appColor.primary}
                activeOutlineColor={appColor.primary}
                outlineColor={appColor.gray}
                keyboardType={keytpe ? "number-pad" : "default"}
                maxLength={mx ? mx: 500}
                disabled={disable ? disable : false}
                autoCapitalize="none"
                right={st ? <TextInput.Icon icon={stxtentry ? "eye-off-outline" : "eye-outline"} onPress={() => handlesTextEntry()}/> : null}
            />
    )
}