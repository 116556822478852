import React, {useState, useEffect} from 'react'
import { View, Text, StyleSheet, Button, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useNavigation } from '@react-navigation/native';


const QrScanner = () => {
  const navigation = useNavigation()
    const [hasPermission, setHasPermission] = useState(null);
    const [scanned, setScanned] = useState(false);
    const {width} = useWindowDimensions()
    

    useEffect(() => {
        const getBarCodeScannerPermissions = async () => {
          const { status } = await BarCodeScanner.requestPermissionsAsync();
          setHasPermission(status === 'granted');
        };
    
        getBarCodeScannerPermissions();
      }, []);
    
      const handleBarCodeScanned = ({ type, data }) => {
        try {
          const qrData = JSON.parse(data)
          if (qrData && typeof qrData === 'object') {
            const { type, userid } = qrData;
            if (type && userid) {
              console.log(userid)
              setScanned(true);
              navigation.navigate('Signup', {referalid:userid})
              // Add your custom logic here for handling the specific QR code
            } 
        }
      }catch (error) {
        console.log(error)
      }
      };
    
      if (hasPermission === null) {
        return <Text>Requesting for camera permission</Text>;
      }
      if (hasPermission === false) {
        return <Text>No access to camera</Text>;
      }

      const styles = StyleSheet.create({
        container:{
            width:width/1.2,
            height:width/1.2
        },
        cc:{
            flex:1,
            justifyContent:'center',
            alignItems:'center'
        }
      })
  return (
    <CustomSafeArea>
       <HeaderComponent title={'Scan Referal QR'} hidect={true}/>
        <View style={styles.cc}>
            <View style={styles.container}>
            <BarCodeScanner
                onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                style={StyleSheet.absoluteFillObject}
            />
            {scanned && <Button title={'Tap to Scan Again'} onPress={() => setScanned(false)} />}
            </View>
        </View>
    </CustomSafeArea>
  )
}

export default QrScanner