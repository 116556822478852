import React, {useState} from 'react'
import { View, Text, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import DropdownSearch from '../../components/SearchableDropdown'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import CustomTextInput from '../../components/customTextInput'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'

const MainCategoryAdd = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [catgryname, seCatygryName] = useState('')
    const [loading, setLoading] = useState(false)



    function handleCatAdd() {
      if(catgryname == '') {
        alert('Please Fill Main Category')
      }else{
        setLoading(true)
        axios.post(`${ServerData.uri}/addcatall/`, {
          addtype:1,
          catname:catgryname
          })
          .then(function (response) {
            setLoading(false)
              if(response.data.code == 1) {
                navigation.goBack()
              }
              console.log(response.data)
          })
          .catch(function (error) {
            setLoading(false)
              console.log(error)
          });
      }
    }

    const styles = StyleSheet.create({
        mn:{
            marginLeft:24,
            marginRight:24,
            marginTop:10
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent hidect={true} title={'Add Main Category'}/>
        <ScrollView>
        <View style={styles.mn}>
        <CustomTextInput title={'Main Category Name'} wd={width-48} onchange={seCatygryName} value={catgryname}/>
        <CustomButton title={'Add Main Category'} bg={appColor.primary} tc={appColor.background} ht={55} mt={20} wt={width-48} onpress={() => handleCatAdd()} ai={loading}/>
        </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default MainCategoryAdd