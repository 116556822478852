import React, {useState, useEffect} from "react";
import { View, StyleSheet, useWindowDimensions, Text, TouchableOpacity, Alert } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import { useDispatch } from "react-redux";
import { userToken } from "../../toolkit/slice";
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import * as SecureStore from 'expo-secure-store';


const CheckUserScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [mobile, setMobile] = useState()
    const [password, setPassword] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [token, setToken] = useState(null)
    const [userstatus, setUserStaus] = useState(0)
    const [chatsts, setChatsts] = useState(0)

    useEffect(() => {
        if (token !== null) {
            async function saveToken() {
                await SecureStore.setItemAsync('token', token);
                dispatch(userToken(token))
            }
            saveToken()
        }
    }, [token])

    function handleCheckUser() {
        if(userstatus == 0) {
            handleuseruser()
        }else{
            handleCreateAgent()
        }
    }


    function handleCreateAgent() {
        if(password == '') {
            alert('Please enter valid password')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/checkuser/`, {
                username:mobile,
                password:password,
                stage:2,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1) {
                        setToken(response.data.token)
                    }else{
                        alert('Invalid Password')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    console.log(error)
                });
        }
    }


    function handleuseruser() {
        if(mobile.length !== 10) {
            alert('Please enter valid mobile')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/checkuser/`, {
                username:mobile,
                stage:1,
                })
                .then(function (response) {
                    setModalVisible(false)
                    console.log(response.data)
                    if(response.data.code == 0){
                        {/** 
                        Alert.alert('G Chat', 'G Chat not installed. Please install G Chat before signup in the partner app', [
                            {
                              text: 'Cancel',
                              onPress: () => console.log('Cancel Pressed'),
                              style: 'cancel',
                            },
                            {text: 'Install G Chat', onPress: () => console.log('OK Pressed')},
                          ]);
                      
                        setChatsts(1)
                         */}
                        const userdata = {
                            'mobile':mobile,
                            'code':0
                        }
                        navigation.navigate('Signup', {data:userdata})
                    }
                    else if(response.data.code == 2){
                        alert('Partner account already exist. Please login.')
                    }
                    else if(response.data.code == 1){
                        setUserStaus(1)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    console.log(error)
                });
        }
    }

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
    })
    return(
        <CustomSafeArea>
            <View style={styles.tp}>
                <Text style={styles.txt}>Sign Up</Text>
                <Text style={styles.txt2}>{userstatus == 0 ? 'Welcome to Gibnu Parnter!' : 'User account found, enter password to create agent account.'}</Text>
            </View>
            <View style={styles.tp2}>
                
                    <CustomTextInput title={'Mobile Number (Whatsapp)'} wd={width-48} onchange={setMobile} value={mobile} disable={userstatus == 0 ? false : true} keytpe={'number-pad'}/>
                {
                    userstatus == 1 ? 
                <CustomTextInput title={'Password'} wd={width-48} st={true} onchange={setPassword} value={password}/>
                :
                    null
                }
                <View style={{marginTop:20}}>
                <CustomButton title={userstatus == 0 ? 'Proceed' : 'Create Agent'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width-48} onpress={() => handleCheckUser()} ai={modalVisible}/>
                </View>
                {
                    chatsts == 0 ?
                    <View style={styles.snv}>
                        <Text style={styles.txt1}>Already have an account</Text>
                        <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                            <Text style={styles.txt5}> Login</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <View style={styles.snv}>
                        <Text style={styles.txt1}>Install G Chat</Text>
                        <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                            <Text style={styles.txt5}> Click Here</Text>
                        </TouchableOpacity>
                    </View>
                }
            </View>
        </CustomSafeArea>
    )
}

export default CheckUserScreen