import React from 'react'
import { View, Text, StyleSheet, useWindowDimensions, Image } from 'react-native'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'


export default function Splash2Comp() {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        img:{
            width:width-50,
            height:width-50,
            resizeMode:'contain'
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:24,
            textAlign:'center'
        },
        txt2:{
            fontFamily:appfont.regular,
            color:'#6E8597',
            fontSize:14,
            textAlign:'center',
            marginTop:17
        }
    })
  return (
    <View>
      <Image source={require('../../constants/img/s2.jpg')} style={styles.img} />
      <View style={{marginTop:20, justifyContent:'center', alignItems:'center', paddingLeft:24, paddingRight:24}}>
        <Text style={styles.txt1}>Your Partner in E-Commerce Success</Text>
        <Text style={styles.txt2}>Unleash your earning potential with personalized assistance, data-driven insights, and powerful tools.</Text>
      </View>
    </View>
  )
}