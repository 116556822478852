import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions, TouchableWithoutFeedback, Keyboard, Modal, Image } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import { StatusBar } from 'expo-status-bar';
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import RNPickerSelect from 'react-native-picker-select';

import { useDispatch } from "react-redux";
import * as SecureStore from 'expo-secure-store';
import { userToken } from "../../toolkit/slice";
import DropdownSearch from "../../components/SearchableDropdown";
import { useFocusEffect } from "@react-navigation/native";



const SignUpScreen = ({route}) => {
    const referalid = route.params?.referalid;
    const userverifieddata = route.params?.data;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [modalVisible, setModalVisible] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [mobile, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [token, setToken] = useState(null)
    const dispatch = useDispatch()
    const [refid, setRefid] = useState('')
    const [otp, setOtp] = useState('')
    const [signupStage, setSignUpStage] = useState(1)
    const [tid, setTid] = useState(null)

    const [slectedid, setSelectedId] = useState(null)

    useFocusEffect(
        React.useCallback(() => {
            if (referalid) {
                setRefid(referalid)
              }
        }, [])
      );

    useEffect(() => {
        if(userverifieddata){
            if(userverifieddata.code == 0) {
                setMobile(userverifieddata.mobile)
            }
        }
    },[])


    function dismisKayboard() {
        Keyboard.dismiss()
    }

    useEffect(() => {
        if (token !== null) {
            async function saveToken() {
                await SecureStore.setItemAsync('token', token);
                dispatch(userToken(token))
            }
            saveToken()
        }
    }, [token])

    function handleStageSign() {
        if(signupStage == 1){
            handleSignUp()
        }else{
            signUpStage2()
        }
    }

    function signUpStage2(){
        if(otp.length !== 4){
            alert('Please enter correct otp.')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/agentsignup/`, {
                stage: 2,
                tid:tid,
                otp:otp,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1) {
                        console.log(response.data)
                        setToken(response.data.token)
                    }else{
                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert('Unable to login')
                    console.log(error)
                });
        }
    }

    function handleSignUp() {
        if(mobile == '' || password == '' || firstName == '' || lastName == '' || email == '') {
            alert('Please enter valid credentials')
        }
        else if(mobile.length !== 10) {
            alert('Please enter valid mobile number')
        }
        else if(password !== password2) {
            alert('Password doesnot match')
        }
        else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/agentsignup/`, {
                username:mobile,
                password:password,
                firstName:firstName,
                lastName:lastName,
                email:email,
                referid:refid,
                stage: 1,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 2) {
                        setSignUpStage(2)
                        setTid(response.data.tid)
                    }else{
                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert('Unable to login')
                    console.log(error)
                });
        }
    }

    const handleItemSelect = (selectedItem) => {
        setSelectedId(selectedItem.id);
      };
      

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        sfview:{
            flex:1
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        scrl:{
            paddingTop:20,
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10

        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
        slm:{
            flexDirection:'row',
            alignItems:'center',
            marginTop:10
        },
        sl:{
            height:40,
            width:(width-40)/3,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10
        },
        sl1:{
            height:40,
            width:(width-40)/3,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:appColor.primary,
            marginRight:10
        },
        slt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:14
        },
        slt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        dropdown:{
            height:50,
            width:width-48,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            borderRadius:10,
            paddingLeft:10,
            marginBottom:10
        },
        mv:{
            flexDirection:'row',
            alignItems:'center'
        },
        mtch1:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10,
            backgroundColor:appColor.primary
        },
        mtch2:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10
        },
        mtxt1:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:12
        },
        mtxt2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        qrimg:{
            height:30,
            width:30,
            resizeMode:'contain',
            tintColor:appColor.primary
        }
    })
    return (
        <View style={styles.main}>
                
                <StatusBar style='dark' />

        <TouchableWithoutFeedback onPress={() => dismisKayboard()}>
            <SafeAreaView style={styles.sfview}>
            <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            <View style={{flexDirection:'row', justifyContent:"space-between", alignItems:'center'}}>
                <View>
                    <Text style={styles.txt}>Signup</Text>
                    <Text style={styles.txt2}>Personal Details!</Text>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('QrScannerLogin')}>
                    <Image source={require('../../constants/img/qr-code.png')} style={styles.qrimg}/>
                </TouchableOpacity>

            </View>
            <View style={{marginTop:10}}>
                {
                    signupStage == 2 ? 
                    <CustomTextInput wd={width-48} title={'Enter OTP'} onchange={setOtp} keytpe={'number-pad'} value={otp}/>
                    :
                    <>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <CustomTextInput wd={(width-60)/2} title={'First Name*'} onchange={setFirstName}/>
                <CustomTextInput wd={(width-60)/2} title={'Last Name'} onchange={setLastName}/>
                </View>
                <CustomTextInput wd={width-48} title={'Mobile Number (Whatsapp)'} onchange={setMobile} keytpe={true} value={mobile} disable={true}/>
                <CustomTextInput wd={width-48} title={'Email*'} onchange={setEmail}/>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <CustomTextInput wd={(width-60)/2} title={'Password'} onchange={setPassword} st={true}/>
                <CustomTextInput wd={(width-60)/2} title={'Confirm Password'} st={true} onchange={setPassword2}/>
                </View>
                <Text style={styles.txt2}>Referer Details</Text>
                <CustomTextInput wd={width-48} title={'Referer Mobile Number'} onchange={setRefid} keytpe={true} value={refid}/>
                <View>
                </View>

                    </>
                }
                <View style={{height:50}}></View>
                <CustomButton title={'Sign Up'} bg={appColor.primary} tc={appColor.background} wt={width-48} ht={55} onpress={() => handleStageSign()} ai={modalVisible}/>
                <View style={styles.snv}>
                        <Text style={styles.txt1}>Change Mobile?</Text>
                        <TouchableOpacity onPress={() => navigation.goBack()}>
                            <Text style={styles.txt5}> Goback</Text>
                        </TouchableOpacity>
                    </View>
                <View style={{height:50}}></View>

            </View>
            </ScrollView>
            </SafeAreaView>
        </TouchableWithoutFeedback>
        </View>
    )
}

export default SignUpScreen