import { View, Text, ScrollView, StyleSheet } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import DocumentCard from '../../../components/DocumentCard'
import VendorInfoCard from '../../../components/VendorInfoCard'
import { useNavigation } from '@react-navigation/native'
import HomeSaleCard from '../../../components/HomeSaleCard'
import Detailcardcomponent from '../../../components/detailcardcomponent'

const SingleAgentScreen = ({route, navigation}) => {
    const {agntid, agnm, agdata, em} = route.params
    const [loading, setLoading] = useState(false)
    const [fileLists, setFileLists] = useState([])
    const [docs, setDocs] = useState(0)
    const [agntnum, setAgntnum] = useState(0)
    const [sellernum, setSellerNum] = useState(0)
    const [employeenum, setemployeenum] = useState(0)
    const [vrfStatus, setVrfStatus] = useState(false)
    const [dlpartners, setDlParnter] = useState(0)

    function getAdditionaldetails(){
        axios.post(`${ServerData.uri3}/agentdetailsfetch/`, {
            agentid:agntid
        })
        .then(response => {
            if(response.data.code == 1){
                setAgntnum(response.data.data.agnum)
                setDocs(response.data.data.docs)
                setSellerNum(response.data.data.vendor)
                setVrfStatus(response.data.data.agstatus)
                setDlParnter(response.data.data.dlpartner)
                setemployeenum(response.data.data.emnum)
            }
          })
          .catch(error => {
              console.log(error)
          });
    }

    function getFileLists() {
      axios.get(`${ServerData.admin}/useruploadeddoc/${agntid}/`)
      .then(response => {
          setFileLists(response.data)
        })
        .catch(error => {
            console.log(error)
        });
  }

  useEffect(() => {
      getFileLists()
  },[])

  useEffect(() => {
    getAdditionaldetails()
  },[])

  const styles = StyleSheet.create({
    op:{
        marginLeft:10,
        marginRight:10,
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:15
    }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={`${em ? 'Employee' : 'Agent'}: ${agnm}`} hidect={true}/>
        <ScrollView>
        <VendorInfoCard name={`${agdata.user.first_name} ${agdata.user.last_name}`} mobile={agdata.user.username} address={`${agdata.addressLine1}, ${agdata.addressLine2}, ${agdata.city}, ${agdata.state}, ${agdata.pincode}`} image={agdata.profileimage}/>
        <View style={{marginTop:30}}></View>
        <View style={styles.op}>
            <HomeSaleCard title={'Employees'} numb={employeenum} onpress={() => navigation.push('agentListView', {nm:agdata.user.first_name, usrname:agdata.user.username, type:2, emrequest:1})}/>
            <View style={{width:15}}></View>
            <HomeSaleCard title={'Agents'} numb={agntnum} onpress={() => navigation.push('agentListView', {nm:agdata.user.first_name, usrname:agdata.user.username, type:2, emrequest:0})}/>
        </View>
        <View style={styles.op}>
            <HomeSaleCard title={'Sellers/Dukaan'} onpress={() => navigation.push('vendorlistView', {usrname:agntid})} numb={sellernum}/>
            <View style={{width:15}}></View>
            <HomeSaleCard title={'Delivery Boy/Girl'} numb={0}/>
        </View>
        <View style={styles.op}>
            <HomeSaleCard title={'Earning'} onpress={() => navigation.push('earnings', {id: agdata.id})}/>
            <View style={{width:15}}></View>
            <HomeSaleCard title={'Change Leader'} onpress={() => navigation.navigate('AgentChangeByLeader', {agentid:agntid})}/>
            <View></View>
        </View>
        <View style={{paddingLeft:10}}>
        <HomeSaleCard title={'Verify Documents'} numb={docs} onpress={() => navigation.navigate('agentdocuments', {agntid:agntid, agnm:agnm})}/>
        </View>
        <View style={{marginLeft:10, marginRight:10}}>
        </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default SingleAgentScreen