import { View, Text } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'

const document = [
  {
    'id':1,
    'name':'Pan Card'
  },
  {
    'id':2,
    'name':'Gst'
  },
]

const DocumentAddPage = () => {
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Verify Shop'} hidect={true}/>
        
    </CustomSafeArea>
  )
}

export default DocumentAddPage