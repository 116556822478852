import { View, Text, ScrollView, StyleSheet } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import { useSelector } from 'react-redux'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'

const AgentCreditCardTransaction = ({route}) => {
    const {linkcode} = route.params;
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [loading, setLoading] = useState(false)
    const [transactionData, setTransactionData] = useState([])


    function getTransactionLists(){
        setLoading(true)
        axios.get(`${ServerData.uri3}/transactionbylinkcode/?token=${tknsts}&linkcode=${linkcode}`)
            .then(function (response) {
                setLoading(false)
                setTransactionData(response.data)
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
            });
    }

    useEffect(() => {
        getTransactionLists()
    },[])

    const styles = StyleSheet.create({
        scrl:{
            flex:1
        },
        mn:{
            flex:1
        },
        hd:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            backgroundColor:appColor.green,
            padding:10,
            marginTop:10
        },
        hd1:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            padding:10,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray
        },
        htxt:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.background,
            flex:1
        },
        htxt1:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.txheadblack,
            flex:1
        },
        htxt2:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.txheadblack,
        },
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={`Income for ${linkcode}`} hidect={true}/>
        <ScrollView style={styles.scrl}>
        <View style={styles.mn}>
                <View style={styles.hd}>
                    <Text style={styles.htxt}>ID</Text>
                    <Text style={[styles.htxt, {textAlign:'center'}]}>AMOUNT</Text>
                    <Text style={[styles.htxt, {textAlign:'right'}]}>COMMISION</Text>
                </View>
                {
                    transactionData.map((item) => {
                        return(
                            <View style={styles.hd1} key={item.id}>
                                <Text style={styles.htxt1}>{item.id}</Text>
                                <Text style={styles.htxt1}>{item.transferamount}</Text>
                                <Text style={styles.htxt2}>{item.agentcommison}</Text>
                            </View>
                        )
                    })
                }
            </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default AgentCreditCardTransaction