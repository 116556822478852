import React from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import Icon from 'react-native-vector-icons/AntDesign';
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomButton from "../../components/customButton";
import HeaderComponent from "../../components/Header";
import IconButton from "../../components/IconButton";

import { useDispatch, useSelector } from "react-redux";
import * as SecureStore from 'expo-secure-store';
import { userToken, storeData } from "../../toolkit/slice";
import ServerData from "../../constants/other/serverData";
import AsyncStorage from '@react-native-async-storage/async-storage';

const ldrchng =             {
    'id':31,
    'icon':'deleteuser',
    'name':'Leader Change',
    'route':'LeaderChange'
}

const pdata = [
    {
        'id':1,
        'name':'Account Settings',
        'subp':[
            {
                'id':11,
                'icon':'team',
                'name':'QR Code & Referral Link',
                'route':'StoreID'
            },
            {
                'id':1,
                'icon':'user',
                'name':'Edit Profile',
                'route':'editprofile'
            },
            {
                'id':2,
                'icon':'enviromento',
                'name':'Edit Address',
                'route':'editaddress'
            },
            {
                'id':12,
                'icon':'book',
                'name':'Bank Account Details',
                'route':'addbankaccount'
            },
            {
                'id':3,
                'icon':'paperclip',
                'name':'Upload Documents',
                'route':'uploaddoc'
            },

        ]
    },
    {
        'id':3,
        'name':'Information & Policy',
        'subp':[
            {
                'id':6,
                'icon':'setting',
                'name':'Terms & Conditions',
                'route':'policyview',
                'uri':`${ServerData.admin}/privacy/`
            },
            {
                'id':7,
                'icon':'lock',
                'name':'Privacy Policy',
                'route':'policyview',
                'uri':`${ServerData.admin}/privacy/`
            },
            {
                'id':8,
                'icon':'closecircleo',
                'name':'Return & Refund Policy',
                'route':'policyview',
                'uri':`${ServerData.admin}/privacy/`
            },
            {
                'id':9,
                'icon':'minuscircleo',
                'name':'Disclaimer',
                'route':'policyview',
                'uri':`${ServerData.admin}/privacy/`
            },
            {
                'id':10,
                'icon':'questioncircleo',
                'name':'Browse FAQs',
                'route':'policyview',
                'uri':`${ServerData.admin}/privacy/`
            },
        ]
    },

]


const AccountScreen = () => {
    const navigation = useNavigation()
    const dispatch = useDispatch()
    const storedata = useSelector((state) => state.appredux.storeData)

    const handleLogout = async() => {
        try {
            await SecureStore.deleteItemAsync('token')
            await AsyncStorage.removeItem('agentdata')
            dispatch(userToken(null))
            dispatch(storeData({}))
        }catch (error) {
            console.log(error)
        }
    }

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        sfview:{
            flex:1
        },
        tp:{
            paddingLeft:20,
            paddingRight:20,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            flexWrap:'wrap',
            backgroundColor:appColor.background,
            paddingBottom:20,
            paddingTop:10,
            marginTop:2
        },
        scrl:{
            backgroundColor:appColor.scrollgray
        },
        acv:{
            paddingTop:20,
            paddingBottom:10,
            backgroundColor:appColor.background,
            marginTop:10,
            paddingLeft:20,
            paddingRight:20
        },
        actxt:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.txheadblack,
            marginBottom:10
        },
        tch:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            height:40
        },
        lft:{
            flexDirection:'row',
            alignItems:'center',
        },
        txt:{
            fontFamily:appfont.regular,
            fontSize:16,
            color:appColor.txheadblack,
            marginLeft:10
        }
    })
    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <HeaderComponent hidect={true} title={'Account'}/>
            <ScrollView showsVerticalScrollIndicator={false} style={styles.scrl}>

            {
                storedata.employee !== 1 ?
            
                <View style={styles.tp}>
                    <IconButton title={'Manage'} icon={'CodeSandbox'} onpress={() => navigation.navigate('Manage')}/>
                    <IconButton title={'Wallet'} icon={'creditcard'} onpress={() => navigation.navigate('OnlinePayment')}/>
                </View>
                :
                null
            }


            {
                pdata.map((item) => {
                    return(
                        <View key={item.id} style={styles.acv}>
                            <Text style={styles.actxt}>{item.name}</Text>
                            {
                                item.subp.map((itm) => {
                                    return(
                                        <TouchableOpacity key={itm.id} style={styles.tch} onPress={() => navigation.navigate(itm.route, {title:itm.name, uri:itm.uri})}>
                                            <View style={styles.lft}>
                                            <Icon name={itm.icon} size={20} color={appColor.primary} />
                                            <Text style={styles.txt}>{itm.name}</Text>
                                            </View>
                                            <Icon name="right" size={14} color={appColor.txheadblack} />
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </View>
                    )
                })
            }
            <View style={{justifyContent:'center', alignItems:'center', marginTop:20, marginBottom:20}}>
            <CustomButton title={'Log Out'} onpress={() => handleLogout()}/>
            </View>
            </ScrollView>
            </SafeAreaView>
        </View>
    )
}

export default AccountScreen