import React, {useState, useEffect} from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Platform, Image, ActivityIndicator } from "react-native";
import appColor from "../constants/other/colors";
import Icon from 'react-native-vector-icons/FontAwesome';
import appfont from "../constants/other/font";
import ServerData from "../constants/other/serverData";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomButton from "./customButton";




export default function ProductCard({onpress, title, tagline, image1, price, cashback, stock, acststus, pid}) {
    const {width} = useWindowDimensions()
    const [actives, setActiveS] = useState(acststus)
    const [loading, setLoading] = useState(false)
    const tknsts = useSelector((state) => state.appredux.userToken)

    function productApprove(){
        if(actives == 1){
            setActiveS(2)
        }else{
            setActiveS(1)
        }
        axios.post(`${ServerData.uri3}/productVerify/`, {
            token:tknsts,
            ppid:pid,
        }).then(response => {
            if(response.data.code == 1){
                setActiveS(response.data.status)
            }
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }

    function handActive() {
        if (actives == 1 ) {
            setActiveS(2)
        }else{
            setActiveS(1)
        }
    }


    const styles = StyleSheet.create({
        pc:{
            backgroundColor:appColor.background,
            marginBottom:10,
            borderRadius:10,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        tp:{
            marginLeft:10,
            marginRight:10,
            flexDirection:'row',
            justifyContent:'space-between'
        },
        img:{
            height:80,
            width:60,
            borderRadius:5,
            resizeMode:'stretch'
        },
        lf:{
            flexDirection:'row',
            alignItems:'center',
            flex:1
        },
        lfr:{
            marginLeft:10,
            flex:1
        },
        tx1:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack,
            marginBottom:5
        },
        txx2:{
            fontFamily:appfont.regular,
            fontSize:12,
            color:appColor.txheadblack,
        },
        tx3:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack,
        },
        tx4:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.green,
        },
        rt:{
            height:100,
            justifyContent:'center'
        },

        act:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:10,
            paddingRight:10,
            height:30,
            width:60,
            marginBottom:10,
            backgroundColor:actives == 1? appColor.green : appColor.red,
            borderRadius:40,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:16
        },
        tx2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:20
        },
        btm:{
            height:40,
            justifyContent:'center',
            alignItems:'center',
            flexDirection:'row'
        },
        txtt:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txgray,
            marginLeft:5
        }
    })
    return (
        <TouchableOpacity style={styles.pc} onPress={onpress}>
            <View style={styles.tp}>
                <View style={styles.lf}>
                    <Image source={image1 ? image1 : require('../constants/asstes/s2.jpg')} style={styles.img}/>
                    <View style={styles.lfr}>
                        <Text style={styles.tx1}>{title}</Text>
                        <Text style={styles.txx2}>Stock : {stock}</Text>
                        <Text style={styles.tx3}>₹{price}</Text>
                        <Text style={styles.tx4}>Cashbock : {cashback}%</Text>
                    </View>
                </View>
                <View style={styles.rt}>
                {
                    loading ? 
                    <ActivityIndicator size="small" color={appColor.primary} style={{marginBottom:10}}/>
                    :
                    <CustomButton wt={120} ht={40} title={actives == 1 ? 'Approved' : 'Approve'} bg={actives == 1 ? appColor.green : appColor.red} tc={appColor.background} onpress={() => productApprove()}/>
                }
                </View>
            </View>
        </TouchableOpacity>
    )
}