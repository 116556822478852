import { View, Text, ScrollView, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import VendorInfoCard from '../../../components/VendorInfoCard'
import Icon from 'react-native-vector-icons/AntDesign';
import Detailcardcomponent from '../../../components/detailcardcomponent'
import ServerData from '../../../constants/other/serverData'
import axios from 'axios'
import DocumentCard from '../../../components/DocumentCard'
import CustomButton from '../../../components/customButton'
import { useSelector } from 'react-redux'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'

const SellerDetails = ({route}) => {
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const {id, data} = route.params;
    const navigation = useNavigation()
    const [loading, setLoading] = useState(false)
    const [fileLists, setFileLists] = useState([])

    function getFileLists() {
        axios.get(`${ServerData.admin}/useruploadeddoc/${data.user.username}/`)
        .then(response => {
            setFileLists(response.data)
          })
          .catch(error => {
              console.log(error)
          });
    }

    useFocusEffect(
        React.useCallback(() => {
            getFileLists()
        },[])
    )

    function agentApprove(id){
        setLoading(true)
        axios.post(`${ServerData.uri3}/vendorVerify/`, {
            token:tknsts,
            agentid:data.user.username,
            type:id
        }).then(response => {
            setLoading(false)
            if(response.data.code == 1){
                alert(response.data.message)
            }
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }

    const styles = StyleSheet.create({
        btmarea:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:10,
            marginRight:10
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Seller details'} hidect={true}/>
        <VendorInfoCard image={data.image} name={data.storename} mobile={data.user.username} address={`${data.addressLine1}, ${data.addressLine2}, ${data.city}, ${data.state} : ${data.pincode}`}/>
        <ScrollView>
        <View style={{marginLeft:10, marginRight:10}}>
            {
                fileLists.map((item) => {
                    return(
                        <View key={item.id}>
                        <DocumentCard data={item} indxnum={fileLists.indexOf(item)} hide1={true} type={2}/>
                        </View>
                    )
                })
            }
        </View>
        </ScrollView>
        <View style={styles.btmarea}>
            <CustomButton title={'Approve'} wt={width/2-15} bg={appColor.green} tc={appColor.background} onpress={() => agentApprove(1)} ai={loading}/>
            <CustomButton title={'De Activate'} wt={width/2-15} bg={appColor.red} tc={appColor.background} onpress={() => agentApprove(2)} ai={loading}/>

        </View>
    </CustomSafeArea>
  )
}

export default SellerDetails