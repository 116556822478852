import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity } from "react-native";
import Icon from 'react-native-vector-icons/AntDesign';
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";




export default function IconButton({onpress, hit, widt, icon, title, mrgnb}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        icbtn:{
            height:(hit ? hit : 45),
            width:(widt ? widt : (width-60)/2),
            borderRadius:5,
            flexDirection:'row',
            paddingLeft:10,
            alignItems:'center',
            borderWidth:1,
            borderColor:appColor.gray,
            marginBottom:(mrgnb? mrgnb : 10)
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:16
        },
        icn:{
            marginRight:10,
            color:appColor.primary
        }
    })
    return (
        <TouchableOpacity style={styles.icbtn} onPress={onpress}>
        {
            icon ?
            <Icon name={icon} size={25} style={styles.icn} />
            :
            null
        }
        <Text style={styles.txt}>{title}</Text>
        </TouchableOpacity>
    )
}