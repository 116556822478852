import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import HeaderComponent from "../../components/Header";
import CustomButton from "../../components/customButton";
import ProductCard from "../../components/ProductCard";
import { useSelector } from "react-redux";
import ServerData from "../../constants/other/serverData";
import axios from "axios";
import { useFocusEffect } from "@react-navigation/native";
import VendorInfoCard from "../../components/VendorInfoCard";
import ServiceCard from "../../components/ServiceCard";



const ProductScreen = ({route}) => {
    const {id} = route.params
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [pstage, setPstage] = useState(1)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [productData, setProductData] = useState([])
    const [serviceData, setServiceData] = useState([])

    function handleAdd() {
        if (pstage == 1) {
            navigation.navigate('AddProductStep1')
        } else {
            navigation.navigate('AddService')
        }
    }


    useFocusEffect(
        React.useCallback(() => {
            function getproductdata() {
                axios.get(`${ServerData.uri3}/productlistbyagent/${id}/`)
                .then(response => {
                    setProductData(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }
            getproductdata()
        },[])
    )


    useFocusEffect(
        React.useCallback(() => {
            function getServiceData() {
                axios.get(`${ServerData.uri3}/servicetlistbyagent/${id}/`)
                .then(response => {
                    setServiceData(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }
            getServiceData()
        },[])
    )




    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background,
            justifyContent:'space-between'
        },
        sfview:{
            flex:1,
            justifyContent:'space-between'
        },
        pv:{
            height:50,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:10
        },
        tch:{
            height:50,
            width:width/2.4,
            justifyContent:'center',
            alignItems:'center',
            borderBottomWidth:3,
            borderBottomColor:appColor.primary,
            marginLeft:10,
            marginRight:10,
        },
        tch1:{
            height:50,
            width:width/2.4,
            justifyContent:'center',
            alignItems:'center',
            marginLeft:10,
            marginRight:10,
            borderBottomWidth:3,
            borderBottomColor:appColor.background

        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.primary,
            fontSize:14
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        vv:{
            justifyContent:'center',
            alignItems:'center',
            marginBottom:10
        }
    })
    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <View style={{flex:1}}>
                <HeaderComponent title={'Product / Service'} hidect={true} adbtn={true} adbtntitle={'Restricted Items'} adbtnclr={appColor.red} onpress={() => navigation.navigate('restrictedItems')}/>
                <View style={styles.pv}>
                    <TouchableOpacity style={pstage == 1 ? styles.tch : styles.tch1} onPress={() => setPstage(1)}>
                        <Text style={pstage == 1 ? styles.txt : styles.txt1}>Product</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={pstage == 2 ? styles.tch : styles.tch1} onPress={() => setPstage(2)}>
                        <Text style={pstage == 2 ? styles.txt : styles.txt1}>Service</Text>
                    </TouchableOpacity>
                </View>
                <ScrollView style={{paddingTop:10,paddingLeft:10, paddingRight:10, flex:1}} showsVerticalScrollIndicator={false}>
                {
                    pstage == 1 ?

                    <View>
                        {
                            productData.map((item) => {
                                return(
                                    <View key={item.id} style={{margin:5}}>
                                    <ProductCard tagline={'Advirtise this product'} title={item.name} image1={{uri:item.displayimage}} cashback={item.cashback} price={item.price} stock={item.stock} acststus={item.verificationStatus} pid={item.id}/>
                                    </View>
                                )
                            })
                        }
                    </View>
                
            :
                <View>
                {
                            serviceData.map((item) => {
                                return(
                                    <View key={item.id}>
                                    <ServiceCard tagline={'Advirtise this service'} title={item.name} image1={item.image ? {uri:item.image} : require('../../constants/asstes/car.jpeg')} acststus={item.verificationstatus} pid={item.id} stock={item.stockcount} price={item.price} cashback={item.cashback}/>
                                    </View>
                                )
                            })
                        }
                </View>}

                    <View style={{height:20}}></View>

                </ScrollView>
            </View>
            </SafeAreaView>
        </View>
    )
}

export default ProductScreen