import React, { useState, useEffect, useCallback } from 'react';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, ScrollView, useWindowDimensions, ActivityIndicator } from 'react-native';
import ServerData from '../constants/other/serverData';
import axios from 'axios';
import appColor from '../constants/other/colors';
import appfont from '../constants/other/font';
import CustomButton from './customButton';
import { useFocusEffect } from '@react-navigation/native';

const DropdownSearch = ({ onAddClick, itemproperty, title, onslect, urldta, hideadd }) => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState(mydata);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const [mydata, setMyData] = useState([])
  const {width} = useWindowDimensions()
  const [actind, setActInd] = useState(false)

  var sitemname = mydata.find((item) => item.id === selectedItem);


  useFocusEffect(
    useCallback(() => {
      function getBusinesType() {
        setActInd(true)
          axios.get(urldta)
          .then(response => {
            setActInd(false)
              setMyData(response.data)
              setSearchResults(response.data)
          })
          .catch(error => {
            setActInd(false)
              console.log(error)
          });
      }
      getBusinesType()
    },[])
  )

function handlelistinv() {
    setIsListVisible(false)
}

  const handleSearch = (text) => {
    setSearchText(text);
    // Perform search logic here and update the searchResults state
    // based on your search criteria
    const filteredResults = mydata.filter((item) =>
      item[itemproperty].toLowerCase().includes(text.toLowerCase())
    );
    setSearchResults(filteredResults);
    setIsListVisible(true);
  };

  const handleItemSelect = (item) => {
    setSelectedItem(item.id);
    setIsListVisible(false);
    setSearchText('');
    onslect(item);
  };

  function handlesearchTouch() {
    setIsListVisible(true)
    setSelectedItem(null)
  }


  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={styles.itemContainer}
      onPress={() => handleItemSelect(item)}
    >
      <Text style={styles.itemText}>{item[itemproperty]}</Text>
    </TouchableOpacity>
  );

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      marginTop:10
    },
    searchField: {
      borderWidth: 1,
      marginBottom: 10,
      borderColor:appColor.txgray,
      borderRadius:10,
      height:50,
      justifyContent:'center',
      padding:10,
      backgroundColor:appColor.background
    },
    input: {
      height:50,
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:14,
    },
    listContainer: {
      maxHeight: 150, // Set the desired max height of the list
      marginBottom: 10,
    },
    itemContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#CCCCCC',
    },
    itemText: {
      fontSize: 16,
      flex: 1,
    },
    addButton: {
      alignItems: 'center',
      backgroundColor: '#DDDDDD',
      padding: 10,
    },
    addButtonText: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    stxt:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:14,
    }
  });

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.searchField}
        onPress={() => handlesearchTouch()}
      >
        {
            selectedItem == null ? 
            <View>

            { actind ?

            <ActivityIndicator size="small" color={appColor.primary} />
              :
            <TextInput
          style={styles.input}
          onChangeText={handleSearch}
          value={searchText}
          placeholder={`Search ${title}`}
          placeholderTextColor={appColor.primary}
          onFocus={() => setIsListVisible(true)}
        />
                    }

        </View>

        :
            <Text style={styles.stxt}>{sitemname[itemproperty]}</Text>
        }
      </TouchableOpacity>

      {isListVisible && (
        <ScrollView style={styles.listContainer}>
          {searchResults && searchResults.length > 0 ? (
            searchResults.map((item) => (
              <React.Fragment key={item.id}>
                {renderItem({ item })}
              </React.Fragment>
            ))
          ) : (
            <View>
            {
              hideadd ? null :
              <CustomButton title={`Add ${title}`} bg={appColor.green} ht={30} tc={appColor.background} wt={width-48} onpress={onAddClick}/>
            }
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default DropdownSearch;
