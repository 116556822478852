import React, {useState, useEffect} from 'react'
import { View, Text, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import DropdownSearch from '../../../components/SearchableDropdown'
import CustomButton from '../../../components/customButton'
import appColor from '../../../constants/other/colors'
import CustomTextInput from '../../../components/customTextInput'
import { useNavigation } from '@react-navigation/native'
import ServerData from '../../../constants/other/serverData'
import axios from 'axios'
import WebSafeArea from '../../webcomponents/WebSafeArea'

const BusinessCategoryAdd = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [slectedid, setSelectedId] = useState(null)
    const [catgryname, seCatygryName] = useState('')
    const [loading, setLoading] = useState(false)

    const handleItemSelect = (selectedItem) => {
      setSelectedId(selectedItem.id);
    };

    function handleCatAdd() {
      if(slectedid == null || catgryname == '') {
        alert('Please Select Main Category')
      }else{
        setLoading(true)
        axios.post(`${ServerData.uri}/addcatall/`, {
          addtype:2,
          mid:slectedid,
          catname:catgryname
          })
          .then(function (response) {
            setLoading(false)
              if(response.data.code == 1) {
                navigation.goBack()
              }
              console.log(response.data)
          })
          .catch(function (error) {
            setLoading(false)
              console.log(error)
          });
      }
    }


    const styles = StyleSheet.create({
        mn:{
            marginLeft:24,
            marginRight:24,
            marginTop:10
        }
    })
  return (
    <WebSafeArea>
        <View style={styles.mn}>
        <DropdownSearch itemproperty={'name'} title={'Main Category'} onAddClick={() => navigation.navigate('MainCatAdd')} urldta={`${ServerData.uri}/maincatlist/`} onslect={handleItemSelect}/>
        <CustomTextInput title={'Business Category Name'} wd={width-48} onchange={seCatygryName} value={catgryname}/>
        <CustomButton title={'Add Business Category'} bg={appColor.primary} tc={appColor.background} ht={55} mt={20} wt={width-48} onpress={() => handleCatAdd()} ai={loading}/>
        </View>
    </WebSafeArea>
  )
}

export default BusinessCategoryAdd