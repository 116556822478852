import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userToken:null,
    userData:{},
    profileImage:null,
    agentStatus:false,
    storeData:{},
}

export const appreduxSlice = createSlice({
    name:'appredux',
    initialState,
    reducers:{
        userToken: (state, action) => {
            state.userToken = (action.payload)
        },
        userData: (state, action) => {
            state.userData = (action.payload)
        },
        profileImage: (state, action) => {
            state.profileImage = (action.payload)
        },
        agentStatus: (state, action) => {
            state.agentStatus = (action.payload)
        },
        storeData: (state, action) => {
            state.storeData = (action.payload)
        },
    }
})

export const {userToken, userData, profileImage, agentStatus, storeData} = appreduxSlice.actions
export default appreduxSlice.reducer