import React, {useState, useEffect} from 'react'
import { View, Text, ScrollView, StyleSheet, useWindowDimensions, TouchableOpacity, Linking } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import DropdownSearch from '../../components/SearchableDropdown'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import CustomTextInput from '../../components/customTextInput'
import { useNavigation } from '@react-navigation/native'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker';
import UploadPhotoComponent from '../../components/UploadPhotoComponent'
import appfont from '../../constants/other/font'

const AddPSubSubCategory = () => {
  const navigation = useNavigation()
  const {width} = useWindowDimensions()
  const [slectedid, setSelectedId] = useState(null)
  const [catgryname, seCatygryName] = useState('')
  const [icon, setIcon] = useState(false);

  const openiclink = async () => {
    await Linking.openURL('https://www.flaticon.com/')
  }


  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
      quality: 1,
    });
    
    if (result.canceled == false) {
      setIcon(result.assets[0]);
    }
  };

  const handleItemSelect = (selectedItem) => {
    setSelectedId(selectedItem.id);
  };

  function handleCatAdd() {
    if(icon == false){
      alert('Please upload icon.')
    }else if(slectedid == null){
      alert('Please select super category')
    }
    else if(catgryname == '') {
      alert('Please type category name')
    }
    else{
      const formData = new FormData()
      formData.append('icon', {uri: icon.uri, name:'adphoto', type:'image/jpeg'})
      formData.append('addtype', 5)
      formData.append('mid', slectedid)
      formData.append('catname', catgryname)

      axios.post(`${ServerData.uri}/addcatall/`, formData, {
        headers:{
            "content-type": "multipart/form-data",
        },
        })
        .then(function (response) {
          if(response.data.code == 1) {
            navigation.goBack()
          } else if (response.data.code == 0){
            alert(response.data.message)
          }
          console.log(response.data)
        })
        .catch(function (error) {
          console.log(error)
        });
    }
    }

  const styles = StyleSheet.create({
      mn:{
          marginLeft:24,
          marginRight:24,
          marginTop:10
      },
      ic:{
        marginLeft:24,
        marginTop:20
      },
      opv:{
        flexDirection:'row',
        alignItems:'center',
        marginTop:5
      },
      tx1:{
        fontFamily:appfont.semibold,
        fontSize:12,
        color:appColor.txheadblack,
        marginRight:10
      },
      tx2:{
        fontFamily:appfont.semibold,
        fontSize:14,
        color:appColor.primary,
      }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent hidect={true} title={'Add Product Category'}/>
        <ScrollView>
        <View style={styles.ic}>
          <UploadPhotoComponent title={'Upload Icon'} img={icon} onpress={() => pickImage()}/>
          <View style={styles.opv}>
            <Text style={styles.tx1}>To download icons</Text>
            <TouchableOpacity onPress={() => openiclink()}>
              <Text style={styles.tx2}>Click Here</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.mn}>
        <DropdownSearch itemproperty={'name'} title={'Main Category'} onAddClick={() => navigation.navigate('addPSubCategory')} urldta={`${ServerData.uri}/psubcatlistall/`} onslect={handleItemSelect}/>
        <CustomTextInput title={'Product Category Name'} wd={width-48} onchange={seCatygryName} value={catgryname}/>
        <CustomButton title={'Add Product Category'} bg={appColor.primary} tc={appColor.background} ht={55} mt={20} wt={width-48} onpress={() => handleCatAdd()}/>
        </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default AddPSubSubCategory