import React, {useState, useEffect} from "react";
import { View, StyleSheet, useWindowDimensions, Text, TouchableOpacity, Alert } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import { useDispatch } from "react-redux";
import { userToken } from "../../toolkit/slice";
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import * as SecureStore from 'expo-secure-store';
import WebSafeArea from "../webcomponents/WebSafeArea";


const CheckBuyer = ({route}) => {
    const {referer} = route.params
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [mobile, setMobile] = useState()
    const [password, setPassword] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [token, setToken] = useState(null)
    const [userstatus, setUserStaus] = useState(0)
    const [chatsts, setChatsts] = useState(0)

    useEffect(() => {
        if (token !== null) {
            async function saveToken() {
                navigation.navigate('AppPage')
            }
            saveToken()
        }
    }, [token])

    function handleCheckUser() {
        if(userstatus == 0) {
            handleuseruser()
        }else{
            handleCreateAgent()
        }
    }


    function handleCreateAgent() {
        if(password == '') {
            alert('Please enter valid password')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri2}/checkbuyer/`, {
                username:mobile,
                password:password,
                stage:2,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1) {
                        const userdata = {
                            'mobile':mobile,
                            'code':0,
                            'firstname':response.data.data.firstName,
                            'lastname':response.data.data.lastName,
                            'email':response.data.data.email,
                            'password':password
                        }
                        navigation.navigate('UserSignupPage', {data:userdata, referalid:referer})
                    }else{
                        alert('Invalid Password')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    console.log(error)
                });
        }
    }


    function handleuseruser() {
        if(mobile.length !== 10) {
            alert('Please enter valid mobile')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri2}/checkbuyer/`, {
                username:mobile,
                stage:1,
                })
                .then(function (response) {
                    setModalVisible(false)
                    console.log(response.data)
                    if(response.data.code == 0){
                        const userdata = {
                            'mobile':mobile,
                            'code':0
                        }
                        navigation.navigate('UserSignupPage', {data:userdata, referalid:referer})
                    }
                    else if(response.data.code == 2){
                        navigation.navigate('CustomerAppPage', {type:1})
                    }
                    else if(response.data.code == 1){
                        setUserStaus(1)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    console.log(error)
                });
        }
    }

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24,
            marginTop:20
            
        },
        tp1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
    })
    return(
        <WebSafeArea>
            <View style={width > 600 ? styles.tp1 : styles.tp}>
                <Text style={styles.txt}>Hello</Text>
                <Text style={styles.txt2}>{userstatus == 0 ? 'Signup as Gibnu Buyer!' : 'User account found, enter password to create buyer account.'}</Text>
            </View>
            <View style={styles.tp2}>
                
                    <CustomTextInput title={'Mobile Number (Whatsapp)'} wd={width > 600 ? width/3 : width-48} onchange={setMobile} value={mobile} disable={userstatus == 0 ? false : true} keytpe={'number-pad'}/>
                {
                    userstatus == 1 ? 
                <CustomTextInput title={'Password'} wd={width > 600 ? width/3 : width-48} st={true} onchange={setPassword} value={password}/>
                :
                    null
                }
                <View style={{marginTop:20}}>
                <CustomButton title={userstatus == 0 ? 'Proceed' : 'Create Buyer Account'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width > 600 ? width/3 : width-48} onpress={() => handleCheckUser()} ai={modalVisible}/>
                </View>
            </View>
        </WebSafeArea>
    )
}

export default CheckBuyer