import { View, Text, StyleSheet, useWindowDimensions, ScrollView } from 'react-native'
import React, {useEffect, useState} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import { useSelector } from 'react-redux'

const EditAddress = () => {
  const tknsts = useSelector((state) => state.appredux.userToken)
  const {width} = useWindowDimensions()
  const [modalVisible, setModalVisible] = useState(false)
  const [adrsline1, setAdresLine1] = useState('')
  const [adrsline2, setAdrsLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [pincode, setPincode] = useState('')

  useEffect(() => {
    function getAgentAddress(){
      axios.post(`${ServerData.uri3}/agentAddressUpdate/`, {
        token:tknsts,
        stage:1,
      })
      .then(response => {
        if(response.data.code == 1){
          setAdresLine1(response.data.data.adrsline1)
          setAdrsLine2(response.data.data.adrsline2)
          setCity(response.data.data.city)
          setState(response.data.data.state)
          setPincode(response.data.data.pincode)
        }
      })
      .catch(error => {
          console.log(error)
      });
    }
    getAgentAddress()
  },[])

  function addressUpdate() {
    setModalVisible(true)
    axios.post(`${ServerData.uri3}/agentAddressUpdate/`, {
      token:tknsts,
      stage:2,
      adrsline1:adrsline1,
      adrsline2:adrsline2,
      city:city,
      state:state,
      pincode:pincode
    })
    .then(response => {
      setModalVisible(false)
      console.log(response.data)
      if(response.data.code == 2){
        alert(response.data.message)
      }
    })
    .catch(error => {
      setModalVisible(false)
        console.log(error)
    });
  }


  const styles = StyleSheet.create({
    txt:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:30,
      marginBottom:10
  },
  scrl:{
      paddingTop:20,
      marginLeft:24,
      marginRight:24,
      flex:1
  },
  txt2:{
      fontFamily:appfont.semibold,
      color:appColor.txgray,
      fontSize:12,
      marginBottom:10

  },
  snv:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      marginTop:10
  },
  txt1:{
      fontFamily:appfont.regular,
      fontSize:14,
      color:appColor.txheadblack
  },
  txt5:{
      fontFamily:appfont.semibold,
      fontSize:14,
      color:appColor.primary
  },
  slm:{
      flexDirection:'row',
      alignItems:'center',
      marginTop:10
  },
  sl:{
      height:40,
      width:(width-40)/3,
      borderRadius:10,
      borderWidth:1,
      borderColor:appColor.txgray,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10
  },
  sl1:{
      height:40,
      width:(width-40)/3,
      borderRadius:10,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:appColor.primary,
      marginRight:10
  },
  slt:{
      fontFamily:appfont.semibold,
      color:appColor.background,
      fontSize:14
  },
  slt1:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:14
  },
  dropdown:{
      height:50,
      width:width-48,
      borderWidth:1,
      borderColor:appColor.txgray,
      justifyContent:'center',
      alignItems:'center',
      borderRadius:10,
      paddingLeft:10,
      marginBottom:10
  },
  mv:{
      flexDirection:'row',
      alignItems:'center'
  },
  mtch1:{
      height:40,
      width:70,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10,
      borderRadius:10,
      backgroundColor:appColor.primary
  },
  mtch2:{
      height:40,
      width:70,
      borderWidth:1,
      borderColor:appColor.primary,
      justifyContent:'center',
      alignItems:'center',
      marginRight:10,
      borderRadius:10
  },
  mtxt1:{
      fontFamily:appfont.semibold,
      color:appColor.background,
      fontSize:12
  },
  mtxt2:{
      fontFamily:appfont.semibold,
      color:appColor.txheadblack,
      fontSize:12
  },
  rr:{
      justifyContent:'center',
      alignItems:'center'
  }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Edit Address'} hidect={true}/>
        <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            <View style={{marginTop:10}}>
                <CustomTextInput wd={(width-48)} title={'AddressLine1'} value={adrsline1} onchange={setAdresLine1}/>
                <CustomTextInput wd={(width-48)} title={'AddressLine2'} value={adrsline2} onchange={setAdrsLine2}/>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <CustomTextInput wd={(width-60)/2} title={'City'} value={city} onchange={setCity}/>
                <CustomTextInput wd={(width-60)/2} title={'State'} value={state} onchange={setState}/>
                </View>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <CustomTextInput wd={(width-60)/2} title={'pincode'} value={pincode} onchange={setPincode}/>
                </View>
                <View>
                </View>

                <View style={{height:50}}></View>
                <CustomButton title={'Update'} bg={appColor.primary} tc={appColor.background} wt={width-48} ht={55} onpress={() => addressUpdate()} ai={modalVisible}/>
                <View style={{height:50}}></View>

            </View>
            </ScrollView>
    </CustomSafeArea>
  )
}

export default EditAddress