import { View, Text, Modal, useWindowDimensions, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'
import React, { useState } from 'react'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { Image } from 'expo-image'
import { useNavigation } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/AntDesign'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ServerData from '../../constants/other/serverData'
import CustomTextInput from '../customTextInput'
import CustomButton from '../customButton'

export default function WalletPinEntryModal({visible, onpress, onpinverify, pinchangepress, title}) {
    const navigation = useNavigation()
    const {height, width} = useWindowDimensions()
    const [loading, setLoading] = useState(false)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [walletpin, setWalletPin] = useState('')

    function sendpindatatoparent(verifiedpin){
        onpinverify(verifiedpin)
        setWalletPin('')
    }

    function checkPin(){
        if(walletpin.length !== 4){
            alert('Please enter valid pin')
        }else{
            setLoading(true)
            axios.post(`${ServerData.admin}/pinverify/`, {
                token:tknsts,
                walletpin:walletpin
                })
                .then(function (response) {
                    setLoading(false)
                    if(response.data.code == 1){
                        sendpindatatoparent(walletpin)
                    }else(
                        alert(response.data.message)
                    )
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                    alert('Unexpected Error')
                });
        }
    }

    const styles = StyleSheet.create({
        mn:{
            flex:1,
            backgroundColor:'rgba(0, 0, 0, 0.8)',
            justifyContent:"center",
            alignItems:'center'
        },
        tch:{
            flex:1
        },
        inside:{
            height:height-200,
            width:width,
            backgroundColor:appColor.background
        },
        itv:{
            padding:10,
            borderBottomWidth:1,
            borderBottomColor:appColor.gray,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        rtxt:{
            fontFamily:appfont.semibold,
            fontSize:11,
            color:'#0A09AB'
        },
        bonusimg:{
            height:width-40,
            width:width-40,
            marginTop:10,
            borderRadius:10
        }
    })
  return (
    <Modal
        animationType='slide'
        transparent={true}
        visible={visible}
    >

    <View style={styles.mn}>
        <TouchableOpacity style={styles.tch} onPress={onpress}>

        </TouchableOpacity>
        <View style={styles.inside}>
            <View style={styles.itv}>
                <Text style={styles.txt}>VERIFY WALLET</Text>
                <TouchableOpacity onPress={onpress}>
                    <Icon name='close' size={20} color={appColor.txheadblack}/>
                </TouchableOpacity>
            </View>
            <ScrollView>
                <View style={{justifyContent:'center', alignItems:'center'}}>
                <CustomTextInput title={'Enter Your Wallet PIN'} wd={width-40} mt={10} keytpe={'number-pad'} mx={4} st={true} onchange={setWalletPin} value={walletpin}/>
                <TouchableOpacity style={{alignSelf:'flex-end', paddingRight:20, marginBottom:10}} onPress={pinchangepress}>
                    <Text style={styles.rtxt}>Reset PIN</Text>
                </TouchableOpacity>
                <CustomButton title={title ? title : 'USE POINTS'} bg={'#0A09AB'} tc={appColor.background} wt={width-40} ht={50} onpress={() => checkPin()} ai={loading}/>
                <Image source={{uri:'https://storage.googleapis.com/gibnu_file_storage/drk2.png'}} contentFit='contain' style={styles.bonusimg} cachePolicy='memory-disk'/>
                </View>
            </ScrollView>
        </View>
    </View>

    </Modal>
  )
}