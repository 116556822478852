import { View, Text, StyleSheet, useWindowDimensions } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import SuccessComponent from '../../components/SuccessComponent'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import CustomButton from '../../components/customButton'
import { useNavigation } from '@react-navigation/native'

const SucessScreenWallet = ({route}) => {
  const {trdata} = route.params;
  const {width} = useWindowDimensions()
  const navigation = useNavigation()

  const styles = StyleSheet.create({
    btm:{
      alignItems:'center'
    },
    txt:{
      fontFamily:appfont.semibold,
      fontSize:18,
      color:appColor.green
    },
    txt2:{
      fontFamily:appfont.semibold,
      fontSize:14,
      color:appColor.primary
    }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Transaction Success'} hidect={true}/>
        <SuccessComponent />
        <View style={styles.btm}>
          <Text style={styles.txt}>Transaction Success: {trdata.amount} CB Points</Text>
          <Text style={styles.txt2}>{trdata.receiver}</Text>
          <Text style={styles.txt2}>Transaction Id: {trdata.transactionid}</Text>
          <CustomButton title={'Back to Walllet'} ht={30} wt={width/2} mt={30} onpress={() => navigation.navigate('OnlinePayment')}/>
        </View>
    </CustomSafeArea>
  )
}

export default SucessScreenWallet