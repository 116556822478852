import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import appColor from '../constants/other/colors'
import appfont from '../constants/other/font'

export default function IncomeCard({indata}) {

    const styles = StyleSheet.create({
        mn:{
            marginBottom:10,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            padding:10,
            margin:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            borderRadius:5
        },
        txt1:{
            fontFamily:appfont.semibold,
            fontSize:16,
            color:appColor.txheadblack
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt3:{
            fontFamily:appfont.regular,
            fontSize:12,
            color:appColor.txheadblack
        },
        txt4:{
            fontFamily:appfont.semibold,
            fontSize:14,
        }
    })
  return (
    <View style={styles.mn}>
        <View style={{flexDirection:'row', alignItems:'center'}}>
            <View style={{marginRight:10}}>
                <Text style={styles.txt1}>#{indata.id}</Text>
            </View>
            <View>
                <Text style={styles.txt2}>Amount: ₹{Math.floor(parseFloat(indata.amount))}</Text>
                <Text style={styles.txt3}>Income Date: {indata.date_created.slice(0,10)}</Text>
                <Text style={styles.txt3}>Income Type: {indata.notes}</Text>

            </View>
        </View>
        <View>
            <Text style={[styles.txt4, {color: indata.cleared_amount ? appColor.green : '#f59e0b'}]}>{indata.cleared_amount ? 'CLEARED' : 'PENDING'}</Text>
        </View>
    </View>
  )
}