import React, {useState, useEffect} from 'react'
import { View, Text, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity } from 'react-native'
import HeaderComponent from '../../components/Header'
import CustomSafeArea from '../../components/CustomSfview'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import Icon from 'react-native-vector-icons/AntDesign'

const BundleScreen = ({route}) => {
    const {width} = useWindowDimensions()
    const {id} = route.params
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [quantity, setQuantity] = useState('')
    const [cashback, setCashback] = useState('')
    const [bundleData, setBundleData] = useState([])
    const [loading, setLoading] = useState(false)

    function addBundleProduct() {
        if(quantity == '' || cashback == '') {
            alert('Enter Valid details')
        }else{
            setLoading(true)
            axios.post(`${ServerData.uri}/addBundle/`, {
                token:tknsts,
                pid:id,
                quantity:quantity,
                cashback:cashback
                })
                .then(function (response) {
                    setLoading(false)
                    if(response.data.code ==1){
                        getProductVariantList()
                        setQuantity('')
                        setCashback('')
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                });
        }
    }

    function getProductVariantList() {
        setLoading(true)
        axios.get(`${ServerData.uri}/productwiseBundle/${id}/`)
        .then(function (response) {
            setLoading(false)
            setBundleData(response.data)
        })
        .catch(function (error) {
            setLoading(false)
            console.log(error)
        });
    }

    useEffect(() => {
        getProductVariantList()
    },[])

    const removeBundle = (value) => {
        axios.post(`${ServerData.uri}/removeBundleProduct/`, {
            token:tknsts,
            bundle:value,
            })
            .then(function (response) {
                if(response.data.code ==1){
                    getProductVariantList()
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }


    const styles = StyleSheet.create({
        mn:{
            alignItems:'center',
            marginTop:10
        },
        bndl:{
            backgroundColor:appColor.primary,
            width:width,
            height:30,
            marginTop:10,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:24,
            paddingRight:24
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background
        },
        inv:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:24,
            marginRight:24,
            marginTop:10,
            alignItems:'center',
            marginBottom:10,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray,
            paddingBottom:5
        }
    })
  return (
    <CustomSafeArea>
      <HeaderComponent title={'Add Bundle'} hidect={true}/>
      <View style={styles.mn}>
        <CustomTextInput title={'Quantity'} onchange={setQuantity} value={quantity}/>
        <CustomTextInput title={'Cashback %'} onchange={setCashback} value={cashback}/>
        <CustomButton title={'Add Bundle'} mt={30} ht={50} bg={appColor.primary} tc={appColor.background} wt={width/1.6} onpress={() => addBundleProduct()} ai={loading}/>
      </View>
      <View style={styles.bndl}>
        <Text style={styles.txt}>Bundle Quantity</Text>
        <Text style={styles.txt}>Cashback</Text>
        <Text style={styles.txt}>Remove</Text>
      </View>
      <ScrollView>
        {
            bundleData.map((item) => {
                return(
                    <View key={item.id} style={styles.inv}>
                        <Text>{item.quantity}</Text>
                        <Text>{item.cashback}%</Text>
                        <TouchableOpacity style={styles.rmv} onPress={() => removeBundle(item.id)}>
                            <Icon name='closecircleo' size={20} color={appColor.red}/>
                        </TouchableOpacity>
                    </View>
                )
            })
        }
      </ScrollView>
    </CustomSafeArea>
  )
}

export default BundleScreen