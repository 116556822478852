import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import HeaderComponent from "../../components/Header";
import UploadPhotoComponent from "../../components/UploadPhotoComponent";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import CustomSafeArea from "../../components/CustomSfview";
import RNPickerSelect from 'react-native-picker-select';
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import * as ImagePicker from 'expo-image-picker';

import { useSelector } from "react-redux";
import DropdownSearch from "../../components/SearchableDropdown";



const data = [
    {
        'id':1,
        'catname':'Product'
    },
    {
        'id':2,
        'catname':'Service'
    }
]



const AddProductStep1 = () => {
    const navigation = useNavigation()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const {width} = useWindowDimensions()
    const [saletype, setSaleType] = useState(1)
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [subsubcatvalue, setSubSubCatValue] = useState(null)
    const [pcatValue, setPcatValue] = useState(null)
    const [image, setImage] = useState(false);
    const [prname, setPrName] = useState(null)
    const [pcat, setPcat] = useState([])
    const [psubCat, setPsubCat] = useState([])
    const [subsusbcat, setSubSubCat] = useState([])
    const [price, setPrice] = useState(null)
    const [cashback, setCashback] = useState('')
    const [stock, setStock] = useState('')
    const [unit, setUnit] = useState('')
    const [pdetail, setPdetail] = useState('')
    const [activity, setActiVity] = useState(false)

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: false,
          quality: 1,
        });
        
        if (result.canceled == false) {
          setImage(result.assets[0]);
        }
      };


    function addProduct() {
        if(image == false || prname == '' || price == '' || value == null) {
            alert('Please provide complete details')
        }else{
            setActiVity(true)
            const formData = new FormData()
            formData.append('image', {uri: image.uri, name:'adphoto', type:'image/jpeg'})
            formData.append('token', tknsts)
            formData.append('pname', prname)
            formData.append('price', price)
            formData.append('cashback', cashback)
            formData.append('stock', stock)
            formData.append('unit', unit)
            formData.append('description', pdetail)
            formData.append('subcat', value)

            axios.post(`${ServerData.uri}/addProduct/`, formData, {
                headers:{
                    "content-type": "multipart/form-data",
                },
                })
                .then(function (response) {
                    setActiVity(false)
                    if(response.data.code == 1){
                        navigation.navigate('UpDateProduct', {id:response.data.product})
                    }else{
                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    setActiVity(false)
                    console.log(error)
                });
        }
    }

    const placeholderStyle = {
        color: appColor.primary,
        fontFamily:appfont.semibold,
        fontSize:14,
      };

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background,
            justifyContent:'space-between'
        },
        sfview:{
            flex:1,
            justifyContent:'space-between'
        },
        scl:{
            paddingTop:10,
            paddingLeft:10,
            paddingRight:10,
        },
        bm:{
            flexDirection:'row',
            justifyContent:'space-between'
        },
        vrv:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.primary
        },
        txt3:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txgray
        },
        vv:{
            justifyContent:'center',
            alignItems:'center',
            marginBottom:10,
            marginTop:10
        },
        slm:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginTop:10
        },
        sl:{
            height:40,
            width:(width-40)/2,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center'
        },
        sl1:{
            height:40,
            width:(width-40)/2,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:appColor.txheadblack
        },
        slt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:14
        },
        slt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        slviw:{
            marginTop:10
        },
        dropdown:{
            height:50,
            width:width-20,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            borderRadius:10,
            paddingLeft:10,
            marginBottom:10
        },
        txt4:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14,
            marginBottom:5
        }
    })
    return (
            <CustomSafeArea>
            <View style={{flex:1}}>
            <HeaderComponent title={'Add Product'} hidect={true}/>
            <ScrollView style={styles.scl}>
                <UploadPhotoComponent title={'Add product images'} onpress={() => pickImage()} img={image}/>
                <View style={{marginTop:20}}></View>
                <CustomTextInput title={'Product Name *'} onchange={setPrName}/>
                <View style={styles.bm}>
                <CustomTextInput title={'₹ Price (MRP) *'} wd={(width-40)/2} onchange={setPrice}/>
                <CustomTextInput title={'% Cash Back'} wd={(width-40)/2} onchange={setCashback}/>
                </View>
                <View style={styles.bm}>
                <CustomTextInput title={'Stock'} wd={(width-40)/2} onchange={setStock}/>
                <CustomTextInput title={'Unit *'} wd={(width-40)/2} onchange={setUnit}/>
                </View>
                <CustomTextInput title={'Product Details *'} ht={100} ml={true} onchange={setPdetail}/>
                <View style={{marginTop:20}}></View>

                <DropdownSearch title={'Category'} urldta={`${ServerData.uri}/subsublist/`} itemproperty={'name'} onAddClick={() => navigation.navigate('addPSubSubCategory')}/>

                <Text style={styles.txt4}>Package Details</Text>
                <View style={styles.bm}>
                <CustomTextInput title={'Box Weight (kg)'} wd={(width-40)/2} onchange={setPrice}/>
                <CustomTextInput title={'Width (cm)'} wd={(width-40)/2} onchange={setCashback}/>
                </View>
                <View style={styles.bm}>
                <CustomTextInput title={'Height (cm)'} wd={(width-40)/2} onchange={setPrice}/>
                <CustomTextInput title={'Breadth (cm)'} wd={(width-40)/2} onchange={setCashback}/>
                </View>                
            </ScrollView>
            </View>
            <View style={styles.vv}>
                <CustomButton title={'Add Product'} ht={50} wt={width/1.6} bg={appColor.primary} tc={appColor.background} fs={18} ai={activity} onpress={() => addProduct()}/>
            </View>
            </CustomSafeArea>
    )
}

export default AddProductStep1