import { View, Text, ScrollView, TouchableOpacity, Share, ActivityIndicator, FlatList} from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import AgentCard from '../../components/AgentCard'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

const VendorListView = ({route}) => {
  const {nm, usrname} = route.params
  const storedata = useSelector((state) => state.appredux.storeData)
  const [userList, setUserList] = useState([])
  const tknsts = useSelector((state) => state.appredux.userToken)
  const navigation = useNavigation()
  const [loading, setLoading] = useState(false)
  const [pagenumber, setPagenumber] = useState(1)
  const [loadmore, setLoadMore] = useState(true)

  const reflink = `https://join.gibnu.com/${storedata.mobile}/`

  const handleShare = () => {
    const shareOptions = {
      message: `Join As Gibnu seller - EARN upto 20 Lacs/month Join us in the mission to Make India Digital (Digital Dukan - Digital Kishan) Powered by GIBNU. Click on the link ${reflink} and join as a seller today. `,
    };

    Share.share(shareOptions)
      .then((result) => {
        if (result.action === Share.sharedAction) {
          console.log('Sharing completed');
        } else if (result.action === Share.dismissedAction) {
          console.log('Sharing cancelled');
        }
      })
      .catch((error) => console.log('Error sharing:', error));
  };

  const loadmorewhenEnd = () => {
    if(loadmore == true){
      setPagenumber(pagenumber+1)
    }
  }


  useEffect(() => {
    function fecthUserLists() {
        setLoading(true)
        axios.get(`${ServerData.uri3}/vendorListView/${usrname}/?page=${pagenumber}`)
          .then(function (response) {
            setLoading(false)
            const newData = response.data.results;
            setUserList(prevList => [...prevList, ...newData])
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
            setLoadMore(false)
          })
        }
        fecthUserLists()
  },[pagenumber])

  const renderVendorCard = ({item}) => {
    return(
      <View style={{marginBottom:10}}>
          <AgentCard title={item.storename} vfstatus={item.verificationStatus} number={item.contactnumber} onpress={() => navigation.navigate('singleVendorScreen', {id: item.id, data:item})} location={item.city} proimg={item.logo}/>
      </View>
    )
  }

  const renderHeader = () => {
    return(
     <View>
      { loading ? null :
        userList.length == 0 ? 
        <Text style={{textAlign:"center", alignSelf:"center", fontFamily:appfont.regular, fontSize:10}}>No Sellers</Text>
        :
        null
        }
     </View>
    )
  }

  const renderFooter = () => {
    return(
      <View>
        {
          loading ? 
          <ActivityIndicator size='large' color={appColor.primary} />
          :
          null
        }
      </View>
    )
  }
  return (
    <CustomSafeArea>
      <HeaderComponent title={'Sellers'} onpress={() => handleShare()}/>
      <FlatList 
            data={userList}
            renderItem={renderVendorCard}
            keyExtractor={(item, index) => index.toString()}
            ListHeaderComponent={renderHeader}
            ListFooterComponent={renderFooter}
            onEndReached={loadmorewhenEnd}
            onEndReachedThreshold={0.5}
                />
    </CustomSafeArea>
  )
}

export default VendorListView