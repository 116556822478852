import { View, Text, ScrollView, useWindowDimensions, StyleSheet, ImageBackground, Image, Share } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import QRCode from 'react-native-qrcode-svg';
import ViewShot from "react-native-view-shot";
import * as Sharing from 'expo-sharing';
import { useSelector } from 'react-redux'
import Icon from 'react-native-vector-icons/FontAwesome'
import Icon2 from 'react-native-vector-icons/MaterialIcons'
import Icon3 from 'react-native-vector-icons/Feather'


const AgentVisitingCard = () => {
    const {width, height} = useWindowDimensions()
    const storedata = useSelector((state) => state.appredux.storeData)

    const viewShotRef = useRef(null);

    const captureView = async () => {
        if (viewShotRef.current) {
          try {
            const uri = await viewShotRef.current.capture();
            const text = "Check out visiting id card!";
            await Sharing.shareAsync(uri, { text });
          } catch (error) {
            console.error('Error capturing and sharing:', error);
          }
        }
      };


    const qrData = {
        agnetName:`${storedata.name} ${storedata.last_name}`,
        mobile:storedata.mobile,
        agentid:storedata.mobile
    }

    const qrDataString = JSON.stringify(qrData);

    const imgheight = (width-20)*0.5887
    const imgwidth = width - 20

    const styles = StyleSheet.create({
        vmain:{
            flex:1,
            backgroundColor:appColor.gray,
            alignItems:'center',
            paddingTop:height/10          
        },
        vimg:{
            width:imgwidth,
            height:imgheight,
            flexDirection:'row',
            justifyContent:'space-between',
            paddingLeft:imgwidth/50,
            paddingRight:imgwidth/50
        },
        glogo:{
            height:imgheight/10,
            width:imgwidth/5,
            tintColor:'#000000',
            resizeMode:'contain',
            marginTop:imgheight/3,
            marginRight:imgwidth/50
        },
        lft:{
            flex:1,
            marginRight:imgwidth/5,
            marginTop:imgheight/5,
            marginBottom:imgheight/10
        },
        nmtxt:{
            fontFamily:appfont.bold,
            textTransform:'uppercase',
            color:appColor.background,
            fontSize:imgheight/15,
        },
        pttxt:{
            fontFamily:appfont.bold,
            color:appColor.background,
            fontSize:imgheight/25,
        },
        nmview:{
            paddingBottom:imgheight/20,
            borderBottomWidth:2,
            borderBottomColor:'#FFCC00'
        },
        btmrow:{
            flex:1,
            marginTop:imgheight/20,
            justifyContent:'space-between'
        },
        row:{
            flexDirection:'row',
            alignItems:'center'
        },
        circle:{
            height:imgheight/12,
            width:imgheight/12,
            backgroundColor:'#F7BE19',
            borderRadius:imgheight/10,
            marginRight:imgwidth/50,
            justifyContent:'center',
            alignItems:'center'
        },
        stxt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:imgheight/25
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Visiting Card'} hidect={true} adbtn={true} adbtntitle={'Share Visiting Card'} onpress={() => captureView()}/>
        <View style={styles.vmain}>
            <ViewShot ref={viewShotRef} options={{format:'png', quality:1}}>
            <ImageBackground source={require('../../constants/img/vcard.png')} style={styles.vimg} imageStyle={{resizeMode:'stretch'}}>
                <View style={styles.lft}>
                    <View style={styles.nmview}>
                        <Text style={styles.nmtxt}>{storedata.name} {storedata.last_name}</Text>
                        {
                            storedata.employee == 1 ?
                            <Text style={styles.pttxt}>Support Executive</Text>
                        :
                        <Text style={styles.pttxt}>{storedata.parnterLevel ? storedata.parnterLevel : 'Silver Partner'}</Text>
                        }
                    </View>
                    <View style={styles.btmrow}>
                        <View style={styles.row}>
                            <View style={styles.circle}>
                                <Icon name='phone' size={imgheight/18} color={'#000000'}/>
                            </View>
                            <Text style={styles.stxt}>+91 - {storedata.mobile}</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.circle}>
                                <Icon name='globe' size={imgheight/18} color={'#000000'}/>
                            </View>
                            <Text style={styles.stxt}>WWW.GIBNU.COM</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.circle}>
                                <Icon2 name='location-on' size={imgheight/18} color={'#000000'}/>
                            </View>
                            <Text style={styles.stxt}>+91 - {storedata.mobile}</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.circle}>
                                <Icon3 name='mail' size={imgheight/18} color={'#000000'}/>
                            </View>
                            <Text style={styles.stxt}>{storedata.email}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Image source={require('../../constants/img/glogo.png')} style={styles.glogo}/>
                </View>
            </ImageBackground>
            </ViewShot>
        </View>
    </CustomSafeArea>
  )
}

export default AgentVisitingCard