import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appfont from "../../constants/other/font";
import HeaderComponent from "../../components/Header";
import StoreManageCard from "../../components/StoreManageCard";
import appColor from "../../constants/other/colors";
import { useSelector } from "react-redux";

const removedData = [
    {
        'id':5,
        'title':'Credit Card Payment',
        'color':'black',
        'icon':'credit-card',
        'route':'paymentlink',
    },
]

const manageData = [
    {
        'id':1,
        'title':'Dukaan (Seller)',
        'color':'#8402FA',
        'icon':'briefcase',
        'route':'vendorlistView'
    },
    {
        'id':2,
        'title':'Gibnu Agents',
        'color':'blue',
        'icon':'users',
        'route':'agentListView'
    },
    {
        'id':21,
        'title':'Employees',
        'color':'#075985',
        'icon':'user-plus',
        'route':'agentListView',
        'em':true
    },
    {
        'id':3,
        'title':'Delivery Boy/Girl',
        'color':'red',
        'icon':'package',
        'route':'DeliveryScreen'
    },
    {
        'id':4,
        'title':'Bulk Whatsapp',
        'color':'green',
        'icon':'whatsapp',
        'route':'bulkwhatsapp',
        'icstatus':true
    },
    {
        'id':6,
        'title':'130 Crores User Data',
        'color':'#053B50',
        'icon':'users',
        'route':'userdatasell',
        'icstatus':true
    },
    {
        'id':7,
        'title':'ID Card',
        'color':'#9381FF',
        'icon':'id-badge',
        'route':'AgentId',
        'icstatus':true
    },
    {
        'id':8,
        'title':'Visiting Card',
        'color':'#FFCC00',
        'icon':'vcard',
        'route':'AgentVisitingCard',
        'icstatus':true
    },
]


const ManageScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background
        },
        sfview:{
            flex:1
        },
        rr:{
            padding:10,
            flexDirection:'row',
            justifyContent:'space-between',
            flexWrap:'wrap'
        }
    })
    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <HeaderComponent title={'Platform Management'} hidect={true}/>
            <View style={styles.rr}>
            {
                manageData.map((item) => {
                    return(
                        <View key={item.id} style={{marginBottom:15}}>
                            <StoreManageCard title={item.title} colorr={item.color} ict={item.icstatus ? true : false} icon={item.icon} onpress={() => navigation.navigate(item.route, {nm:item.title, usrname:tknsts, type:1, emrequest: item.em? 1 : 0})}/>
                        </View>
                    )
                })
            }
            </View>
            </SafeAreaView>
        </View>
    )
}

export default ManageScreen