import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, useWindowDimensions, ScrollView } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useSelector } from "react-redux";
import HeaderComponent from "../../components/Header";
import VerifyCardComponent from "../../components/VerifyCard";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import axios from "axios";
import ServerData from "../../constants/other/serverData";

const ShopVerificationList = () => {
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [shopverifylist, setShopVerifyList] = useState([])

    const styles = StyleSheet.create({
        
    })

    return(
        <CustomSafeArea>
            <HeaderComponent title={'Shop Verification'} hidect={true}/>
            {/** 
            <VerifyCardComponent name={'Anand Samal'} address={'Plot 45, Near BJP Junior College, Bhubaneswar - 75009'}/>
            */}
            <Text style={{alignSelf:'center', 
                            marginTop:50,
                            fontFamily:appfont.regular,
                            color:appColor.txheadblack,
                            fontSize:12
              }}>No  pending verification.</Text>
        </CustomSafeArea>
    )
}

export default ShopVerificationList