import { View, Text, StyleSheet, useWindowDimensions } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import { WebView } from 'react-native-webview';
import { useSelector } from 'react-redux';
import appColor from '../../constants/other/colors';
import appfont from '../../constants/other/font';
import axios from 'axios';
import ServerData from '../../constants/other/serverData';

const SPlanPayPage = ({route, navigation}) => {
    const {payuri, pid, plancode, planprice, payble} = route.params;
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [checkpay, setCheckPay] = useState(1)
    const webViewRef = useRef(null);


    function checkStatusOfPayment() {
        axios.post(`${ServerData.uri3}/payagentsupportplan/`, {
            token:tknsts,
            plancode:plancode,
            pid:pid,
            stage:2
        })
        .then(function (response) {
            if(response.data.code == 1){
                if(response.data.status == 2){
                    alert(response.data.message)
                    navigation.goBack()
                }else if(response.data.status == 0){
                    alert(response.data.message)
                    navigation.goBack()
                }else{
                    setCheckPay(checkpay+1)
                }
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        setTimeout(() => {
            checkStatusOfPayment()
        }, 5000)
      },[checkpay])

    const styles = StyleSheet.create({
        mn:{
            padding:10,
            borderBottomWidth:1,
            borderColor:appColor.gray
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.primary
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Support Plan Payment'} hidect={true}/>
        <View style={styles.mn}>
            <Text style={styles.txt}>Plan Selected: ₹{planprice}</Text>
            <Text style={styles.txt}>Deduction From Wallet: ₹{planprice - payble}</Text>
            <Text style={styles.txt}>Payble Amount: ₹{payble}</Text>
        </View>
        <WebView 
            ref={webViewRef}
            style={{flex:1}}
            source={{uri:payuri}}
        />
    </CustomSafeArea>
  )
}

export default SPlanPayPage