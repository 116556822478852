import React, {useState, useEffect} from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeTab from "./BottomTab";
import AddServiceScreen from "../screens/management/AddService";
import SignUpScreen from "../screens/authentication/SignUp";

import * as SecureStore from 'expo-secure-store';
import { userToken, storeData } from "../toolkit/slice";
import { useSelector, useDispatch } from "react-redux";
import LoginScreen from "../screens/authentication/Signin";
import AddProductStep1 from "../screens/management/AddProductStep1";
import UpdateProductScreen from "../screens/management/UpDateProduct";
import OnlinePayment from "../screens/Wallet/Payment";
import VariantScreen from "../screens/management/Variant";
import BundleScreen from "../screens/management/Bundle";
import ShopVerificationList from "../screens/Agent/ShopVerificationList";
import DocumentAddPage from "../screens/Agent/DocumentAddPage";
import CustomerList from "../screens/managestore/CustomerList";
import Delivery from "../screens/managestore/Delivery";
import MarketingProduct from "../screens/managestore/MarketingProduct";
import PartnerVerificationList from "../screens/Agent/PartnerVerificationList";
import CopyShopPScreen from "../screens/Others/copyproduct.js/CopyShopPScreen";
import CopyProduct from "../screens/Others/copyproduct.js/CopyProduct";
import Storevisitor from "../screens/managestore/Storevisitor";
import FeatureScreen from "../screens/management/Features";
import BusinessCategoryAdd from "../screens/CategoryAddition/BusinessCategory";
import MainCategoryAdd from "../screens/CategoryAddition/MainCategory";
import SplashScreen from "../screens/Splash/Splash";
import AddPSubSubCategory from "../screens/CategoryAddition/AddPSubSubCatgory";
import StoreIdQr from "../screens/CategoryAddition/StoreIdQr";
import UploadDocumnets from "../screens/Profile/UploadDocumnets";
import AgentChangeScreen from "../screens/agnt/AgentChange";
import LoanApplicationScreen from "../screens/agnt/LoanApplication";
import MarketingSales from "../screens/agnt/MarketingSales";
import EarningScreen from "../screens/agnt/Earning";
import QrScanner from "../screens/authentication/QrScanner";
import AgentListView from "../screens/managestore/Agents";
import VendorListView from "../screens/managestore/Vendor";
import SingleAgentScreen from "../screens/managestore/Agents/SingleAgent";
import SendScreen from "../screens/Wallet/SendScreen";
import ReceiveScreen from "../screens/Wallet/ReceiveScreen";
import SucessScreenWallet from "../screens/Wallet/SucessScreen";
import EditProfile from "../screens/Profile/EditProfile";
import AddDocumentCategory from "../screens/Profile/AddDocumentCategory";
import ViewFileScreen from "../screens/Profile/ViewFileScreen";
import SendFundRequest from "../screens/Wallet/SendFundRequest";
import CheckUserScreen from "../screens/authentication/CheckUser";
import EditAddress from "../screens/Profile/EditAddress";
import SingleVendor from "../screens/managestore/Vendor/SingleVendor";
import VerifySellerMobile from "../screens/managestore/Vendor/SellerAddition/VerifySellerMobile";
import ProductScreen from "../screens/Tab/Product";
import SellerDetails from "../screens/managestore/Vendor/SellerDetails";
import AgentDocuments from "../screens/managestore/Agents/AgentDocumnet";
import AgentListView2 from "../screens/managestore/AgentList2";
import BulkWhatsapp from "../screens/managestore/Whatsapp.js/BulkWhatsapp";
import BuyWpackage from "../screens/managestore/Whatsapp.js/BuyWpackage";
import RestrictedItems from "../screens/Others/RestrictedItems";
import SingleDeliveryPartner from "../screens/managestore/Delivery/SingleDeliveryPartner";
import PolicyView from "../screens/Profile/PolicyWebView";
import ForgotPassword from "../screens/authentication/ForgotPassword";
import SingleAgentScreen2 from "../screens/managestore/Agents/SingleAgent2";
import AgentListView3 from "../screens/managestore/AgentList3";
import CreditCardLinks from "../screens/managestore/Payment.js/CreditCardLinks";
import BankAccount from "../screens/Profile/BankAccount";
import UserDataSell from "../screens/managestore/130crore/UserDataSell";

import axios from "axios";
import ServerData from "../constants/other/serverData";
import AgentCreditCardTransaction from "../screens/managestore/Payment.js/AgentCreditCardTransaction";
import Withdraw from "../screens/Wallet/Withdraw";
import AgentId from "../screens/managestore/AgentId";
import AgentVisitingCard from "../screens/managestore/AgentVisitingCard";
import CashReferReduction from "../screens/managestore/Vendor/CashReferReduction";
import LeadViewer from "../screens/managestore/Vendor/LeadViewer";
import ChangeWalletPin from "../screens/Wallet/ChangeWalletPin";
import ProductDetailsScreen from "../screens/managestore/Vendor/ProductDetailsScreen";
import ServiceDetailsScreen from "../screens/managestore/Vendor/ServiceDetailsScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SupportPlans from "../screens/Profile/SupportPlans";
import SPlanPayPage from "../screens/Profile/SPlanPayPage";
import UPIPayment from "../screens/manualUPI/UPIPayment";
import PointExchange from "../screens/Wallet/PointExchange";
import NotificationPage from "../screens/Profile/NotificationPage";
import SendWithdrawPoint from "../screens/Wallet/SendWithdrawPoint";
import NotifyDetail from "../screens/Profile/NotifyDetail";
import LeaderChange from "../screens/agnt/LeaderChange";
import UPIPayList from "../screens/Wallet/UPIPayList";
import LumosysCommision from "../screens/agnt/LumosysCommision";
import AgentChangeByLeader from "../screens/managestore/Agents/AgentChangeByLeader";


const Stack = createNativeStackNavigator()

const HomeScreeNavigator = () => {

   const tknsts = useSelector((state) => state.appredux.userToken)
   const dispatch = useDispatch()

   async function setToLocalStorage(object){
      const strungifyObj = JSON.stringify(object)
      await AsyncStorage.setItem('agentdata', strungifyObj)
  }
   useEffect(() => {
      async function getToken() {
          const key = await SecureStore.getItemAsync('token')
          const agdata = await AsyncStorage.getItem('agentdata')
          if (key !== null) {
            dispatch(userToken(key))
          }
          if(agdata !== null){
            const loc_agdata = JSON.parse(agdata)
            dispatch(storeData(loc_agdata))
          }
      }
      getToken()
  }, [])

  useEffect(() => {
      function getAgentData(){
         if(tknsts !== null){
            axios.post(`${ServerData.uri3}/checkagentstatus/`, {
               token:tknsts,
               })
               .then(function (response) {
                   if(response.data.code == 1){
                       dispatch(storeData(response.data.storedata))
                       setToLocalStorage(response.data.storedata)
                   }
               })
               .catch(function (error) {
                   console.log(error)
               });
         }
      }
      getAgentData()
  },[tknsts])

    return (
        <Stack.Navigator
         screenOptions={{
            headerShown:false,
            tabBarShowLabel:false,
         }}
         
         >  
         {
            tknsts == null ?
            <> 
            <Stack.Screen
                 name="Splash"
                 component={SplashScreen}
              /> 
            <Stack.Screen 
                 name="chekuser"
                 component={CheckUserScreen}
              />
            <Stack.Screen
                 name="Signup"
                 component={SignUpScreen}
              /> 
               <Stack.Screen
                 name="Login"
                 component={LoginScreen}
              /> 
              <Stack.Screen
                 name="ForgotPassword"
                 component={ForgotPassword}
              /> 
              <Stack.Screen
                 name="BusinessCatAdd"
                 component={BusinessCategoryAdd}
              /> 
              <Stack.Screen
                 name="MainCatAdd"
                 component={MainCategoryAdd}
              /> 
               <Stack.Screen
                 name="QrScannerLogin"
                 component={QrScanner}
              /> 

            </>
            :
         <>
            <Stack.Screen
                 name="AppHome"
                 component={HomeTab}
              /> 
              <Stack.Screen
                 name="UpDateProduct"
                 component={UpdateProductScreen}
              /> 
              <Stack.Screen
                 name="AddProductStep1"
                 component={AddProductStep1}
              /> 
              <Stack.Screen
                 name="AddService"
                 component={AddServiceScreen}
              />
              <Stack.Screen 
                 name="OnlinePayment"
                 component={OnlinePayment}
              /> 
              <Stack.Screen 
                 name="AddVariant"
                 component={VariantScreen}
              />
              <Stack.Screen 
                 name="AddBundles"
                 component={BundleScreen}
              />
              <Stack.Screen 
                 name="AddFeatures"
                 component={FeatureScreen}
              />
              <Stack.Screen 
                 name="ShopVerificationList"
                 component={ShopVerificationList}
              />
              <Stack.Screen 
                 name="PartnerVerificationList"
                 component={PartnerVerificationList}
              />
              <Stack.Screen 
                 name="AddDocument"
                 component={DocumentAddPage}
              />
              <Stack.Screen 
                 name="CustomerList"
                 component={CustomerList}
              />
              <Stack.Screen 
                 name="DeliveryScreen"
                 component={Delivery}
              />
              <Stack.Screen 
                 name="SingleDeliveryPartner"
                 component={SingleDeliveryPartner}
              />
              <Stack.Screen 
                 name="MarketingProduct"
                 component={MarketingProduct}
              />
              <Stack.Screen 
                 name="copyshop"
                 component={CopyShopPScreen}
              />
              <Stack.Screen 
                 name="copyproduct"
                 component={CopyProduct}
              />
              <Stack.Screen 
                 name="storevisitor"
                 component={Storevisitor}
              />
              <Stack.Screen 
                 name="addPSubSubCategory"
                 component={AddPSubSubCategory}
              />
              <Stack.Screen
                 name="MainCatAdd"
                 component={MainCategoryAdd}
              /> 
              <Stack.Screen
                 name="BusinessCatAdd"
                 component={BusinessCategoryAdd}
              />
              <Stack.Screen 
                 name="StoreID"
                 component={StoreIdQr}
              />
              <Stack.Screen 
                 name="uploaddoc"
                 component={UploadDocumnets}
              />
              <Stack.Screen 
                 name="agntchange"
                 component={AgentChangeScreen}
              />
              <Stack.Screen 
                 name="loanapplns"
                 component={LoanApplicationScreen}
              />
              <Stack.Screen 
                 name="mktsales"
                 component={MarketingSales}
              />
              <Stack.Screen 
                 name="earnings"
                 component={EarningScreen}
              />
              <Stack.Screen 
                 name="agentListView"
                 component={AgentListView}
              />
              <Stack.Screen 
                 name="agentListView2"
                 component={AgentListView2}
              />
              <Stack.Screen 
                 name="agentListView3"
                 component={AgentListView3}
              />
              <Stack.Screen 
                 name="vendorlistView"
                 component={VendorListView}
              />
              <Stack.Screen 
                 name="singleAgentScreen"
                 component={SingleAgentScreen}
              />
              <Stack.Screen 
                 name="singleAgentScreen2"
                 component={SingleAgentScreen2}
              />
              <Stack.Screen 
                 name="singleVendorScreen"
                 component={SingleVendor}
              />
              <Stack.Screen 
                 name="sendCashBackScreen"
                 component={SendScreen}
              />
              <Stack.Screen 
                 name="receiveCashBackScreen"
                 component={ReceiveScreen}
              />
              <Stack.Screen 
                 name="walletsuccess"
                 component={SucessScreenWallet}
              />
              <Stack.Screen 
                 name="editprofile"
                 component={EditProfile}
              />
              <Stack.Screen 
                 name="addoccatgory"
                 component={AddDocumentCategory}
              />
              <Stack.Screen 
                 name="viewfile"
                 component={ViewFileScreen}
              />
              <Stack.Screen 
                 name="sendFundRequest"
                 component={SendFundRequest}
              />
              <Stack.Screen 
                 name="editaddress"
                 component={EditAddress}
              />
              <Stack.Screen 
                 name="verifysellermobile"
                 component={VerifySellerMobile}
              />
              <Stack.Screen 
                 name="productScreen"
                 component={ProductScreen}
              />
              <Stack.Screen 
                 name="sellerdetails"
                 component={SellerDetails}
              />
               <Stack.Screen 
                 name="agentdocuments"
                 component={AgentDocuments}
              />
              <Stack.Screen 
                 name="bulkwhatsapp"
                 component={BulkWhatsapp}
              />
              <Stack.Screen 
                 name="buyWpackage"
                 component={BuyWpackage}
              />  
              <Stack.Screen 
                 name="restrictedItems"
                 component={RestrictedItems}
              />
              <Stack.Screen 
                 name="policyview"
                 component={PolicyView}
              />
            <Stack.Screen 
                 name="paymentlink"
                 component={CreditCardLinks}
              />
              <Stack.Screen 
                 name="addbankaccount"
                 component={BankAccount}
              />
              <Stack.Screen 
                 name="userdatasell"
                 component={UserDataSell}
              />
              <Stack.Screen 
                 name="AgentCreditCardTransaction"
                 component={AgentCreditCardTransaction}
              />
              <Stack.Screen 
                 name="Withdraw"
                 component={Withdraw}
              />
              <Stack.Screen 
                 name="AgentId"
                 component={AgentId}
              />
              <Stack.Screen 
                 name="AgentVisitingCard"
                 component={AgentVisitingCard}
              />
              <Stack.Screen 
                 name="CashReferReduction"
                 component={CashReferReduction}
              />
              <Stack.Screen 
                 name="LeadViewer"
                 component={LeadViewer}
              />
               <Stack.Screen 
                 name="ChangeWalletPin"
                 component={ChangeWalletPin}
              />
            <Stack.Screen 
                 name="ProductDetailsScreen"
                 component={ProductDetailsScreen}
              />
            <Stack.Screen 
                 name="ServiceDetailsScreen"
                 component={ServiceDetailsScreen}
              />
              <Stack.Screen 
                 name="SupportPlans"
                 component={SupportPlans}
              />
              <Stack.Screen 
                 name="SPlanPayPage"
                 component={SPlanPayPage}
              />
              <Stack.Screen 
                 name="UPIPayment"
                 component={UPIPayment}
              />
              <Stack.Screen 
                 name="PointExchange"
                 component={PointExchange}
              />
              <Stack.Screen 
                 name="NotificationPage"
                 component={NotificationPage}
              />
              <Stack.Screen 
                 name="SendWithdrawPoint"
                 component={SendWithdrawPoint}
              />
              <Stack.Screen 
                 name="NotifyDetail"
                 component={NotifyDetail}
              />
              <Stack.Screen 
                 name="LeaderChange"
                 component={LeaderChange}
              />
              <Stack.Screen 
                 name="UPIPayList"
                 component={UPIPayList}
              />
              <Stack.Screen 
                 name="LumosysCommision"
                 component={LumosysCommision}
              />
              <Stack.Screen 
                 name="AgentChangeByLeader"
                 component={AgentChangeByLeader}
              />
              </>

         }

         </Stack.Navigator>
    )
}


export default HomeScreeNavigator