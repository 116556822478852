import { View, Text, FlatList, ActivityIndicator } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import DropdownSearch from '../../../components/SearchableDropdown'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import { useSelector } from 'react-redux'
import CustomTextInput from '../../../components/customTextInput'
import AgentCard from '../../../components/AgentCard'
import appColor from '../../../constants/other/colors'
import CustomButton from '../../../components/customButton'
import { useNavigation } from '@react-navigation/native'

const AgentChangeByLeader = ({route}) => {
    const {agentid} = route.params;
    const navigation = useNavigation()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [loading, setLoading] = useState(false)
    const [searchtext, setSearchText] = useState('')
    const [userList, setUserList] = useState([])
    const [selectedId, setSelectedId] = useState(null)


    function changeLeader(){
        if(selectedId == null){
            alert('Please select a agent')
        }else{
            setLoading(true)
            axios.post(`${ServerData.uri3}/leader_change_by_agnet/`, {
                token:tknsts,
                agentid:agentid,
                leader_id:selectedId
            })
            .then(response => {
                setLoading(false)
                if(response.data.code == 1){
                    alert(response.data.message)
                    navigation.goBack()
                }else if(response.data.code == 0){
                    alert(response.data.message)
                }
              })
              .catch(error => {
                setLoading(false)
                  console.log(error)
              });
        }
        
    }


    function searchOnTextChange(txt){
        setLoading(true)
        axios.get(`${ServerData.uri3}/agentListView/${tknsts}/?search=${txt}`)
          .then(function (response) {
            setLoading(false)
            console
            const newData = response.data.results;
            setUserList(newData)
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
          })
  }

  const renderAgentCard = ({item}) => {
    return(
      <View style={{marginBottom:10, backgroundColor:selectedId == item.id ? appColor.primary : appColor.background, paddingBottom:10}}>
          <AgentCard title={`${item.user.first_name} ${item.user.last_name}`} number={item.user.username} vfstatus={item.verificationStatus} proimg={item.profileimage} teamsize={item.total_agents_under} onpress={() => setSelectedId(item.id)}/>
      </View>
    )
  }

  const renderHeader = () => {
    return(
        loading ? 
        <View>
            <ActivityIndicator size='large' color={appColor.primary} />
        </View>
        :
        <View></View>
    )
  }
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Agent Change'} hidect={true}/>
        <View style={{flex:1}}>
            <CustomTextInput title={'Search Agent'} onchange={searchOnTextChange}/>
            <FlatList 
                data={userList}
                renderItem={renderAgentCard}
                keyExtractor={(item, index) => index.toString()}
                ListHeaderComponent={renderHeader}
            />
            {
                selectedId == null ?
                null :
                <View style={{paddingTop:10}}>
                <CustomButton title={'Change To Selected Leader'} bg={appColor.red} tc={appColor.background} ai={loading} onpress={() => changeLeader()}/>
            </View>
            }
        </View>
    </CustomSafeArea>
  )
}

export default AgentChangeByLeader