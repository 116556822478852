import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import HeaderComponent from "../../components/Header";
import UploadPhotoComponent from "../../components/UploadPhotoComponent";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import ServerData from "../../constants/other/serverData";
import axios from "axios";



const UpdateProductScreen = ({route}) => {
    const {id} = route.params;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [productData, setProductData] = useState(null)
    const [image, setImage] = useState(false);
    const [prname, setPrName] = useState(null)
    const [price, setPrice] = useState(null)
    const [cashback, setCashback] = useState('')
    const [stock, setStock] = useState('')
    const [unit, setUnit] = useState('')
    const [pdetail, setPdetail] = useState('')
    const [activity, setActiVity] = useState(false)


    useEffect(() => {
        function getproductdata() {
            axios.get(`${ServerData.uri2}/getsignleproduct/${id}/`)
            .then(response => {
                setPrName(response.data.name)
                setPrice(response.data.price)
                setCashback(response.data.cashback)
                setPdetail(response.data.shortDescription)
            })
            .catch(error => {

            });
        }
        getproductdata()
    },[])

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background,
            justifyContent:'space-between'
        },
        sfview:{
            flex:1,
            justifyContent:'space-between'
        },
        scl:{
            paddingTop:10,
            paddingLeft:10,
            paddingRight:10,
        },
        bm:{
            flexDirection:'row',
            justifyContent:'space-between'
        },
        vrv:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.primary
        },
        txt3:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txgray
        },
        vv:{
            justifyContent:'center',
            alignItems:'center',
            marginBottom:10,
            marginTop:10
        },
        slm:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginTop:10
        },
        sl:{
            height:40,
            width:(width-40)/2,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center'
        },
        sl1:{
            height:40,
            width:(width-40)/2,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:appColor.txheadblack
        },
        slt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:14
        },
        slt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        slviw:{
            marginTop:10
        }
    })
    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <View style={{flex:1}}>
            <HeaderComponent title={'Update Product'} hidect={true}/>
            <ScrollView style={styles.scl}>
                <UploadPhotoComponent title={'Add product images (upto 8)'}/>
                <View style={{marginTop:20}}></View>
                <CustomTextInput title={'Product Name *'} value={prname} onchange={setPrName}/>
                <View style={styles.bm}>
                <CustomTextInput title={'₹ Price *'} wd={(width-40)/2} value={price} onchange={setPrice}/>
                <CustomTextInput title={'% Cash Back'} wd={(width-40)/2} value={cashback} onchange={setCashback}/>
                </View>
                <View style={styles.bm}>
                <CustomTextInput title={'Stock'} wd={(width-40)/2} onchange={setCashback} value={cashback}/>
                <CustomTextInput title={'Unit *'} wd={(width-40)/2} onchange={setUnit} value={unit}/>
                </View>
                <CustomTextInput title={'Product Details *'} ht={100} ml={true} value={pdetail} onchange={setPdetail}/>
                <View style={{marginTop:20}}></View>
                <View style={styles.vrv}>
                    <TouchableOpacity style={styles.vr} onPress={() => navigation.navigate('AddFeatures', {id:id})}>
                        <Text style={styles.txt2}>+ ADD FEATURES</Text>
                    </TouchableOpacity>
                    <Text style={styles.txt3}>2 Variants</Text>
                </View>
                <View style={styles.vrv}>
                    <TouchableOpacity style={styles.vr} onPress={() => navigation.navigate('AddVariant')}>
                        <Text style={styles.txt2}>+ ADD VARIANTS</Text>
                    </TouchableOpacity>
                    <Text style={styles.txt3}>2 Variants</Text>
                </View>
                <View style={styles.vrv}>
                    <TouchableOpacity style={styles.vr} onPress={() => navigation.navigate('AddBundles', {id:id})}>
                        <Text style={styles.txt2}>+ ADD BUNDLES</Text>
                    </TouchableOpacity>
                    <Text style={styles.txt3}>1 Bundles</Text>
                </View>
                <View style={{height:30}}></View>
            </ScrollView>
            </View>
            <View style={styles.vv}>
                <CustomButton title={'Update Product'} ht={50} wt={width/1.6} bg={appColor.primary} tc={appColor.background} fs={18}/>
            </View>
            </SafeAreaView>
        </View>
    )
}

export default UpdateProductScreen