import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Image, Alert, Linking } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { useSelector } from 'react-redux'

const SupportPlans = ({navigation}) => {
    const [planlist, setPlanList] = useState([])
    const {width} = useWindowDimensions()
    const [loading, setLoading] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [activeplanid, setActivePlanid] = useState(null)
    const [paybleamount, setPaybleAmount] = useState('')
    const storedata = useSelector((state) => state.appredux.storeData)



    function changePlanidselected(id){
        const foundObject = planlist.find(obj => obj.id === id);
        setSelectedPlan(id)
        setPaybleAmount(foundObject.minimum_payble)
    }

    function getUpiPayDetails() {
        if(selectedPlan == null){
            alert('Please select a plan')
        }else if(selectedPlan == activeplanid){
            alert('You already subscribed to this plan')
        }
        else{
            setLoading(true)
            axios.post(`${ServerData.admin}/getmanualupiid/`, {
                token:tknsts,
                purpose:'AGNT_SPT',
                amount:paybleamount
            })
            .then(function (response) {
                setLoading(false)
                if(response.data.code == 1){
                    const paydata = {
                        upiid:response.data.updata.upiid,
                        sellername:response.data.updata.name,
                        amount:paybleamount,
                        purpose:'Agent Support Plan'
                    }
                    navigation.navigate('UPIPayment', {paydata: paydata})
                }else if(response.data.code == 0){
                    Alert.alert('Alert', response.data.message, [
                        {
                            text: "Cancel",
                            style:'cancel'
                        },
                        {
                            text:'Call Agent',
                            onPress: () => Linking.openURL(`tel:+91${storedata.agentmobile ? storedata.agentmobile : '0000'}`)
                        }
                    ]);
                }
            })
            .catch(function (error) {
                setLoading(false)
                alert('Unexpected Error')
                console.log(error)
            });
        }
    }

    function chekPlan(){
        axios.get(`${ServerData.uri3}/supportplans/?token=${tknsts}`)
        .then(function (response) {
            setPlanList(response.data.results)
            if(response.data.selected_plan){
                setSelectedPlan(response.data.selected_plan)
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }


    useEffect(() => {
        chekPlan()
    },[])

    function changeVPlans(){
        if(selectedPlan == null){
            alert('Please select a plan')
        }else if(selectedPlan == activeplanid){
            alert('You already subscribed to this plan')
        }
        else{
            setLoading(true)
            axios.post(`${ServerData.uri3}/payagentsupportplan/`, {
                token:tknsts,
                plancode:selectedPlan,
                stage:1
            })
            .then(function (response) {
                setLoading(false)
                if(response.data.code == 1){
                    navigation.navigate('SPlanPayPage', {payuri: response.data.paylink, pid: response.data.tid, plancode: response.data.plancode, planprice: response.data.planprice, payble:response.data.payble})
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
            });
        }
    }



    const styles = StyleSheet.create({
        pl:{
            width:(width-40)/3,
            justifyContent:'center',
            alignItems:'center',
            height:160,
            borderWidth:1,
            borderColor:appColor.primary,
            borderRadius:5
        },
        pl2:{
            width:(width-40)/3,
            justifyContent:'center',
            alignItems:'center',
            height:160,
            borderWidth:1,
            borderColor:appColor.primary,
            borderRadius:5,
            backgroundColor:'#E8FFCE'
        },
        simg:{
            height:width/6,
            width:width/6,
            resizeMode:'contain'
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.primary,
            fontSize:12,
            marginTop:10
        },
        price:{
            fontFamily:appfont.semibold,
            color:appColor.green,
            fontSize:16,
        }
    })


  return (
    <CustomSafeArea>
        <HeaderComponent title={'Plans'} hidect={true}/>
        <View style={{flex:1}}>
            <View style={{flexDirection:"row", justifyContent:'space-between', paddingLeft:10,paddingRight:10, marginTop:10}}>
                {
                    planlist.map((item) => {
                        return(
                            <TouchableOpacity key={item.id} style={selectedPlan == item.id ? styles.pl2 : styles.pl} onPress={() => changePlanidselected(item.id)}>
                                <Image source={{uri : item.image}} style={styles.simg}/>
                                <Text style={styles.txt}>{item.name}</Text>
                                <Text style={styles.price}>₹{item.price.slice(0,-3)}</Text>
                            </TouchableOpacity>
                        )
                    })
                }
            </View>
            <View style={{alignSelf:"center", marginTop:20}}>
            {/** 
                selectedPlan !== null ?
                <CustomTextInput title={'Payble Amount'} wd={width-20} keytpe={'number-pad'} value={paybleamount} onchange={setPaybleAmount}/>
                :
                null

            */}
            <CustomButton title={'Choose Plans'} bg={appColor.primary} tc={appColor.background} wt={width-20} onpress={() => getUpiPayDetails()} ai={loading}/>
            </View>
        </View>
    </CustomSafeArea>
  )
}

export default SupportPlans