import React, {useState, useEffect} from "react";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import HeaderComponent from "../../components/Header";
import UploadPhotoComponent from "../../components/UploadPhotoComponent";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";



const AddServiceScreen = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:appColor.background,
            justifyContent:'space-between'
        },
        sfview:{
            flex:1,
            justifyContent:'space-between'
        },
        scl:{
            paddingTop:10,
            paddingLeft:10,
            paddingRight:10,
        },
        bm:{
            flexDirection:'row',
            justifyContent:'space-between'
        },
        vrv:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.primary
        },
        txt3:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txgray
        },
        vv:{
            justifyContent:'center',
            alignItems:'center',
            marginBottom:10
        }
    })
    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.sfview}>
            <View>
            <HeaderComponent title={'Add Service'} hidect={true}/>
            <ScrollView style={styles.scl}>
                <UploadPhotoComponent title={'Add service images'}/>
                <View style={{marginTop:20}}></View>
                <CustomTextInput title={'Service Name *'}/>
                <CustomTextInput title={'Service Category'}/>
                <View style={styles.bm}>
                <CustomTextInput title={'₹ Price *'} wd={(width-40)/2}/>
                <CustomTextInput title={'% Cash Back'} wd={(width-40)/2}/>
                </View>
                <CustomTextInput title={'Service Details *'} ht={100} ml={true}/>
                <View style={{marginTop:20}}></View>
            </ScrollView>
            </View>
            <View style={styles.vv}>
                <CustomButton title={'Add Service'} ht={50} wt={width/1.6} bg={appColor.primary} tc={appColor.background} fs={18}/>
            </View>
            </SafeAreaView>
        </View>
    )
}

export default AddServiceScreen