import React from 'react'
import { View, Text, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'

const VariantScreen = () => {
  const {width} = useWindowDimensions()
  return (
    <CustomSafeArea>
      <HeaderComponent title={'Add Variant'} hidect={true}/>
    </CustomSafeArea>
  )
}

export default VariantScreen