import React, {useState, useEffect} from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'

const CustomerList = () => {
  const [customerdata, setCustomerdata] = useState([])

  const styles = StyleSheet.create({
    ndata:{
      flex:1,
      justifyContent:'center',
      alignItems:'center'
    }
  })

  return (
    <CustomSafeArea>
      <HeaderComponent hidect={true} adbtn={true} adbtntitle={'BUY CUSTOMER'} title={'Customers'}/>
      {
        customerdata.length == 0 ?
        <View style={styles.ndata}>
            <Text>No customers found</Text>
        </View>
        :
        null
      }
    </CustomSafeArea>
  )
}

export default CustomerList