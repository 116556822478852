import { View, Text, ScrollView, TouchableOpacity, StyleSheet, FlatList, Platform, Modal, useWindowDimensions, Linking, ActivityIndicator } from 'react-native'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Icon from 'react-native-vector-icons/AntDesign'
import { Image } from 'expo-image'
import CustomButton from '../../components/customButton'


const statuscodes = [
    {
        'id':1,
        'code':'ALL'
    },
    {
        'id':2,
        'code':'PENDING'
    },
    {
        'id':3,
        'code':'ACCEPTED'
    },
    {
        'id':4,
        'code':'REJECTED'
    }
]

const UPIPayList = () => {
    const [selectedcode, setSelectedCode] = useState('ALL')
    const [paylist, setPayList] = useState([])
    const [pagenumber, setPageNumber] = useState(1)
    const [hasnext, sethasMOre] = useState(true)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [loading, setLoading] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const {height, width} = useWindowDimensions()
    const [payid, setPayId] = useState(null)
    const [btnload, setBTNLoad] = useState(false)

    function handleStatuschange(ssid){
        setPageNumber(1)
        setSelectedCode(ssid)
    }

    function LoadMore(){
        if(hasnext){
            setPageNumber(pagenumber+1)
        }
    }

    function handlePayment(status, payid){
        setBTNLoad(true)
        axios.post(`${ServerData.admin}/approverejectpayment/`, {
            token:tknsts,
            status:status,
            id:payid,
            })
            .then(function (response) {
                setBTNLoad(false)
                alert(response.data.message)
                setModalVisible(false)
                refreshpaydata()
            })
            .catch(function (error) {
                setBTNLoad(false)
                alert('Unexpected Error')
            });
    }

    function changeModal(id){
        setPayId(id)
        setModalVisible(true)
    }

    function refreshpaydata() {
        setLoading(true)
        axios.get(`${ServerData.admin}/upiverifylist/?token=${tknsts}&status=${selectedcode}&page=${pagenumber}`)
        .then(response => {
            setLoading(false)
            setPayList(response.data.results)
            sethasMOre(true)
            setPageNumber(1)
        })
        .catch(error => {
            setLoading(false)
            sethasMOre(false)
            console.log(error)
        });
    }


    useEffect(() => {
        function getUpipaydata() {
            setLoading(true)
            axios.get(`${ServerData.admin}/upiverifylist/?token=${tknsts}&status=${selectedcode}&page=${pagenumber}`)
            .then(response => {
                setLoading(false)
                if (response.data.next == null) {
                    sethasMOre(false)}
                if(pagenumber == 1){
                    setPayList(response.data.results)
                }else{
                    setPayList((prevData) => [...prevData, ...response.data.results])
                }
            })
            .catch(error => {
                setLoading(false)
                sethasMOre(false)
                console.log(error)
            });
        }
        getUpipaydata()
    },[selectedcode, pagenumber])

    const renderUPICard = ({item}) => {
        return(
            <TouchableOpacity style={styles.pcard} onPress={() => changeModal(item)}>
                <View>
                <Text style={styles.txt}>Payee: {item.pay_user.first_name} {item.pay_user.last_name}</Text>
                <Text style={styles.txt}>Amount: ₹{item.amount}</Text>
                <Text style={styles.txt}>Time: {item.txn_date.slice(0,10)} : {item.txn_date.slice(11,16)}</Text>
                </View>
                <View>
                    <Text>{item.status}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const renderfototer = () => {
        return(
            <View>
                {
                    loading ? 
                    <ActivityIndicator size='large' color={appColor.primary} />
                    :
                    null
                }
            </View>
        )
    }

    const styles = StyleSheet.create({
        scrl:{
            paddingLeft:10,
            marginTop:10,
            marginBottom:10
        },
        stbtn:{
            borderWidth:2,
            borderColor:appColor.primary,
            marginRight:10,
            padding:10,
            borderRadius:50
        },
        bttxt:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.primary
        },
        pcard:{
            backgroundColor:appColor.background,
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
            padding:10,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            margin:10
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },

        ml:{
            flex:1,
            backgroundColor:'rgba(0, 0, 0, 0.8)',
            justifyContent:"center",
            alignItems:'center'
        },
        tchm:{
            flex:1
        },
        inside:{
            height:height-100,
            width:width,
            backgroundColor:appColor.background
        },
        itv:{
            padding:10,
            borderBottomWidth:1,
            borderBottomColor:appColor.gray,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },
        txtm:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        img:{
            height:height-300,
            width:width-20
        }
    
    })
  return (
    <CustomSafeArea>
        <Modal
            animationType='slide'
            transparent={true}
            visible={modalVisible}
        >
            <View style={styles.ml}>
            <TouchableOpacity style={styles.tchm} onPress={() => setModalVisible(false)}>

            </TouchableOpacity>
            <View style={styles.inside}>
            <View style={styles.itv}>
                <Text style={styles.txtm}>VERIFY PAYMENT</Text>
                <TouchableOpacity onPress={() => setModalVisible(false)}>
                    <Icon name='close' size={20} color={appColor.txheadblack}/>
                </TouchableOpacity>
            </View>
            <ScrollView>
                <Text style={{margin:10, fontFamily:appfont.semibold, color:appColor.txheadblack, fontSize:15}}>Amount: ₹{payid && payid.amount ? payid.amount : ''}</Text>
                <View style={{justifyContent:'center', alignItems:'center'}}>
                {
                    payid && payid.pay_proof ?
                    <Image source={{uri : payid.pay_proof}} contentFit='contain' style={styles.img}/>
                    :
                    null
                }
                </View>
                {
                    payid ?
                
                <View style={{flexDirection:'row', justifyContent:'space-between', padding:20}}>
                    <CustomButton title={'APPROVE'} wt={width/4} bg={appColor.green} tc={appColor.background} onpress={() => handlePayment('APPROVE', payid.id)} ai={btnload}/>
                    <CustomButton title={'REJECT'} wt={width/4} bg={appColor.red} tc={appColor.background} onpress={() => handlePayment('REJECT', payid.id)} ai={btnload}/>
                    <CustomButton title={'CALL'} wt={width/4} bg={appColor.primary} tc={appColor.background} onpress={() => payid ? Linking.openURL(`tel:+91${payid.pay_user.username}`): null} />
                </View>
                :
                null }
            </ScrollView>
            </View>
            </View>
        </Modal>
        <HeaderComponent title={'Payment List'} hidect={true}/>
        <View>
        <ScrollView style={styles.scrl} horizontal={true}>
            {
                statuscodes.map((item) => {
                    return(
                        <TouchableOpacity key={item.id} style={[styles.stbtn, {backgroundColor: selectedcode == item.code ? appColor.primary : appColor.background}]} onPress={() => handleStatuschange(item.code)}>
                            <Text style={[styles.bttxt, {color: selectedcode == item.code ? appColor.background : appColor.primary}]}>{item.code}</Text>
                        </TouchableOpacity>
                    )
                })
            }
        </ScrollView>
        </View>
        <FlatList 
            renderItem={renderUPICard}
            key={item => item.id}
            data={paylist}
            ListFooterComponent={renderfototer}
            onEndReached={() => LoadMore()}
        />
    </CustomSafeArea>
  )
}

export default UPIPayList