import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import AgentCard from '../../components/AgentCard'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

const Delivery = ({route}) => {
  const {nm} = route.params
  const [userList, setUserList] = useState([])
  const tknsts = useSelector((state) => state.appredux.userToken)
  const navigation = useNavigation()


  useEffect(() => {
    function fecthUserLists() {
        axios.get(`${ServerData.uri3}/deliverpartnerlistsbyagent/${tknsts}/`)
          .then(function (response) {
            setUserList(response.data)
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(function () {
            // always executed
          });
        }
        fecthUserLists()
  },[])
  return (
    <CustomSafeArea>
      <HeaderComponent title={nm} hidect={true}/>
            <ScrollView>
            {
              userList.length == 0 ?
              <Text style={{alignSelf:'center', 
                            marginTop:50,
                            fontFamily:appfont.regular,
                            color:appColor.txheadblack,
                            fontSize:12
              }}>You dont have any {nm} yet.</Text>
              :
              <View>
              {
                userList.map((item) => {
                  return(
                    <View key={item.id}>
                      <AgentCard title={`${item.user.first_name} ${item.user.last_name}`} number={item.user.username} vfstatus={item.verificationStatus} proimg={item.profileimage} onpress={() => navigation.navigate('SingleDeliveryPartner', {agntid:item.user.username, agnm:item.user.first_name, agdata:item})}/>
                    </View>
                  )
                })
              }
              </View>
            }
            </ScrollView>
    </CustomSafeArea>
  )
}

export default Delivery