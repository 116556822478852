import React, {useState, useEffect} from 'react'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import DropdownSearch from '../../components/SearchableDropdown'
import ServerData from '../../constants/other/serverData'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import CustomTextInput from '../../components/customTextInput'
import FileUploadComponent from '../../components/fileUploadComponent'
import { useSelector } from 'react-redux'
import axios from 'axios'
import DocumentCard from '../../components/DocumentCard'
import appfont from '../../constants/other/font'

const UploadDocumnets = () => {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [slectedid, setSelectedId] = useState(null)
    const [documentNumber, setDocumentNumber] = useState('')
    const [fileDatas, setFileData] = useState(null)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const storedata = useSelector((state) => state.appredux.storeData)
    const [loading, setLoading] = useState(false)
    const [fileLists, setFileLists] = useState([])


    const styles = StyleSheet.create({
        mn:{
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        txt:{
            fontSize:16,
            color:appColor.green,
            fontFamily:appfont.semibold,
            marginTop:10
        }
    })


    function getFileLists() {
        axios.get(`${ServerData.admin}/useruploadeddoc/${storedata.mobile}/`)
        .then(response => {
            setFileLists(response.data)
          })
          .catch(error => {
              console.log(error)
          });
    }

    useFocusEffect(
        React.useCallback(() => {
            getFileLists()
        },[])
    )

    const handleFileSelected = (fileData) => {
        setFileData(fileData)
        // Handle the selected file data in the parent component
      };

    const handleItemSelect = (selectedItem) => {
        setSelectedId(selectedItem.id);
      };

    function UploadFileToServer() {
        if(slectedid == null) {
            alert('Select document category')
        } else if(documentNumber == null) {
            alert('Please enter valid document number')
        } else if(fileDatas == null) {
            alert('Select valid document')
        } else {
            setLoading(true)
            const formData = new FormData()
            formData.append('file', {uri:fileDatas.uri, name:fileDatas.name, type:fileDatas.mimeType})
            formData.append('token', tknsts)
            formData.append('docnumber', documentNumber)
            formData.append('catnum', slectedid)
            axios.post(`${ServerData.admin}/uploaddocument/`, formData, {
                headers:{
                    "content-type": "multipart/form-data",
                },
                })
                .then(function (response) {
                    setLoading(false)
                    if(response.data.code == 1){
                        getFileLists()
                    }else if(response.data.code == 0){
                        alert(response.data.message)
                    }
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                });
        }
    }
    
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Upload Document'} hidect={true}/>
        <ScrollView style={styles.mn}>
        <DropdownSearch itemproperty={'name'} title={'Document Category'} onAddClick={() => navigation.navigate('addoccatgory')} onslect={handleItemSelect} urldta={`${ServerData.admin}/documentcatlists/`}/>
        {
            slectedid == null ? 
            null :
            <>
            <CustomTextInput title={'Document Number'} wd={width-48} onchange={setDocumentNumber} value={documentNumber}/>
            <FileUploadComponent onFileSelected={handleFileSelected}/>
            </>
        }
        <CustomButton title={'Upload'} wt={width-48} ht={50} mt={30} bg={appColor.primary} tc={appColor.background} onpress={() => UploadFileToServer()} ai={loading}/>

        <Text style={styles.txt}>Uploaded Documents</Text>
        <View>
            {
                fileLists.map((item) => {
                    return(
                        <View key={item.id}>
                        <DocumentCard data={item} indxnum={fileLists.indexOf(item)} hide1={true} type={1}/>
                        </View>
                    )
                })
            }
        </View>

        </ScrollView>
    </CustomSafeArea>
  )
}

export default UploadDocumnets