import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, Share } from 'react-native'
import React, {useRef} from 'react'
import CustomButton from './customButton';
import { useSelector } from 'react-redux';
import appfont from '../constants/other/font';
import appColor from '../constants/other/colors';
import { captureRef } from 'react-native-view-shot';
import * as Sharing from 'expo-sharing';
import { SvgXml } from 'react-native-svg';
import QRCode from 'react-native-qrcode-svg';
import { takeSnapshotAsync } from 'expo-av';
import ServerData from '../constants/other/serverData';
import * as Clipboard from 'expo-clipboard';





export default function QrCodeComponent({type}) {
    const storedata = useSelector((state) => state.appredux.storeData)
    const {width} = useWindowDimensions()
    const viewRef = useRef(null);

    const messageData = [
      {
        'id':1,
        'title':'Agent',
        'message':`
        🚀 Join the Gibnu Revolution as an Agent! 🚀

        Become a crucial part of our dynamic team and unleash your potential with Gibnu, the next-gen ecommerce app! As an Agent, you'll be at the forefront of innovation, connecting customers with top-quality products while enjoying flexible hours and incredible earning potential of up to ₹20 lakh a month!
        
        Ready to embark on an exciting journey? Click here to join us today: https://join.gibnu.com/${storedata.mobile}/?type=agent
        
        Don't miss out on this amazing opportunity – join Gibnu now and be part of something extraordinary! #GibnuAgent #JoinTheRevolution 🌟📱
        `
      },
      {
        'id':2,
        'title':'Dukaan',
        'message':`
        🚀 Transform Your Dukaan with Gibnu! 🚀

        Ready to take your dukaan to new heights? Gibnu is here to revolutionize the way you do business! With our cutting-edge platform, you can supercharge your sales, streamline stock management, and unleash the power of automated marketing – all in one place!
        
        Say goodbye to traditional methods and hello to efficiency! Gibnu empowers you to boost your sales like never before, while effortlessly managing your inventory and reaching more customers through automated marketing strategies.
        
        Don't let your dukaan get left behind in the digital age – join Gibnu today and watch your business soar! Click here to get started: https://join.gibnu.com/${storedata.mobile}/?type=seller
        
        Elevate your dukaan experience with Gibnu – the ultimate solution for modern merchants! #GibnuDukaan #BoostYourSales 🌟🛒
        `
      },
      {
        'id':3,
        'title':'Delivery Boy',
        'message':`
        🚚 Join Gibnu as a Delivery Hero! 🚚

        Ready to hit the road and deliver smiles? Gibnu is looking for dedicated delivery heroes to join our team! As a delivery boy, you'll be the face of Gibnu, ensuring that our customers receive their orders promptly and with a smile.
        
        With flexible hours and competitive pay, Gibnu offers you the opportunity to earn money while exploring new neighborhoods and connecting with people in your community. Whether you prefer biking, driving, or walking, there's a delivery option for everyone!
        
        Join the Gibnu family today and be a part of something bigger – delivering happiness, one order at a time. Click here to start your journey: https://join.gibnu.com/${storedata.mobile}/?type=delivery
        
        Don't miss out on this exciting opportunity to become a Gibnu delivery hero! #GibnuDelivery #DeliverSmiles 🌟📦
        `
      },
      {
        'id':4,
        'title':'Employee',
        'message':`
        🌟 Join the Gibnu Team and Elevate Your Career! 🌟

        Are you ready to embark on a journey of growth and opportunity? Gibnu is seeking passionate individuals like you to join our dynamic team! As an employee, you'll be part of a culture that values innovation, collaboration, and personal development.
        
        Whether you're a seasoned professional or just starting your career, Gibnu offers exciting opportunities for growth and advancement. From customer service to tech development, there's a place for everyone in our diverse and inclusive workplace.
        
        Join us in shaping the future of ecommerce and be a part of something truly extraordinary. Click here to explore career opportunities with Gibnu: https://join.gibnu.com/${storedata.mobile}/?type=employee
        
        Don't wait – seize the opportunity to unleash your potential with Gibnu today! #GibnuTeam #ElevateYourCareer 🚀👩‍💼👨‍💼
        `
      },
    ]


    const reflink = `https://join.gibnu.com/${storedata.mobile}/`


    const copyToClipboard = async () => {
      await Clipboard.setStringAsync(reflink);
      alert('Link copied')
    };

      const handleShare = (message) => {
        const shareOptions = {
          message: message,
        };
    
        Share.share(shareOptions)
          .then((result) => {
            if (result.action === Share.sharedAction) {
              console.log('Sharing completed');
            } else if (result.action === Share.dismissedAction) {
              console.log('Sharing cancelled');
            }
          })
          .catch((error) => console.log('Error sharing:', error));
      };

      const copyToClipboardVendor = async () => {
        await Clipboard.setStringAsync(reflinkvendor);
        alert('Link copied')
      };
  
        const handleSharevendor = () => {
          const shareOptions = {
            message: `Join Free - EARN upto 20 Lacs/month Join us in the mission to Make India Digital (Digital Dukan - Digital Kishan) Powered by GIBNU. Use my mobile number ${storedata.mobile} as referal mobile number and open seller account today. Click on the link ${reflinkvendor}`,
          };
      
          Share.share(shareOptions)
            .then((result) => {
              if (result.action === Share.sharedAction) {
                console.log('Sharing completed');
              } else if (result.action === Share.dismissedAction) {
                console.log('Sharing cancelled');
              }
            })
            .catch((error) => console.log('Error sharing:', error));
        };


      
      

    const qrData = {
        type:1,
        userid:storedata.mobile,
        user:1
    }
    const qrDataString = JSON.stringify(qrData);

    const styles = StyleSheet.create({
        rn:{
            justifyContent:'center',
            alignItems:'center',
        },
        txt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:18
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10,
            marginTop:3
        },
        txt3:{
          alignSelf:'center',
          marginTop:10,
          marginBottom:5,
          fontFamily:appfont.semibold,
          color:appColor.txheadblack,
          fontSize:12
        }
    })
  return (
    <View style={styles.rn} ref={viewRef}>
        <Text style={styles.txt1}>{storedata.name}</Text>
        <Text style={styles.txt2}>{type == 10 ? 'Wallet Id: ' : null}{storedata.mobile}</Text>
      <QRCode value={qrDataString} size={width-100} logo={require('../assets/icon.png')}/>
      <View style={{paddingTop:19}}>
        <Text style={styles.txt3}>Share referral link</Text>
        <View style={{flexDirection:'row', justifyContent:'space-between', flexWrap:'wrap', padding:10}}>
        {
          messageData.map((item) => {
            return(
              <View key={item.id}>
                <CustomButton title={item.title} wt={width/2-15} mt={10} onpress={() => handleShare(item.message)} bg={appColor.primary} tc={appColor.background}/>
              </View>
            )
          })
        }
        </View>
      </View>
      <View>
      </View>
    </View>
  )
}