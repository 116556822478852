import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { View, Text, StyleSheet, Platform } from "react-native";
import Icon from 'react-native-vector-icons/AntDesign';
import Icon2 from 'react-native-vector-icons/Feather';
import Icon3 from 'react-native-vector-icons/Ionicons';

import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";
import HomeScreen from "../screens/Tab/Home";
import OrderScreen from "../screens/Tab/Order";
import ProductScreen from "../screens/Tab/Product";
import ManageScreen from "../screens/Tab/Manage";
import AccountScreen from "../screens/Tab/Account";


const Tab = createBottomTabNavigator()

const HomeTab = () => {
    return(
        <Tab.Navigator
            screenOptions={{
           headerShown:false,
           tabBarShowLabel:false,
           tabBarStyle:{
           }
        }}
        >
        <Tab.Screen 
            name="Home"
            component={HomeScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={styles.icm}>
                        <Icon name="home" size={25} color={focused ? appColor.primary : appColor.txheadblack}/>
                        <Text style={focused ? styles.txt1 : styles.txt2}>Home</Text>
                    </View>
                )
            }}
        />
        <Tab.Screen 
            name="Manage"
            component={ManageScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={styles.icm}>
                        <Icon2 name="layers" size={25} color={focused ? appColor.primary : appColor.txheadblack}/>
                        <Text style={focused ? styles.txt1 : styles.txt2}>Manage</Text>
                    </View>
                )
            }}
        />
         <Tab.Screen 
            name="Account"
            component={AccountScreen}
            options={{
                tabBarIcon:({focused}) => (
                    <View style={styles.icm}>
                        <Icon2 name="user" size={25} color={focused ? appColor.primary : appColor.txheadblack}/>
                        <Text style={focused ? styles.txt1 : styles.txt2}>Account</Text>
                    </View>
                )
            }}
        />

        </Tab.Navigator>
    )
}

export default HomeTab

const styles = StyleSheet.create({
    icm:{
        justifyContent:'center',
        alignItems:'center'
    },
    txt1:{
        fontFamily:appfont.semibold,
        color:appColor.primary,
        fontSize:12
    },
    txt2:{
        fontFamily:appfont.semibold,
        color:appColor.txheadblack,
        fontSize:12
    },
})