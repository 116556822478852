import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import UserCard from '../../components/UserCard'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'

const OrderStatusData = [
  {
      'id':1,
      'name':'Loaction'
  },
  {
      'id':2,
      'name':'Date'
  },
]

const Storevisitor = () => {
  const {width} = useWindowDimensions()

  const [statusd, setStatusD] = useState(1)
  const [orderData, setOrderData] = useState([])

  const styles = StyleSheet.create({
    sr1:{
      height:60,
      flexDirection:'row',
      alignItems:'center',
      paddingRight:20,
      borderBottomColor:appColor.gray,
      backgroundColor:appColor.background,
      paddingLeft:24
  },
  tch:{
      paddingTop:7,
      paddingBottom:7,
      paddingLeft:15,
      paddingRight:15,
      borderWidth:2,
      borderColor:appColor.primary,
      marginRight:10,
      borderRadius:30
  },
  tx:{
      fontFamily:appfont.semibold,
      color:appColor.primary,
      fontSize:14
  },
  tch1:{
      paddingTop:7,
      paddingBottom:7,
      paddingLeft:15,
      paddingRight:15,
      borderWidth:2,
      borderColor:appColor.primary,
      marginRight:10,
      borderRadius:30,
      backgroundColor:appColor.primary
  },
  tx1:{
      fontFamily:appfont.semibold,
      color:appColor.background,
      fontSize:14
  },
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Store Visitors'} hidect={true} adbtn={true} adbtntitle={'Buy'} btn2={'Select All'}/>
        <View style={styles.sr1}>
                {
                    OrderStatusData.map((item) => {
                        return(
                            <TouchableOpacity key={item.id} style={statusd == item.id ? styles.tch1 : styles.tch} onPress={() => setStatusD(item.id)}>
                                <Text style={statusd == item.id ? styles.tx1 : styles.tx}>{item.name}</Text>
                            </TouchableOpacity>
                        )
                    })
                }
        </View>
        <UserCard />
    </CustomSafeArea>
  )
}

export default Storevisitor