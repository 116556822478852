import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Platform } from "react-native";
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";




export default function HomeSaleCard({onpress, title, numb, colorr}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        cd:{
            width:(width-40)/2,
            flex:1,
            backgroundColor:appColor.background,
            borderRadius:10,
            padding:10,
            justifyContent:'center',
            ...Platform.select({
                android: { elevation: 5 },
                ios: { 
                  shadowColor: '#000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                },
            }),
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:16,
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:20,
            marginTop:20
        }
    })
    return (
        <TouchableOpacity style={styles.cd} onPress={onpress}>
             <Text style={styles.txt}>{title}</Text>
             <Text style={styles.txt2}>{numb}</Text>
        </TouchableOpacity>
    )
}