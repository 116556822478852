import { View, Text, Modal, StyleSheet, useWindowDimensions, TouchableOpacity, ScrollView } from 'react-native'
import React from 'react'
import appColor from '../constants/other/colors'
import Icon from 'react-native-vector-icons/AntDesign'
import appfont from '../constants/other/font'
import CustomButton from './customButton'


const filtervalue = [
    {
        'id':1,
        'name':'Type'
    },
    {
        'id':2,
        'name':'Date'
    },

]


export default function FilterComponent({visible, onpress}) {
    const {width, height} = useWindowDimensions()

    const styles = StyleSheet.create({
        mn:{
            flex:1,
            backgroundColor:'rgba(0, 0, 0, 0.8)',
        },
        tch:{
            flex:1,
        },
        mm:{
            height:height-200,
            backgroundColor:appColor.background,
            paddingBottom:30
        },
        itv:{
            padding:10,
            borderBottomWidth:1,
            borderBottomColor:appColor.gray,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        mnf:{
            flexDirection:'row'
        }
    })
  return (
    <Modal
        animationType='slide'
        transparent={true}
        visible={visible}
        style={{flex:1}}
    >
        <View style={styles.mn}>
            <TouchableOpacity style={styles.tch}>

            </TouchableOpacity>
            <View style={styles.mm}>
                <View style={styles.itv}>
                    <Text style={styles.txt}>Filter</Text>
                    <TouchableOpacity onPress={onpress}>
                        <Icon name='close' size={20} color={appColor.txheadblack}/>
                    </TouchableOpacity>
                </View>
                <ScrollView>
                    <View style={styles.mnf}>
                        <View>
                            
                        </View>
                        <View></View>
                    </View>
                </ScrollView>
                <View>
                    <CustomButton title={'Apply Filter'}/>
                </View>
            </View>
        </View>
    </Modal>
  )
}