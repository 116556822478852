import { View, Text, FlatList, TouchableOpacity, StyleSheet, useWindowDimensions, Platform, Linking } from 'react-native'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import appfont from '../../constants/other/font'

const PointExchange = () => {
    const {width, height} = useWindowDimensions()
    const [pointneed, setPointNeed] = useState('')
    const [sellerData, setSellerData] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [ndadded, setNdAdded] = useState(false)
    const [loading, setLoading] = useState(false)


    const styles = StyleSheet.create({
      mn:{
        margin:10,
        padding:10,
        backgroundColor:appColor.background,
        ...Platform.select({
          android: { elevation: 5 },
          ios: { 
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
          },
        }),
        borderRadius:10,
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'
      
      },
      txt1:{
        fontFamily:appfont.semibold,
        fontSize:14,
        color:appColor.txheadblack
      },
      cl:{
        borderWidth:1,
        borderColor:appColor.green,
        marginTop:10,
        borderRadius:5,
        height:18,
        width:40,
        justifyContent:'center',
        alignItems:'center'
    },
    tc:{
        fontFamily:appfont.semibold,
        color:appColor.green,
        fontSize:10
    },
    })

    function getAppVersions(){
      if(pointneed == ''){
        alert('Please enter the number of points you need.')
      }else{
        setLoading(true)
        axios.get(`${ServerData.admin}/withdrawlist/?amount=${pointneed}`)
        .then(response => {
          setLoading(false)
          setSellerData(response.data.results)
          setNdAdded(true)
        })
        .catch(error => {
            setLoading(false)
            alert('Error')
            console.log(error)
        });
      }
    }

    const renderWithdrawCard = ({item}) => {
      return(
        <View style={styles.mn}>
          <View>
            <Text style={[styles.txt1, {textTransform:'uppercase'}]}>Available Point: {item.amount}</Text>
            <Text style={styles.txt1}>{item.user_first_name} {item.user_last_name}</Text>
          </View>
          <TouchableOpacity style={styles.cl} onPress={() => Linking.openURL(`tel:+91${item.contact_number}`)}>
                <Text style={styles.tc}>Call</Text>
            </TouchableOpacity>
        </View>
      )
    }

  return (
    <CustomSafeArea>
        <HeaderComponent title={'Receive Exchange'} hidect={true}/>
      {
            ndadded?
            <View style={{flex:1}}>
          <FlatList 
            data={sellerData}
            renderItem={renderWithdrawCard}
            keyExtractor={(item) => item.id}
            style={{flex:1}}
          />
        </View>
            :
            <View>
            <CustomTextInput title={'Gibnu Point You Need?'} mt={10} keytpe={'Number-pad'} onchange={setPointNeed} value={pointneed} />
            <CustomButton title={'Submit'} bg={appColor.primary} tc={appColor.background} ht={60} onpress={() => getAppVersions()} ai={loading}/>
        </View>
      }
    </CustomSafeArea>
  )
}

export default PointExchange