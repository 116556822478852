import { View, Text, Share } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import { WebView } from 'react-native-webview';
import HeaderComponent from '../../components/Header';
import appColor from '../../constants/other/colors';
import CustomButton from '../../components/customButton';
import { useSelector } from 'react-redux';


const LumosysCommision = () => {
    const storedata = useSelector((state) => state.appredux.storeData)

    const handleShare = () => {
        const shareOptions = {
          message: `
          🌟 Exciting Opportunity Alert! 🌟

          Hey there! Are you ready to level up your mobile app development skills and land your dream job? Lumosys is thrilled to announce our brand new Fullstack Mobile App Development course - Lumosys CodeCamp! 🚀
          
          Join us and learn from top educators from Google, Facebook, Amazon, and our very own Lumosys team. We'll be diving deep into Django and React Native, offering classes in both Hindi and English for your convenience.
          
          Why choose Lumosys CodeCamp?
          ✨ Learn from industry experts
          ✨ Master Django and React Native
          ✨ Classes available in Hindi & English
          ✨ Opportunity to get hired at Lumosys and partner companies
          
          Ready to kickstart your career in mobile app development? Sign up now for Lumosys CodeCamp and secure your future success! Click here to join: [Joining Link]
          
          Hurry, limited seats available! Don't miss out on this amazing opportunity. See you at CodeCamp! 🚀📱

          Joining Link: https://codecamp.lumosys.in/?referer=${storedata.mobile}&agency=GIBNU&coupon=GIBNU
          
          Best regards,
          Team Lumosys
          `,
        };
    
        Share.share(shareOptions)
          .then((result) => {
            if (result.action === Share.sharedAction) {
              console.log('Sharing completed');
            } else if (result.action === Share.dismissedAction) {
              console.log('Sharing cancelled');
            }
          })
          .catch((error) => console.log('Error sharing:', error));
      };

  return (
    <CustomSafeArea>
        <HeaderComponent title={'Refer & Get 70% Commison'}/>
        <WebView 
            style={{flex:1}}
            source={{uri : `https://codecamp.lumosys.in/?referer=${storedata.mobile}&agency=GIBNU&coupon=GIBNU`}}
        />
        <View style={{height:100, backgroundColor:appColor.background, justifyContent:'center', alignItems:'center', borderTopWidth:1, borderTopColor:appColor.gray}}>
            <CustomButton title={'Refer & Earn'} bg={appColor.primary} tc={appColor.background} onpress={() => handleShare()}/>
        </View>
    </CustomSafeArea>
  )
}

export default LumosysCommision