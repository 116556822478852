import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import Icon  from 'react-native-vector-icons/AntDesign'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'

export default function Webfooter() {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        ft:{
            width:width,
            paddingLeft: width > 600 ? 50 :24,
            paddingRight: width > 600 ? 50 :24,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            flexWrap:'wrap',
            marginBottom:10
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:10,
        },
        ic:{
            height:20,
            width:20,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            marginLeft:width > 600 ? 10 : 0,
            marginRight:width > 600 ? 0: 10,
        },
        rt:{
            flexDirection:'row',
            alignItems:'center',
            marginTop:width > 600 ? 0 : 10,
        }
    })
  return (
    <View style={styles.ft}>
      <Text style={styles.txt}>© 2024 GIBNU DIGITAL SOLUTIONS PRIVATE LIMITED. All rights reserved.</Text>
      <View style={styles.rt}>
        <TouchableOpacity style={styles.ic}>
            <Icon name='twitter' size={10} color={appColor.txheadblack}/>
        </TouchableOpacity>
        <TouchableOpacity style={styles.ic}>
            <Icon name='youtube' size={10} color={appColor.red}/>
        </TouchableOpacity>
        <TouchableOpacity style={styles.ic}>
            <Icon name='facebook-square' size={10} color={appColor.primary}/>
        </TouchableOpacity>
        

      </View>
    </View>
  )
}