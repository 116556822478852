import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import { WebView } from 'react-native-webview';
import HeaderComponent from '../../components/Header';


const PolicyView = ({route}) => {
    const {uri, title} = route.params;

    const styles = StyleSheet.create({
        webs:{
            flex:1,
            marginLeft:10,
            marginRight:10
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={title} hidect={true}/>
        <WebView 
            style={styles.webs}
            source={{ uri: uri }}
        />
    </CustomSafeArea>
  )
}

export default PolicyView