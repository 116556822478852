import { View, Text, useWindowDimensions } from 'react-native'
import React, {useRef} from 'react'
import LottieView from 'lottie-react-native';
import appColor from '../constants/other/colors';


export default function SuccessComponent() {
    const animation = useRef(null);
    const {width} = useWindowDimensions()
  return (
    <View style={{alignItems:'center', marginTop:10}}>
        <LottieView
            autoPlay
            ref={animation}
            style={{
            width: width/2,
            height: width/2,
            backgroundColor: appColor.background,
            }}
            source={require('../constants/img/success.json')}
        />
    </View>
  )
}