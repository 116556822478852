import { View, Text, StyleSheet, FlatList, ActivityIndicator } from 'react-native'
import React, { useState , useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import appColor from '../../constants/other/colors'
import FilterComponent from '../../components/FilterComponent'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import IncomeCard from '../../components/IncomeCard'
import { useSelector } from 'react-redux'
import appfont from '../../constants/other/font'


const EarningScreen = ({route}) => {
  const {id} = route.params;
  const [filterVisible, setFilterVisible] = useState(false)
  const [incomelist, setIncomeList] = useState([])
  const [loading, setLoading] = useState(false)

  let totalIncome = 0
  let totalCleared = 0

  for (const income of incomelist){
    totalIncome += parseFloat(income.amount)
    if(income.cleared_amount){
      totalCleared += parseFloat(income.cleared_amount)
    }
  }



  useEffect(() => {
    function fecthUserLists() {
        setLoading(true)
        axios.get(`${ServerData.uri3}/agent-general-income/?token=${id}`)
          .then(function (response) {
            setLoading(false)
            setIncomeList(response.data.income_list)
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
          })
        }
        fecthUserLists()
  },[])

  const renderIncomeCard = ({item}) => {
    return(
      <IncomeCard indata={item}/>
    )
  }

    const styles = StyleSheet.create({
        entop:{
          flexDirection:'row',
          justifyContent:'space-between',
          padding:24,
          borderBottomWidth:1,
          borderBottomColor:appColor.gray,
          backgroundColor:appColor.background
        },
        mdl:{
          justifyContent:'center',
          alignItems:'center'
        },
        txt1:{
          fontFamily:appfont.semibold,
          fontSize:14,
          color:appColor.txheadblack
        },
        txt2:{
          fontFamily:appfont.semibold,
          fontSize:14,
          color:appColor.green,
          marginTop:5
        }
    })

    const renderHeader = () => {
      return(
        <View style={styles.entop}>
          <View style={styles.mdl}>
            <Text style={styles.txt1}>Total Earning</Text>
            <Text style={styles.txt2}>₹{totalIncome}</Text>
          </View>
          <View style={styles.mdl}>
            <Text style={styles.txt1}>Cleared</Text>
            <Text style={styles.txt2}>₹{totalCleared}</Text>
          </View>
          <View style={styles.mdl}>
            <Text style={styles.txt1}>Pending</Text>
            <Text style={styles.txt2}>₹{totalIncome - totalCleared}</Text>
          </View>
        </View>
      )
    }

    const footerComponent = () => {
      return(
        <View style={{justifyContent:'center', alignItems:'center', padding:24}}>
          {
            loading ?
            <ActivityIndicator size='large' color={appColor.primary} />
            :
            <View>
              {
                incomelist.length < 1 ?
                <Text>No incomes</Text>
                :
                null
              }
            </View>
          }
        </View>
      )
    }
  return (
    <CustomSafeArea>
        <FilterComponent visible={filterVisible} />
        <HeaderComponent title={'Earning Dashboard'} hidect={true} />
            <FlatList 
              renderItem={renderIncomeCard}
              data={incomelist}
              key={(item) => item.id}
              ListHeaderComponent={renderHeader}
              ListFooterComponent={footerComponent}
              stickyHeaderIndices={[0]}
            />
    </CustomSafeArea>
  )
}

export default EarningScreen