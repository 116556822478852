import React, {useState, useEffect} from 'react';
import { View, StyleSheet, Keyboard, TouchableWithoutFeedback, Text, useWindowDimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import appColor from '../constants/other/colors';
import NetInfo from '@react-native-community/netinfo';
import appfont from '../constants/other/font';


const CustomSafeArea = ({ children, bg }) => {
    const {width} = useWindowDimensions()

    const [isConnected, setIsConnected] = useState(true);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
        setIsConnected(state.isConnected);
        });

        return () => {
        unsubscribe();
        };
    }, []);

    function dismisKeyboard() {
        Keyboard.dismiss()
    }

    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:bg ? bg : appColor.background
        },
        sfview:{
            flex:1,
        },
        ss:{
            flex:1,
            backgroundColor:bg ? bg : appColor.background
        }
    })
  return (
    <View style={styles.main}>
        <StatusBar style="dark" />
        <SafeAreaView style={styles.sfview}>
        <View style={styles.ss}>
        {children}
        {
            isConnected ?
            null
            :
            <View style={{width:width, height:15, backgroundColor:appColor.red}}>
                <Text style={{fontFamily:appfont.semibold, color:appColor.background, fontSize:8, textAlign:'center', alignSelf:"center"}}>No internet connection</Text>
            </View>
        }
        </View>
        </SafeAreaView>
    </View>
  )
};

export default CustomSafeArea;