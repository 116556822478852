import { View, Text, ScrollView, useWindowDimensions, StyleSheet, ImageBackground, Image, Share } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import QRCode from 'react-native-qrcode-svg';
import ViewShot from "react-native-view-shot";
import * as Sharing from 'expo-sharing';
import { useSelector } from 'react-redux'

const AgentId = () => {
    const {width, height} = useWindowDimensions()
    const storedata = useSelector((state) => state.appredux.storeData)

    const viewShotRef = useRef(null);

    const captureView = async () => {
        if (viewShotRef.current) {
          try {
            const uri = await viewShotRef.current.capture();
            const text = "Check out my id card!";
            await Sharing.shareAsync(uri, { text });
          } catch (error) {
            console.error('Error capturing and sharing:', error);
          }
        }
      };


    const qrData = {
        agnetName:`${storedata.name} ${storedata.last_name}`,
        mobile:storedata.mobile,
        agentid:storedata.mobile
    }

    const qrDataString = JSON.stringify(qrData);

    const styles = StyleSheet.create({
        cdmain:{
            margin:20,
            flex:1,
        },
        circle:{
            height:width/2,
            width:width/2,
            backgroundColor:'#B8B8FF',
            borderRadius:width/4,
            alignSelf:'center',
            marginTop:height/17,
            justifyContent:"center",
            alignItems:'center'
        },
        profileimg:{
            height:width/2 - 12,
            width:width/2 - 12,
            borderRadius:width/4
        },
        glogo:{
            height:height/20,
            width:width/2,
            resizeMode:"contain",
            alignSelf:'center',
            marginTop:height/25
        },
        btm:{
            flex:1,
            marginTop:height/35,
        },
        nmtxt:{
            fontFamily:appfont.bold,
            color:appColor.txheadblack,
            fontSize:height/35,
            textTransform:'uppercase',
            alignSelf:'center',
            textAlign:'center'
        },
        ptview:{
            alignSelf:'center',
            marginTop:height/100,
            paddingLeft:width/15,
            paddingRight:width/15,
            backgroundColor:'#B8B8FF',
            paddingTop:height/150,
            paddingBottom:height/150,
            borderRadius:15
        },
        pttxt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:height/80
        },
        bb:{
            flex:1,
            justifyContent:'space-between',
            marginBottom:height/25,
            marginTop:height/20,
            marginLeft:width/10,
            marginRight:width/10,
        },
        itxt:{
            fontFamily:appfont.semibold,
            fontSize:height/60,
            color:appColor.txheadblack,
            marginBottom:height/150
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Id Card'} hidect={true} adbtn={true} adbtntitle={'Share ID'} onpress={() => captureView()}/>
        <ViewShot style={styles.cdmain} ref={viewShotRef} options={{format:'png', quality:1}}>
            <ImageBackground source={require('../../constants/img/idcard.png')} style={{flex:1}} imageStyle={{resizeMode:'stretch'}}>
                <Image source={require('../../constants/img/glogo.png')} style={styles.glogo}/>
                <View style={styles.circle}>
                    <Image source={storedata.pimage ? {uri : storedata.pimage} : require('../../constants/img/ppic.png')} style={styles.profileimg}/>
                </View>
                <View style={styles.btm}>
                    <Text style={styles.nmtxt}>{storedata.name} {storedata.last_name}</Text>
                    <View style={styles.ptview}>
                        {
                            storedata.employee == 1 ?
                            <Text style={styles.pttxt}>Support Executive</Text>
                        :
                        <Text style={styles.pttxt}>{storedata.parnterLevel ? storedata.parnterLevel : 'Silver Partner'}</Text>
                        }
                    </View>
                    <View style={styles.bb}>
                        <View>
                        <Text style={styles.itxt} numberOfLines={1} ellipsizeMode='clip'>ID No: {storedata.mobile}</Text>
                        <Text style={styles.itxt} numberOfLines={1} ellipsizeMode='clip'>E-mail: {storedata.email}</Text>
                        <Text style={styles.itxt} numberOfLines={1} ellipsizeMode='clip'>Mobile: +91 - {storedata.mobile}</Text>
                        </View>
                        <View style={{alignSelf:'center'}}>
                            <QRCode value={qrDataString} size={height/10}/>
                        </View>
                    </View>
                </View>
            </ImageBackground>
        </ViewShot>
    </CustomSafeArea>
  )
}

export default AgentId