import React, { useState } from "react";
import { View, Text, useWindowDimensions, StyleSheet, Image, TouchableOpacity, TextInput } from "react-native";
import Icon from 'react-native-vector-icons/AntDesign';
import { useNavigation } from "@react-navigation/native";
import appColor from "../constants/other/colors";
import appfont from "../constants/other/font";
import Animated, { useSharedValue, withTiming, useAnimatedStyle, withSpring } from 'react-native-reanimated';




export default function HeaderComponent({ic, title, hidect, adbtn, adbtntitle, onpress, btn2, onsearch}) {
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [seacrhactive, setSearchActive] = useState(false)

    const currentHeight = useSharedValue(0);
    const headerstyle = useAnimatedStyle(() => {
        return {
          height: withTiming(currentHeight.value),
        };
      });
    
      const increaseHeight = () => {
        if(seacrhactive == false){
            setSearchActive(true)
            currentHeight.value += 40;
        }else if(seacrhactive == true){
            currentHeight.value -=40;
            setSearchActive(false)
        }
      };

    const styles = StyleSheet.create({
        hdr:{
            width:width,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            paddingLeft:20,
            paddingRight:20,
            paddingBottom:10,
            borderBottomWidth:1,
            borderColor:appColor.gray,
        },
        scrh:{
            height:40,
            borderBottomWidth:1,
            borderColor:appColor.gray,
            borderLeftWidth:1,
            borderRightWidth:1,
            borderBottomLeftRadius:5,
            borderBottomRightRadius:5,
            marginRight:10,
            marginLeft:10,
            paddingLeft:10,
            paddingRight:10,
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            overflow:"hidden"
        },
        txinpt:{
            height:40,
            width:width-100,
        },
        srcancel:{
            height:40,
            width:40,
            alignItems:'center',
            justifyContent:'center',
        },
        tch:{
            height:30,
            width:30,
            justifyContent:'center'
        },

        rtview:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            width:80
        },
        lft:{
            flexDirection:'row',
            alignItems:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.txheadblack,
            marginLeft:10
        },
        adb:{
            backgroundColor:appColor.primary,
            paddingTop:5,
            paddingBottom:5,
            paddingLeft:10,
            paddingRight:10,
            borderRadius:5
        },
        adtxt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:8
        },
        
    })

    return (
        <>
        <View style={styles.hdr}>
        <View style={styles.lft}>
            <TouchableOpacity style={styles.tch} onPress={() => navigation.goBack()}>
                <Icon name={ic ? ic : "arrowleft"} size={25} color={appColor.txheadblack}/>
            </TouchableOpacity>
            {
                title? 
                <Text style={styles.txt}>{title}</Text> : null
            }
        </View>
        {
            hidect ? null :
    
                    <TouchableOpacity style={styles.tch} onPress={() => increaseHeight()}>
                    <Icon name="search1" size={25} color={seacrhactive ? appColor.gray : appColor.txheadblack}/>
                    </TouchableOpacity>
}
        {
            adbtn ?
            <TouchableOpacity style={styles.adb} onPress={onpress}>
                <Text style={styles.adtxt}>{adbtntitle}</Text>
            </TouchableOpacity>
            :
            null
        }
        </View>

        {
            hidect ? 
            null
        :
            <Animated.View style={[styles.scrh, headerstyle]}>
            {
                seacrhactive ? 
            <>
            <TextInput 
                style={styles.txinpt}
                placeholder="Search Items"
                onChangeText={onsearch}
            />
            <TouchableOpacity style={styles.srcancel} onPress={() => increaseHeight()}>
                <Icon name="closecircleo" size={20} color={seacrhactive ? appColor.txheadblack : appColor.gray} />
            </TouchableOpacity>
            </>
            :
            null
            }
            </Animated.View>
        }
        </>
    )
}