import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, ScrollView } from 'react-native'
import React, {useState, useEffect} from 'react'
import WebSafeArea from '../../webcomponents/WebSafeArea'
import CustomTextInput from '../../../components/customTextInput'
import CustomButton from '../../../components/customButton'
import appfont from '../../../constants/other/font'
import appColor from '../../../constants/other/colors'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import Icon from 'react-native-vector-icons/Feather'


const PayHome = ({route}) => {
    const linkcode = route.params?.linkcode;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [userstatus, setUserStaus] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [amount, setAmount] = useState('')
    const [pan, setPan] = useState('')
    const [upiid, setUpiId] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [cnfaccountNumber, setCnfAccountNumber] = useState('')

    const [payeename, setPayeeName] = useState('')
    const [ifsccode, setIfscCode] = useState('')
    const [transfermethod, setTransferMethod] = useState(0)

    const [upiverifyStatus, setUpiVerifyStatus] = useState(0)
    const [verifiedupiname, setVerifiedUPianme] = useState('')
    const [ifscverifyStatus, setIFSCVerifyStatus] = useState(0)
    const [ifscbankdetails, setIFSCBankDetails] = useState('')

    const token = localStorage.getItem('paytoken');

    const [paymentdata, setPaymentData] = useState([])

    function getPaymentData(){
        axios.get(`${ServerData.uri3}/paymentbyusermobilelist/${token}/`)
            .then(function (response) {
                setModalVisible(false)
                setPaymentData(response.data)
            })
            .catch(function (error) {
                setModalVisible(false)
                alert(error)
            });
    }

    useEffect(() => {
        getPaymentData()
    },[])


    useEffect(() => {
        if (!token) {
        navigation.navigate('CreditMobileVerification')
        }
    },[])

    function validatedUPI() {
        if(upiid.includes("@")){
            axios.post(`${ServerData.uri3}/initiatecreditcardbillpayment/`, {
                upiid:upiid,
                stage:1,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if (response.data.data.validity == 1){
                        setVerifiedUPianme(response.data.data.response)
                        setUpiVerifyStatus(1)
                    }else{
                        alert(response.data.data.message)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert(error)
                });
        }else{
            alert('invalid upi id')
        }
    }

    function validateIFSC(){
        if(ifsccode.length !== 11){
            alert('Invalid IFSC Code')
        }else{
            axios.post(`${ServerData.uri3}/initiatecreditcardbillpayment/`, {
                ifsc:ifsccode,
                stage:2,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.data.code == 1){
                        setIFSCVerifyStatus(1)
                        setIFSCBankDetails(response.data.data.bank)
                    }else{
                        alert('Invalid IFSC')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert(error)
                });
        }
    }
    

    function changeUPIVerify(){
        setUpiVerifyStatus(0)
        setVerifiedUPianme('')
    }

    function changeIFSCVErify(){
        setIFSCVerifyStatus(0)
        setIFSCBankDetails('')
    }

    function initiatePaymemt(){
        if(Number(amount)<100){
            alert('Amount should be greater than ₹1000')
        }
        else if(transfermethod == 0){
            alert('Please select UPI/ Bank Account')
        }else if(transfermethod == 1 && upiid == ''){
            alert('Please enter UPI Id')
        }else if(transfermethod == 1 && upiverifyStatus == 0){
            alert('Please validate UPI ID')
        }else if(transfermethod == 2 && payeename == ''){
            alert('Please Enter Payee Name')
        }else if(transfermethod == 2 && accountNumber == ''){
            alert('Please Enter account number')
        }else if(transfermethod == 2 && accountNumber !== cnfaccountNumber){
            alert('Account no & Confirm account does not match')
        }else if(transfermethod == 2 && ifsccode == ''){
            alert('Please enter IFSC code')
        }else if(transfermethod == 2 && ifscverifyStatus == 0){
            alert('Please validate your ifsc')
        }else if(Number(amount) > 49999 && pan == ''){
            alert('Please enter PAN number of payee as transaction amount crosses ₹50,000')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.uri3}/initiatecreditcardbillpayment/`, {
                linkcode:linkcode,
                ifsc:ifsccode,
                stage:3,
                transfermode:transfermethod,
                token:token,
                upiid:upiid,
                pan:pan,
                amount:amount,
                accountno:accountNumber,
                ifsc:ifsccode,
                payeename:payeename,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1){
                        navigation.navigate('PayVarify', {paydata:response.data.data})
                    }else{
                        alert('Error, please try later.')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert(error)
                });
        }
    }


    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24,
            marginTop:20
            
        },
        tp1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
        scrl:{
            paddingTop:20,
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        scrl1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        scrlheader:{
            height:40,
            backgroundColor:'#071952',
            flexDirection:'row',
            alignItems:'center',
            paddingLeft:10,
            paddingRight:10
        },
        scrltxn:{
            height:40,
            flexDirection:'row',
            alignItems:'center',
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray,
            paddingLeft:10,
            paddingRight:10
        },
        htxt:{
            flex:1,
            textAlign:'left',
            marginRight:10,
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:10
        },
        ttxt:{
            flex:1,
            textAlign:'left',
            marginRight:10,
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:10
        }
    })
  return (
    <WebSafeArea>
        <View style={width > 600 ? styles.scrl1 : styles.scrl}>
            <View style={width > 600 ? styles.tp1 : styles.tp}>
                <Text style={styles.txt}>Credit Card</Text>
                <Text style={styles.txt2}>Pay to anyone with your credit card</Text>
            </View>
            <View style={{marginTop:10}}>
                <CustomTextInput title={'Amount'} wd={width > 600 ? width/3 : width-48} onchange={setAmount} value={amount}  keytpe={'number-pad'}/>
                <Text style={styles.txt2}>Transfer To</Text>
                <View style={{flexDirection:'row'}}>
                {
                    Number(amount) < 100000 ? 
                    <>
                    <CustomButton title={'UPI'} fs={8} ht={25} wt={80} bg={transfermethod == 1 ? appColor.primary : false} tc={transfermethod == 1 ? appColor.background : false} onpress={() => setTransferMethod(1)}/>
                    <View style={{width:10}}></View>
                    </>
                    :
                    null
                }
                <CustomButton title={'Bank Account'} fs={8} ht={25} wt={80} bg={transfermethod == 2 ? appColor.primary : false} tc={transfermethod == 2 ? appColor.background : false} onpress={() => setTransferMethod(2)}/>
                </View>

                {
                    Number(amount) < 50000 || transfermethod == 0 ? 
                    null
                    :
                    <CustomTextInput title={'Payee PAN'} wd={width > 600 ? width/3 : width-48} onchange={setPan} value={pan} disable={upiverifyStatus == 1 ? true : false}/>
                }

                {
                    transfermethod == 1 && Number(amount) < 100000 ? 
                    <>
                    <CustomTextInput title={'UPI Id'} wd={width > 600 ? width/3 : width-48} onchange={setUpiId} value={upiid} disable={upiverifyStatus == 1 ? true : false}/>
                    {
                        upiverifyStatus == 1 ? 
                        <View style={{flexDirection:"row", alignSelf:'flex-end', alignItems:'center'}}>
                            <Text style={{fontSize:8, color:appColor.green, fontFamily:appfont.semibold, marginRight:20}}>Verified: {verifiedupiname}</Text>
                            <TouchableOpacity onPress={() => changeUPIVerify()}>
                                <Icon name='edit' size={15} color={appColor.primary} />
                            </TouchableOpacity>
                        </View>
                        :
                        <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={() => validatedUPI()}>
                            <Text style={{fontFamily:appfont.semibold, color:appColor.primary, fontSize:10, textAlign:'right'}}>Verify UPI</Text>
                     </TouchableOpacity>
                    }
                    </>
                    :
                    null
                }

                {
                    transfermethod == 2 ? 
                    <>
                    <CustomTextInput title={'Payee Name'} wd={width > 600 ? width/3 : width-48} onchange={setPayeeName} value={payeename}/>
                    <CustomTextInput title={'Account Number'} wd={width > 600 ? width/3 : width-48} onchange={setAccountNumber} value={accountNumber}  keytpe={'number-pad'} st={true}/>
                    <CustomTextInput title={'Confirm Account No'} wd={width > 600 ? width/3 : width-48} onchange={setCnfAccountNumber} value={cnfaccountNumber}  keytpe={'number-pad'}/>
                    <CustomTextInput title={'IFSC Code'} wd={width > 600 ? width/3 : width-48} onchange={setIfscCode} value={ifsccode} disable={ifscverifyStatus == 1 ? true : false}/>

                    {
                        ifscverifyStatus == 1 ? 
                        <View style={{flexDirection:"row", alignSelf:'flex-end', alignItems:'center'}}>
                            <Text style={{fontSize:8, color:appColor.green, fontFamily:appfont.semibold, marginRight:20}}>{ifscbankdetails}</Text>
                            <TouchableOpacity onPress={() => changeIFSCVErify()}>
                                <Icon name='edit' size={15} color={appColor.primary} />
                            </TouchableOpacity>
                        </View>
                        :
                        <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={() => validateIFSC()}>
                            <Text style={{fontFamily:appfont.semibold, color:appColor.primary, fontSize:10, textAlign:'right'}}>Verify IFSC</Text>
                     </TouchableOpacity>
                    }
                    </>
                    :
                    null
                }
                <View style={{marginTop:20}}>
                <CustomButton title={'Proceed'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width > 600 ? width/3 : width-48}  ai={modalVisible} onpress={() => initiatePaymemt()}/>
                </View>
            </View>
        </View>
        <View style={{width:width, marginTop:10, justifyContent:'center', alignItems:'center'}}>
            <ScrollView horizontal={true}>
                <View>
                    <View style={styles.scrlheader}>
                        <Text style={styles.htxt}>Reference Number</Text>
                        <Text style={styles.htxt}>Amount</Text>
                        <Text style={styles.htxt}>Payment Status</Text>
                        <Text style={styles.htxt}>Payment Date</Text>
                        <Text style={styles.htxt}>Payee Transfer Status</Text>
                        <Text style={styles.htxt}>Transfer Date</Text>
                        <Text style={styles.htxt}>UTR Number</Text>
                    </View>
                    {
                        paymentdata.map((item) => {
                            return(
                                <TouchableOpacity key={item.id} style={styles.scrltxn}>
                                    <Text style={styles.ttxt}>{item.id}</Text>
                                    <Text style={styles.ttxt}>{item.transferamount}</Text>
                                    <Text style={styles.ttxt}>{item.transactionstatus}</Text>
                                    <Text style={styles.ttxt}>{item.transactiondate.substring(8, 10)}/{item.transactiondate.substring(5, 7)}/{item.transactiondate.substring(0, 4)}</Text>
                                    <Text style={styles.ttxt}>{item.transferstatus}</Text>
                                    <Text style={styles.ttxt}>{item.transferDate}</Text>
                                    <Text style={styles.ttxt}>{item.utrnumber}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
            </ScrollView>
        </View>
    </WebSafeArea>
  )
}

export default PayHome