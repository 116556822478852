import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity, Image, Linking } from 'react-native'
import React from 'react'
import appfont from '../constants/other/font'
import appColor from '../constants/other/colors'



const appdata = [
    {
        'id':8,
        'name':'G Chat',
        'image':require('../constants/asstes/plapp/chat.png'),
        'link':'',
    },
    {
        'id':1,
        'name':'Gibnu',
        'image':require('../constants/asstes/plapp/ecom.png'),
        'link':'https://play.google.com/store/apps/details?id=com.gibnuecomuser.in',
    },
    {
        'id':2,
        'name':'Seller',
        'image':require('../constants/asstes/plapp/shop.png'),
        'link':'https://play.google.com/store/apps/details?id=com.gibnuvendor.in',
    },
    {
        'id':3,
        'name':'Delivery Partner',
        'image':require('../constants/asstes/plapp/dlman.png'),
        'link':'',
    },
    {
        'id':4,
        'name':'Loan',
        'image':require('../constants/asstes/plapp/loan.png'),
        'link':'',
    },
    {
        'id':5,
        'name':'Loan Agent',
        'image':require('../constants/asstes/plapp/lagent.png'),
        'link':'',
    },
    {
        'id':6,
        'name':'Gibnu Ads',
        'image':require('../constants/asstes/plapp/ads.png'),
        'link':'',
    },
    {
        'id':7,
        'name':'Gibnu Jobs',
        'image':require('../constants/asstes/plapp/interview.png'),
        'link':'',
    },
]

export default function PlatformApp() {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        mn:{
            marginLeft:10,
            marginRight:10,
            marginTop:10,
            marginBottom:10,
            backgroundColor:'#071952',
            paddingTop:10,
            borderRadius:5,
            flex:1
            },
        txt:{
            fontFamily:appfont.semibold,
            fontSize:12,
            color:appColor.background,
            marginBottom:10,
            marginLeft:10
        },
        mnn:{
            width:width-20,
            flexDirection:'row',
            justifyContent:'space-between',
            flexWrap:'wrap',
            flex:1,
            alignItems:'flex-start'
        },
        mnv:{
            marginBottom:10,
            alignItems:'center',
            justifyContent:'space-between',
            flexBasis:'25%'
        },
        tp:{
            height:width/10,
            width:width/10,
            backgroundColor:'#4A55A2',
            borderRadius:5,
            justifyContent:'center',
            alignItems:'center'
        },
        img:{
            height:width/14,
            width:width/14,
            tintColor:'#C5DFF8'
        },
        txt2:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:'#C5DFF8',
            marginTop:5,
            textAlign:"center"
        }
    })
  return (
    <View style={styles.mn}>
        <Text style={styles.txt}>Our Platform Apps</Text>
        <View style={styles.mnn}>
            {
                appdata.map((item) => {
                    return(
                        <TouchableOpacity key={item.id} style={styles.mnv} onPress={() => item.link == '' ? alert(`${item.name} app will be available soon on PlayStore.`) : Linking.openURL(item.link)}>
                            <View style={styles.tp}>
                                <Image source={item.image} style={styles.img} />
                            </View>
                            <Text style={styles.txt2}>{item.name}</Text>
                        </TouchableOpacity>
                    )
                })
            }
        </View>
    </View>
  )
}