import { View, Text, StyleSheet } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import HomeSaleCard from '../../components/HomeSaleCard'


const MarketingProduct = () => {
  const [marketingData, setMarketingData] = useState([])

  const styles = StyleSheet.create({
    mm:{
      flex:1,
      justifyContent:'center',
      alignItems:'center'
    },
    mn:{
      marginLeft:10,
      marginRight:10,
      marginTop:10,
      flexDirection:'row',
      justifyContent:'space-between'
    }
  })

  return (
    <CustomSafeArea>
      <HeaderComponent title={'Marketing Products'} hidect={true}/>
      <View style={styles.mn}>
        <HomeSaleCard title={'Leads'} numb={0}/>
        <HomeSaleCard title={'Import'} numb={0}/>
      </View>
    </CustomSafeArea>
  )
}

export default MarketingProduct