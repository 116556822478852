const appColor = {
    'primary':'#2C3AD9',
    'red': '#EA5050',
    'txheadblack':'#1E1E1E',
    'txgray':'#727275',
    'gray':'#D8D8D8',
    'background':'#FFFFFF',
    'txt':'#333333',
    'green':'#24a541',
    'lightpurplr':'#C9A7EB',
    'yellow':'#FFD93D',
    'scrollgray':'#EEEEEE'
}

export default appColor