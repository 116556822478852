import { View, Text } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import QrCodeComponent from '../../components/QrCodeComponent'

const ReceiveScreen = () => {
    
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Receive Screen'} hidect={true}/>
        <View style={{justifyContent:"center", alignItems:'center', flex:1}}>
        <QrCodeComponent type={10}/>
        </View>
    </CustomSafeArea>
  )
}

export default ReceiveScreen