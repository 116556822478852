import React, { useState } from 'react'
import { View, Text, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import CustomTextInput from '../../components/customTextInput'
import CustomButton from '../../components/customButton'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import ServerData from '../../constants/other/serverData'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import WalletPinEntryModal from '../../components/mobile/WalletPinEntryModal'


const SendFundRequest = ({route}) => {
    const {type} = route.params;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [idVerified, setIdVerified] = useState(0)
    const [walletid, setWalletId] = useState('')
    const [amount, setAmount] = useState('')
    const [walletname, setWalletname] = useState('')
    const [loading, setLoading] = useState(false)
    const [walletpin, setWalletPin] = useState('')
    const [pinentryvisible, setPinEntryVisible] = useState(false)

    function checkWalletId() {
        setLoading(true)
        axios.get(`${ServerData.admin}/checkwalletid/${walletid}/`)
          .then(function (response) {
            setLoading(false)
            if(response.data.code == 1) {
                setIdVerified(1)
                setWalletname(response.data.name)

            } else if(response.data.code == 0) {
                alert(response.data.message)
            }
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
          })
          .finally(function () {
            // always executed
          });
        }
    function makePayment() {
        setLoading(true)
        axios.post(`${ServerData.admin}/transfercashback/`, {
            token:tknsts,
            walletid:walletid,
            amount:amount,
            type:type,
            })
            .then(function (response) {
                setLoading(false)
                if(response.data.code == 0) {
                    alert(response.data.message)
                }else if(response.data.code == 1) {
                    navigation.navigate('walletsuccess', {trdata:response.data.data})
                }else{
                    alert('Transaction Error')
                }
            })
            .catch(function (error) {
                setLoading(false)
                alert('Unexpected Error')
            });
    }

    function verifidedCashbackPoint(data){
        setWalletPin(data)
        setPinEntryVisible(false)
        makePayment()
    }

    function routePinchange(){
        setPinEntryVisible(false)
        navigation.navigate('ChangeWalletPin')
    }

    function handlePayment() {
        if(idVerified == 0) {
            checkWalletId()
        } else if(idVerified == 1){
            if(amount.length < 1)
            {
                alert('Enter Valid Amount')
            }else{
                setPinEntryVisible(true)
            }
        }
    }

    const styles = StyleSheet.create({
        scrl:{
            flex:1,
            marginTop:10,
        },
        ss:{
            alignItems:'center'
        },
        txtnm:{
            fontFamily:appfont.semibold,
            color:appColor.green,
            fontSize:16
        },
        txid:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:10
        }
    })
  return (
    <CustomSafeArea>
            <WalletPinEntryModal visible={pinentryvisible} onpress={() => setPinEntryVisible(false)} onpinverify={verifidedCashbackPoint} pinchangepress={() => routePinchange()} title={'TRANSFER POINTS'}/>
        <HeaderComponent title={'Send Request'} hidect={true} />
        <ScrollView style={styles.scrl}>
            <View style={styles.ss}>
            {
                idVerified == 0 ? 
                <CustomTextInput title={'Mobile Number'} onchange={setWalletId} value={walletid} keytpe={true}/>
                :
                <>
                <Text style={styles.txtnm}>{walletname}</Text>
                <Text style={styles.txid}>Mobile Number: {walletid}</Text>
                <CustomTextInput title={'Enter Amount'} onchange={setAmount} value={amount}/>
                </>
            }
            <CustomButton title={idVerified == 0 ? 'Verify Mobile' : 'Send Request'} bg={appColor.primary} wt={width-20} ht={60} tc={appColor.background} fs={18} mt={20} onpress={() => handlePayment()} ai={loading}/>
            </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default SendFundRequest