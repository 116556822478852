import { View, Text, ScrollView, StyleSheet, useWindowDimensions, TouchableOpacity, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import CustomTextInput from '../../components/customTextInput'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import CustomButton from '../../components/customButton'
import HeaderComponent from '../../components/Header'
import ProfilePicComp from '../../components/ProfilePicComp'
import * as ImagePicker from 'expo-image-picker';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { storeData } from '../../toolkit/slice'


const EditProfile = () => {
    const {width} = useWindowDimensions()
    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState(false)
    const storedata = useSelector((state) => state.appredux.storeData)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [firstName, setFirstName] = useState(storedata.name)
    const [lastName, setLastName] = useState(storedata.last_name)
    const [mobile, setMobile] = useState(storedata.mobile)
    const [email, setEmail] = useState(storedata.email)
    const [password, setPassword] = useState('')
    const [token, setToken] = useState(null)
    const [ppic, setPpic] = useState(false)
    const [profilepic, setProfilePic] = useState(false)



    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsEditing: false,
          quality: 1,
        });

        
        if (result.assets) {
            setProfilePic(result.assets[0]);
            setPpic(result.assets[0].uri)
          }
      };

      function updateProfile(){
        if(firstName.length < 2 || lastName.length < 2){
            alert('Please enter complete detail')
        } else {
            setModalVisible(true)
            const formData = new FormData()
            if(ppic !== false){
                formData.append('image', {uri: ppic, name:'adphoto', type:'image/jpeg'})
            }
            formData.append('token', tknsts)
            formData.append('first_name', firstName)
            formData.append('last_name', lastName)
            formData.append('email', email)
            axios.post(`${ServerData.uri3}/updateAgent/`, formData, {
                headers:{
                    "content-type": "multipart/form-data",
                },
            })
            .then(function (response) {
                setModalVisible(false)
                if(response.data.code === 1){
                    alert('Profile Updated')
                    dispatch(storeData(response.data.storedata))
                }
            })
            .catch(function (error) {
                setModalVisible(false)
                console.log(error)
                alert('An error occurred while updating the profile.')
            });
        }
    }
    

    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        scrl:{
            paddingTop:20,
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10

        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
        slm:{
            flexDirection:'row',
            alignItems:'center',
            marginTop:10
        },
        sl:{
            height:40,
            width:(width-40)/3,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10
        },
        sl1:{
            height:40,
            width:(width-40)/3,
            borderRadius:10,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            backgroundColor:appColor.primary,
            marginRight:10
        },
        slt:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:14
        },
        slt1:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        dropdown:{
            height:50,
            width:width-48,
            borderWidth:1,
            borderColor:appColor.txgray,
            justifyContent:'center',
            alignItems:'center',
            borderRadius:10,
            paddingLeft:10,
            marginBottom:10
        },
        mv:{
            flexDirection:'row',
            alignItems:'center'
        },
        mtch1:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10,
            backgroundColor:appColor.primary
        },
        mtch2:{
            height:40,
            width:70,
            borderWidth:1,
            borderColor:appColor.primary,
            justifyContent:'center',
            alignItems:'center',
            marginRight:10,
            borderRadius:10
        },
        mtxt1:{
            fontFamily:appfont.semibold,
            color:appColor.background,
            fontSize:12
        },
        mtxt2:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:12
        },
        rr:{
            justifyContent:'center',
            alignItems:'center'
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Update Profile'} hidect={true}/>
        <ScrollView style={styles.scrl} showsVerticalScrollIndicator={false}>
            <View style={styles.rr}>
                <ProfilePicComp title={'Profile Picture'} onpress={pickImage} img={ppic == false ? storedata.pimage : ppic}/>
            </View>
            <View style={{marginTop:10}}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                <CustomTextInput wd={(width-60)/2} title={'First Name*'} onchange={setFirstName} value={firstName}/>
                <CustomTextInput wd={(width-60)/2} title={'Last Name'} onchange={setLastName} value={lastName}/>
                </View>
                <CustomTextInput wd={width-48} title={'Mobile Number*'} onchange={setMobile} value={mobile} disable={true}/>
                <CustomTextInput wd={width-48} title={'Email*'} onchange={setEmail} value={email}/>
                <View>
                </View>

                <View style={{height:50}}></View>
                <CustomButton title={'Update'} bg={appColor.primary} tc={appColor.background} wt={width-48} ht={55} onpress={() => updateProfile()} ai={modalVisible}/>
                <View style={{height:50}}></View>

            </View>
            </ScrollView>
    </CustomSafeArea>
  )
}

export default EditProfile