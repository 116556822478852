import { View, Text, StyleSheet } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'

const MarketingSales = () => {
    const styles = StyleSheet.create({
        
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Complains'}/>
        <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
            <Text>No complains</Text>
        </View>
    </CustomSafeArea>
  )
}

export default MarketingSales