import { View, Text, StyleSheet, useWindowDimensions, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CustomButton from '../../components/customButton';
import { useNavigation } from '@react-navigation/native';
import WebSafeArea from '../webcomponents/WebSafeArea';
import appfont from '../../constants/other/font';
import appColor from '../../constants/other/colors';

const WelcomePage = ({route}) => {
    const navigation = useNavigation()
    const [referer, setRefer] = useState('')
    const {width, height} = useWindowDimensions()

    useEffect(() => {
        const storeReferer = async () => {
            if(route.params?.referer){
                setRefer(route.params.referer)
                try {
                    await AsyncStorage.setItem('referer', route.params.referer);
                  } catch (e) {
                    // saving error
                  }
            }
          };
          storeReferer()
    },[])

    useEffect(() => {
      document.title = 'Gibnu Create'
      if(route.params?.type && referer){
        const type = route.params.type
        if(type == 'agent'){
          navigation.navigate('Checkuser', {referer:referer, employee:0})
        }else if(type == 'seller'){
          navigation.navigate('Checkvendor', {referer:referer})
        }else if(type == 'employee'){
          navigation.navigate('Checkuser', {referer:referer, employee:1})
        }
      } else {
        navigation.navigate('CheckBuyer', {referer:referer})
      }
    },[referer])

    const styles = StyleSheet.create({
      mn:{
        flex:1,
        flexDirection: width > 600 ? 'row' : 'column-reverse',
      },
      img:{
        width:width >600 ? width/2.5 : width-48,
        height:width >600 ? width/2.5 :width -48,
        resizeMode:'contain'
      },
      rt:{
        width:width >600 ? width/2 :width,
        justifyContent:'center',
        alignItems:'center'
      },
      lft:{
        width: width > 600 ? width/2 : width,
        justifyContent:'center',
        paddingLeft:width > 600 ? 50 : 24,
        paddingRight:width > 600 ? 50 : 24
      },
      txt:{
        fontFamily:appfont.semibold,
        color:appColor.txheadblack,
        fontSize:30,
        
      },
      para:{
        fontFamily:appfont.regular,
        color:appColor.txheadblack,
        fontSize:18,
        marginTop:10
      },
      btn:{
        flexDirection:"row",
        justifyContent:'space-between',
        alignItems:'center',
        flexWrap:'wrap'
      },
      txt2:{
        fontFamily:appfont.semibold,
        color:appColor.green,
        fontSize:14,
        marginTop:20
      }
    })
  return (
    <WebSafeArea>
        <View style={styles.mn}>
            <View style={styles.lft}>
              <Text style={styles.txt}>Gibnu - Your One-Stop E-Commerce Platform</Text>
              <Text style={styles.para}>
              Discover Gibnu: Empowering sellers, agents, delivery boys, and customers in one dynamic e-commerce hub.
              </Text>

              <Text style={styles.txt2}>Register today as</Text>
              <View style={styles.btn}>
                <CustomButton title={'Gibnu Support Agent'} wt={width > 600 ? width/5-10 : width-48} mt={10} bg={appColor.primary} tc={appColor.background} onpress={() => navigation.navigate('Checkuser', {referer:referer, employee:0})}/>
                <CustomButton title={'Dukaan (Seller)'} wt={width > 600 ? width/5-10 : width-48} mt={10} tc={appColor.background} bg={'#6527BE'} onpress={() => navigation.navigate('Checkvendor', {referer:referer})}/>
                <CustomButton title={'Delivery Boy/Girl'} wt={width > 600 ? width/5-10 : width-48} mt={10} tc={appColor.background} bg={'#164B60'} onpress={() => alert('Not Activated')}/>
                <CustomButton title={'Gibnu Customer'} wt={width > 600 ? width/5-10 : width-48} mt={10} tc={appColor.background} bg={appColor.green} onpress={() => navigation.navigate('CheckBuyer', {referer:referer})}/>
              </View>
            </View>
            <View style={styles.rt}>
              <Image source={require('../../constants/img/s1.jpg')} style={styles.img}/>
            </View>
        </View>
    </WebSafeArea>
  )
}

export default WelcomePage