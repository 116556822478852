import { View, Text, FlatList, ActivityIndicator } from 'react-native'
import React from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import { useState, useEffect } from 'react'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import UserCard from '../../../components/UserCard'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'

const LeadViewer = ({route}) => {
    const {id, leadtype} = route.params;
    const [loading, setLoading] = useState(false)
    const [pagenumber, setPagenumber] = useState(1)
    const [loadmore, setLoadMore] = useState(true)
    const [leadlist, setLeadList] = useState([])

    const loadmorewhenEnd = () => {
        if(loadmore == true){
          setPagenumber(pagenumber+1)
        }
      }

    const renderLeads = ({item}) => {
        return(
            <View>
                    <UserCard time={item.timestamp} city={item.buyer.city} mobile={item.buyer.user.username} email={item.buyer.user.email} fname={item.buyer.user.first_name} lname={item.buyer.user.last_name} />
            </View>
        )
    }

    const renderFooter = () =>{
        return(
            <View>
                {
                    loading ? 
                    <ActivityIndicator size='large' color={appColor.primary}/>
                    :
                    <Text style={{fontFamily:appfont.semibold, fontSize:12, alignSelf:'center', marginTop:10}}>No More Leads</Text>
                }
            </View>
        )
    }
    
    

      useEffect(() => {
        function fetchLeadList() {
            setLoading(true)
            axios.get(`${ServerData.uri3}/eventbyagent/?page=${pagenumber}&vendorid=${id}&type=${leadtype}`)
              .then(function (response) {
                setLoading(false)
                const newData = response.data.results;
                setLeadList(prevList => [...prevList, ...newData])
              })
              .catch(function (error) {
                setLoading(false)
                console.log(error)
                setLoadMore(false)
              })
            }
            fetchLeadList()
      },[pagenumber])
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Leads'} hidect={true}/>
        <FlatList 
            data={leadlist}
            keyExtractor={item => item.id}
            renderItem={renderLeads}
            onEndReached={loadmorewhenEnd}
            ListFooterComponent={renderFooter}
        />
    </CustomSafeArea>
  )
}

export default LeadViewer