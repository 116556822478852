import React from "react";
import { View, Text, useWindowDimensions, StyleSheet, TouchableOpacity, Image } from "react-native";
import appColor from "../constants/other/colors";
import Icon from 'react-native-vector-icons/Feather';
import appfont from "../constants/other/font";




export default function UploadPhotoComponent({onpress, title, img}) {
    const {width} = useWindowDimensions()

    const styles = StyleSheet.create({
        cm:{
            height:width/4,
            width:width/4,
            borderWidth:1,
            borderColor:appColor.gray,
            borderRadius:10,
            marginBottom:5,
            justifyContent:'center',
            alignItems:'center'
        },
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:14
        },
        imgg:{
            height:width/4-20,
            width:width/4-20,
            borderRadius:10,
        }
    })
    return (
        <View>
            <TouchableOpacity style={styles.cm} onPress={onpress}>
                {
                    img?
                    <Image source={{uri:img.uri}} style={styles.imgg} />
                    :
                <Icon name="camera" size={width/9} color={appColor.txgray} />
                }
            </TouchableOpacity>
            <Text style={styles.txt}>{title}</Text>
        </View>
    )
}