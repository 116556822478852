import { View, Text, ScrollView, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import AgentCard from '../../components/AgentCard'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import DocumentCard from '../../components/DocumentCard'

const AgentListView2 = ({route}) => {
  const {nm, type, usrname} = route.params
  const navigation = useNavigation()
  const [userList, setUserList] = useState([])
  const tknsts = useSelector((state) => state.appredux.userToken)
  const [loading, setLoading] = useState(false)
  const [pagenumber, setPagenumber] = useState(1)
  const [loadmore, setLoadMore] = useState(true)

  const loadmorewhenEnd = () => {
    if(loadmore == true){
      setPagenumber(pagenumber+1)
    }
  }

  useEffect(() => {
    function fecthUserLists() {
        setLoading(true)
        axios.get(`${ServerData.uri3}/agentListView/${usrname}/?page=${pagenumber}`)
          .then(function (response) {
            setLoading(false)
            const newData = response.data.results;
            setUserList(prevList => [...prevList, ...newData])
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
            setLoadMore(false)
          })
        }
        fecthUserLists()
  },[pagenumber])

  const renderAgentCard = ({item}) => {
    return(
      <View style={{marginBottom:10}}>
          <AgentCard title={`${item.user.first_name} ${item.user.last_name}`} number={item.user.username} vfstatus={item.verificationStatus} proimg={item.profileimage} teamsize={item.total_agents_under} onpress={() => navigation.navigate('singleAgentScreen2', {agntid:item.user.username, agnm:item.user.first_name, agdata:item})}/>
      </View>
    )
  }

  const renderHeader = () => {
    return(
     <View>
      { loading ? null :
        userList.length == 0 ? 
        <Text style={{textAlign:"center", alignSelf:"center", fontFamily:appfont.regular, fontSize:10}}>No team agents</Text>
        :
        null
        }
     </View>
    )
  }


  const renderFooter = () => {
    return(
      <View>
        {
          loading ? 
          <ActivityIndicator size='large' color={appColor.primary} />
          :
          null
        }
      </View>
    )
  }


  return (
    <CustomSafeArea>
      <HeaderComponent title={`Team of ${nm}`} hidect={true}/>
          <FlatList 
                  data={userList}
                  renderItem={renderAgentCard}
                  keyExtractor={(item, index) => index.toString()}
                  ListHeaderComponent={renderHeader}
                  ListFooterComponent={renderFooter}
                  onEndReached={loadmorewhenEnd}
                  onEndReachedThreshold={0.5}
                />
    </CustomSafeArea>
  )
}

export default AgentListView2