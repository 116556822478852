import { View, Text, StyleSheet, useWindowDimensions, TouchableOpacity } from 'react-native'
import React, {useState, useEffect} from 'react'
import WebSafeArea from '../../webcomponents/WebSafeArea'
import CustomTextInput from '../../../components/customTextInput'
import CustomButton from '../../../components/customButton'
import appfont from '../../../constants/other/font'
import appColor from '../../../constants/other/colors'
import { useNavigation } from '@react-navigation/native'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'

const PaymentMobileVerify = ({route}) => {
    const linkcode = route.params?.linkcode;
    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [mobile, setMobile] = useState()
    const [userstatus, setUserStaus] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [otp, setOtp] = useState('')
    const [otpid, setOtpId] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    useEffect(() => {
        const token = localStorage.getItem('paytoken');
        if (token) {
        navigation.navigate('PayHome', {linkcode:linkcode})
        }
    },[])

    function handleMobileInput(){
        if (userstatus == 0){
            if (mobile.length !== 10){
                alert("Enter valid mobile number")
            }else{
                setModalVisible(true)
            axios.post(`${ServerData.uri3}/checkpayuser/`, {
                mobile:mobile,
                stage:1,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1){
                        setUserStaus(1)
                        setOtpId(response.data.tmpid)
                    }if(response.data.code == 2){
                        setUserStaus(2)
                        setOtpId(response.data.tmpid)
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert('error')
                });
            }
        }
    }

    function handleResendOtp(){
        axios.post(`${ServerData.uri3}/checkpayuser/`, {
            mobile:mobile,
            stage:3,
            tmpid:otpid
            })
            .then(function (response) {
                setModalVisible(false)
                alert(response.data.message)
            })
            .catch(function (error) {
                setModalVisible(false)
                alert(error)
            });
    }

    function handleUserCreation(){
        if(otp.length !== 4){
            alert('Enter Valid Otp')
        }else{
            axios.post(`${ServerData.uri3}/checkpayuser/`, {
                mobile:mobile,
                stage:2,
                tmpid:otpid,
                otp:otp,
                firstName:firstName,
                lastName:lastName,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 5){
                        localStorage.setItem('paytoken', response.data.token);
                        navigation.navigate('PayHome', {linkcode:linkcode})
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                    alert(error)
                });
        }
    }


    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24,
            marginTop:20
            
        },
        tp1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
        tp2:{
            alignItems:'center'
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
        scrl:{
            paddingTop:20,
            marginLeft:24,
            marginRight:24,
            flex:1
        },
        scrl1:{
          width:width/3,
          alignSelf:'center',
          marginTop:50
        },
    })

  return (
    <WebSafeArea>
            <View style={width > 600 ? styles.scrl1 : styles.scrl}>
            <View style={width > 600 ? styles.tp1 : styles.tp}>
                <Text style={styles.txt}>Credit Card</Text>
                <Text style={styles.txt2}>Pay to anyone with your credit card</Text>
            </View>
            <View style={{marginTop:10}}>
                
                    <CustomTextInput title={'Enter your mobile number'} wd={width > 600 ? width/3 : width-48} onchange={setMobile} value={mobile} disable={userstatus == 0 ? false : true} keytpe={'number-pad'}/>

                    {
                        userstatus > 0 ? 
                        <>
                        <CustomTextInput title={'Enter OTP'} wd={width > 600 ? width/3 : width-48} onchange={setOtp} value={otp} keytpe={'number-pad'} mx={4}/>
                        <TouchableOpacity style={{alignSelf:'flex-end'}} onPress={() => handleResendOtp()}>
                            <Text style={{fontFamily:appfont.semibold, color:appColor.primary, fontSize:10, textAlign:'right'}}>Resend Otp</Text>
                        </TouchableOpacity>
                        </>
                        :
                        null
                    }
                    {
                        userstatus > 1 ?
                        <View style={{flexDirection:'row', justifyContent:'space-between', flex:1}}>
                        <CustomTextInput wd={width > 600 ? width/6-10 : (width-60)/2} title={'First Name*'} onchange={setFirstName} value={firstName}/>
                        <CustomTextInput wd={width > 600 ? width/6-10 : (width-60)/2} title={'Last Name'} onchange={setLastName} value={lastName}/>
                        </View>
                        :
                        null
                    }
                <View style={{marginTop:20}}>
                <CustomButton title={userstatus == 0 ? 'Proceed' : 'Verify'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width > 600 ? width/3 : width-48}  ai={modalVisible} onpress={() => userstatus == 0 ? handleMobileInput() : handleUserCreation()}/>
                </View>
            </View>
            </View>
        </WebSafeArea>
  )
}

export default PaymentMobileVerify