import { View, Text, ScrollView, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import HeaderComponent from '../../components/Header'
import AgentCard from '../../components/AgentCard'
import appfont from '../../constants/other/font'
import appColor from '../../constants/other/colors'
import axios from 'axios'
import ServerData from '../../constants/other/serverData'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import DocumentCard from '../../components/DocumentCard'

const AgentListView = ({route, navigation}) => {
  const {nm, type, usrname, emrequest} = route.params
  const [userList, setUserList] = useState([])
  const tknsts = useSelector((state) => state.appredux.userToken)
  const [loading, setLoading] = useState(false)
  const [pagenumber, setPagenumber] = useState(1)
  const [loadmore, setLoadMore] = useState(true)
  const [searchtext, setSearchText] = useState('&@876_98_se')

  const loadmorewhenEnd = () => {
    if(loadmore == true){
      setPagenumber(pagenumber+1)
    }
  }

  function searchOnTextChange(){
        setLoading(true)
        axios.get(`${ServerData.uri3}/agentListView/${usrname}/?search=${searchtext}&employee=${emrequest}`)
          .then(function (response) {
            setLoading(false)
            const newData = response.data.results;
            setUserList(newData)
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
          })
  }

  useEffect(() => {
    if(searchtext !== '&@876_98_se'){
      searchOnTextChange()
    }
  },[searchtext])


  useEffect(() => {
    function fecthUserLists() {
        setLoading(true)
        axios.get(`${ServerData.uri3}/agentListView/${usrname}/?page=${pagenumber}&search=${searchtext}&employee=${emrequest}`)
          .then(function (response) {
            setLoading(false)
            const newData = response.data.results;
            setUserList(prevList => [...prevList, ...newData])
          })
          .catch(function (error) {
            setLoading(false)
            console.log(error)
            setLoadMore(false)
          })
        }
        fecthUserLists()
  },[pagenumber])

  const renderAgentCard = ({item}) => {
    return(
      <View style={{marginBottom:10}}>
          <AgentCard title={`${item.user.first_name} ${item.user.last_name}`} number={item.user.username} vfstatus={item.verificationStatus} proimg={item.profileimage} teamsize={item.total_agents_under} onpress={() => navigation.push('singleAgentScreen', {agntid:item.user.username, agnm:item.user.first_name, agdata:item, em:emrequest})}/>
      </View>
    )
  }

  const renderHeader = () => {
    return(
     <View>
      { loading ? null :
        userList.length == 0 ? 
        <Text style={{textAlign:"center", alignSelf:"center", fontFamily:appfont.regular, fontSize:10}}>No team agents</Text>
        :
        null
        }
     </View>
    )
  }

  const renderFooter = () => {
    return(
      <View>
        {
          loading ? 
          <ActivityIndicator size='large' color={appColor.primary} />
          :
          null
        }
      </View>
    )
  }


  return (
    <CustomSafeArea>
      <HeaderComponent title={emrequest == 1 ? 'Employees':'Agents'} onsearch={setSearchText}/>
                <FlatList 
                  data={userList}
                  renderItem={renderAgentCard}
                  keyExtractor={(item, index) => index.toString()}
                  ListHeaderComponent={renderHeader}
                  ListFooterComponent={renderFooter}
                  onEndReached={loadmorewhenEnd}
                  onEndReachedThreshold={0.5}
                />
    </CustomSafeArea>
  )
}

export default AgentListView