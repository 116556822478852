import { View, Text, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import HeaderComponent from '../../../components/Header'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import VendorInfoCard from '../../../components/VendorInfoCard'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'
import CustomButton from '../../../components/customButton'
import { useSelector } from 'react-redux'
import ServerData from '../../../constants/other/serverData'
import axios from 'axios'
import DocumentCard from '../../../components/DocumentCard'
import { useNavigation } from '@react-navigation/native'

const SingleDeliveryPartner = ({route}) => {
    const {width} = useWindowDimensions()
    const {agntid, agnm, agdata} = route.params
    const [loading, setLoading] = useState(false)
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [fileLists, setFileLists] = useState([])
    const navigation = useNavigation()

    function getFileLists() {
      axios.get(`${ServerData.admin}/useruploadeddoc/${agntid}/`)
      .then(response => {
          setFileLists(response.data)
        })
        .catch(error => {
            console.log(error)
        });
  }

  useEffect(() => {
      getFileLists()
  },[])

    

    function agentApprove(id){
      setLoading(true)
      axios.post(`${ServerData.uri3}/deliverPartnerVerify/`, {
          token:tknsts,
          agentid:agntid,
          type:id
      }).then(response => {
          setLoading(false)
          if(response.data.code == 1){
              alert(response.data.message)
          }
        })
        .catch(error => {
          setLoading(false)
            console.log(error)
        });
  }

    const styles = StyleSheet.create({
      btmarea:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginLeft:10,
        marginRight:10
    }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={`Partner: ${agnm}`} hidect={true}/>
        <VendorInfoCard name={`${agdata.user.first_name} ${agdata.user.last_name}`} mobile={agdata.user.username} address={`${agdata.addressLine1}, ${agdata.addressLine2}, ${agdata.city}, ${agdata.state}, ${agdata.pincode}`} image={agdata.profileimage}/>
        <ScrollView>
        <View style={{marginLeft:10, marginRight:10}}>
            {
                fileLists.map((item) => {
                    return(
                        <View key={item.id} style={{marginBottom:5}}>
                        <DocumentCard data={item} indxnum={fileLists.indexOf(item)} hide1={true}/>
                        </View>
                    )
                })
            }
        </View>
        </ScrollView>
        <View style={styles.btmarea}>
            <CustomButton title={'Approve'} wt={width/2-15} bg={appColor.green} tc={appColor.background} onpress={() => agentApprove(1)} ai={loading}/>
            <CustomButton title={'De Activate'} wt={width/2-15} bg={appColor.red} tc={appColor.background} onpress={() => agentApprove(2)} ai={loading}/>

        </View>

    </CustomSafeArea>
  )
}

export default SingleDeliveryPartner