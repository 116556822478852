import { View, Text, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import DocumentCard from '../../../components/DocumentCard'
import VendorInfoCard from '../../../components/VendorInfoCard'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import HomeSaleCard from '../../../components/HomeSaleCard'
import CustomButton from '../../../components/customButton'
import appColor from '../../../constants/other/colors'
import { useSelector } from 'react-redux'

const AgentDocuments = ({route}) => {
    const tknsts = useSelector((state) => state.appredux.userToken)
    const {width} = useWindowDimensions()
    const {agntid, agnm} = route.params
    const [loading, setLoading] = useState(false)
    const [fileLists, setFileLists] = useState([])
    const navigation = useNavigation()

    function agentApprove(id){
        setLoading(true)
        axios.post(`${ServerData.uri3}/agentverify/`, {
            token:tknsts,
            agentid:agntid,
            type:id
        }).then(response => {
            setLoading(false)
            alert(response.data.message)
          })
          .catch(error => {
            setLoading(false)
              console.log(error)
          });
    }

    function getFileLists() {
      axios.get(`${ServerData.admin}/useruploadeddoc/${agntid}/`)
      .then(response => {
          setFileLists(response.data)
        })
        .catch(error => {
            console.log(error)
        });
  }

  useFocusEffect(
    React.useCallback(() => {
        getFileLists()
    },[])
  )

  const styles = StyleSheet.create({
    op:{
        marginLeft:10,
        marginRight:10,
        flexDirection:'row',
        justifyContent:'space-between',
        marginBottom:15
    },
    btmarea:{
        flexDirection:'row',
        justifyContent:'space-between',
        marginLeft:10,
        marginRight:10
    }
  })
  return (
    <CustomSafeArea>
        <HeaderComponent title={`Agent: ${agnm}`} hidect={true}/>
        <ScrollView>
        <View style={{marginLeft:10, marginRight:10}}>
            {
                fileLists.map((item) => {
                    return(
                        <View key={item.id} style={{marginBottom:5}}>
                        <DocumentCard data={item} indxnum={fileLists.indexOf(item)} hide1={true} type={2}/>
                        </View>
                    )
                })
            }
        </View>
        </ScrollView>
        <View style={styles.btmarea}>
            <CustomButton title={'Approve'} wt={width/2-15} bg={appColor.green} tc={appColor.background} onpress={() => agentApprove(1)} ai={loading}/>
            <CustomButton title={'De Activate'} wt={width/2-15} bg={appColor.red} tc={appColor.background} onpress={() => agentApprove(2)} ai={loading}/>

        </View>
    </CustomSafeArea>
  )
}

export default AgentDocuments