import { View, Text } from 'react-native'
import React from 'react'

const ServiceDetailsScreen = () => {
  return (
    <View>
      <Text>ServiceDetailsScreen</Text>
    </View>
  )
}

export default ServiceDetailsScreen