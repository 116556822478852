import React, {useState, useEffect} from "react";
import { View, StyleSheet, useWindowDimensions, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import CustomSafeArea from "../../components/CustomSfview";
import { useNavigation } from "@react-navigation/native";
import appColor from "../../constants/other/colors";
import appfont from "../../constants/other/font";
import CustomTextInput from "../../components/customTextInput";
import CustomButton from "../../components/customButton";
import { useDispatch } from "react-redux";
import { userToken } from "../../toolkit/slice";
import axios from "axios";
import ServerData from "../../constants/other/serverData";
import * as SecureStore from 'expo-secure-store';
import HeaderComponent from "../../components/Header";

const ForgotPassword = () => {

    const navigation = useNavigation()
    const {width} = useWindowDimensions()
    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [token, setToken] = useState(null)
    const [otp, setOtp] = useState('')
    const [stage, steStage] = useState(1)
    const [tmuserid, settmuserid] = useState(null)
    const [resendLoading, setResendLoading] = useState(false)


    function resendOtp(){
        setResendLoading(true)
        axios.post(`${ServerData.admin}/resendotp/`, {
            tmid:tmuserid,
            })
            .then(function (response) {
                setResendLoading(false)
                alert(response.data.message)
            })
            .catch(function (error) {
                setResendLoading(false)
                console.log(error)
            });
    }


    function conditionalReset(){
        if(stage == 1){
            getUserOtp()
        }else{
            resetPassword()
        }
    }

    function getUserOtp(){
        if(mobile.length !== 10){
            alert('Please enter valid mobile number')
        }else{
            setModalVisible(true)
            axios.post(`${ServerData.admin}/resetpassword/`, {
                username:mobile,
                stage:stage,
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1){
                        settmuserid(response.data.id)
                        steStage(2)
                    }
                    else{
                        alert('User not found')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                });
        }
    }

    function resetPassword(){
        if(otp.length !== 4){
            alert('Please enter valid otp')
        }else if(password !== password2 || password.length < 4){
            alert('Please enter valid otp')
        }
        else{
            setModalVisible(true)
            axios.post(`${ServerData.admin}/resetpassword/`, {
                username:tmuserid,
                stage:stage,
                password:password,
                otp:otp
                })
                .then(function (response) {
                    setModalVisible(false)
                    if(response.data.code == 1){
                        alert('Password reset complete')
                        navigation.goBack()
                    }else{
                        alert('Invalid OTP')
                    }
                })
                .catch(function (error) {
                    setModalVisible(false)
                });
        }
    }


    const styles = StyleSheet.create({
        txt:{
            fontFamily:appfont.semibold,
            color:appColor.txheadblack,
            fontSize:30,
            marginBottom:10
        },
        txt2:{
            fontFamily:appfont.semibold,
            color:appColor.txgray,
            fontSize:12,
            marginBottom:10
        },
        tp:{
            marginLeft:24,
            marginRight:24
        },
        tp2:{
        },
        snv:{
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            marginTop:10
        },
        txt1:{
            fontFamily:appfont.regular,
            fontSize:14,
            color:appColor.txheadblack
        },
        txt5:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.primary
        },
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Forgot Password'} hidect={true} />
            <View style={{marginTop:10}}></View>
            <View style={styles.tp}>
                <Text style={styles.txt2}>{stage == 1 ? 'Enter mobile number to get otp!' : 'We have sent verification otp to your mobile!'}</Text>
            </View>
            <View style={styles.tp2}>
                <CustomTextInput title={'Mobile Number'} wd={width-48} onchange={setMobile} disable={stage == 1 ? false : true}/>
                {
                    stage == 1 ? 
                    null
                    :
                <>
                <CustomTextInput title={'Enter Otp'} wd={width-48} onchange={setOtp} keytpe={'number-pad'}/>
                {
                    resendLoading ? 
                <ActivityIndicator size='small' color={appColor.primary} style={{alignSelf:'flex-end', marginRight:24}} />
                :
                <TouchableOpacity style={{alignSelf:'flex-end', marginRight:24}} onPress={() => resendOtp()}>
                    <Text style={styles.txt5}>Resend OTP</Text>
                </TouchableOpacity>
                }

                <View style={{flexDirection:'row', justifyContent:'space-between', paddingLeft:24, paddingRight:24}}>
                <CustomTextInput wd={width/2-30} title={'Password'} onchange={setPassword} st={true}/>
                <CustomTextInput wd={width/2-30} title={'Confirm Password'} st={true} onchange={setPassword2}/>
                </View>
                </>
                }
                <View style={{marginTop:20}}>
                <CustomButton title={'Verify'} bg={appColor.primary} tc={appColor.background} ht={55} wt={width-48} onpress={() => conditionalReset()} ai={modalVisible}/>
                </View>
            </View>
    </CustomSafeArea>
  )
}

export default ForgotPassword