const weblinking = {
    config: {
      screens: {
        Webhome: '/:referer?',
        SignUp:'/signup',
        Checkuser:'/checkuser/:referer?',
        AppPage:'/appdownload',
        Checkvendor:'/checkvendor',
        CheckBuyer:'/checkbuyer',
        CheckBuyer:'/vref/:referer?',
        VendorSignUp:'/sellersignup',
        UserSignupPage:'/usersignup',
        BusinessCatAdd:'/businesscategoryadd',
        MainCatAdd:'/maincategoryadd',
        VendorAppPage:'/vendorapppage',
        CustomerAppPage:'/customerapppage',
        CreditMobileVerification:'/creditcard/:linkcode?',
        PayHome:'/payhome',
        PayVarify:'/payverify',
        ResetPasswordWeb:'/passwordreset',
        PasswordSuccess:'/passwordsuccess',
        ProductRefScreen:'/productref',
        
      }
    },
  };

export default weblinking