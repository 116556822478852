import { View, Text, StyleSheet, ScrollView, useWindowDimensions } from 'react-native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import appColor from '../../constants/other/colors'
import { StatusBar } from 'expo-status-bar';
import WebHeader from './WebHeader';
import Webfooter from './Webfooter';

export default function WebSafeArea({children, bg, loading}) {
    const {width} = useWindowDimensions()
    const styles = StyleSheet.create({
        main:{
            flex:1,
            backgroundColor:bg ? bg : appColor.background
        },
        sfview:{
            flex:1,
        },
        ss:{
            flex:1,
            backgroundColor:bg ? bg : appColor.background
        }
    })
  return (
    <View style={styles.main}>
    <StatusBar style="dark" />
    <SafeAreaView style={styles.sfview}>
    <WebHeader />
    <ScrollView>
    <View style={styles.ss}>
    {children}
    </View>
    </ScrollView>
        <View style={{marginTop:10}}></View>
        <Webfooter />
    </SafeAreaView>
</View>
  )
}