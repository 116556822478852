import { View, Text, StyleSheet, useWindowDimensions, Image, Linking } from 'react-native'
import React, {useState, useEffect} from 'react'
import CustomSafeArea from '../../components/CustomSfview'
import AsyncStorage from '@react-native-async-storage/async-storage';
import CustomButton from '../../components/customButton';
import { useNavigation } from '@react-navigation/native';
import WebSafeArea from '../webcomponents/WebSafeArea';
import appfont from '../../constants/other/font';
import appColor from '../../constants/other/colors';

const VendorAppPage = ({route}) => {
    const navigation = useNavigation({route})
    const type = route.params?.type;
    const [referer, setRefer] = useState('')
    const {width, height} = useWindowDimensions()

    useEffect(() => {
        setTimeout(
          function(){
            Linking.openURL('https://play.google.com/store/apps/details?id=com.gibnuvendor.in')
          },
          5000
        )
    },[])

    const styles = StyleSheet.create({
      mn:{
        flex:1,
        flexDirection: width > 600 ? 'row' : 'column-reverse',
      },
      img:{
        width:width >600 ? width/4 : width/3,
        height:width >600 ? width/4 :width/3,
        resizeMode:'contain'
      },
      rt:{
        width:width >600 ? width/2 :width,
        justifyContent:'center',
        alignItems:'center'
      },
      lft:{
        width: width > 600 ? width/2 : width,
        justifyContent:'center',
        paddingLeft:width > 600 ? 50 : 24,
        paddingRight:width > 600 ? 50 : 24
      },
      txt:{
        fontFamily:appfont.semibold,
        color:appColor.txheadblack,
        fontSize:30,
        
      },
      para:{
        fontFamily:appfont.regular,
        color:appColor.txheadblack,
        fontSize:18,
        marginTop:10
      },
      btn:{
        flexDirection:"row",
        justifyContent:'space-between',
        alignItems:'center',
        flexWrap:'wrap'
      },
      txt2:{
        fontFamily:appfont.semibold,
        color:appColor.green,
        fontSize:14,
        marginTop:20
      }
    })
  return (
    <WebSafeArea>
        <View style={styles.mn}>
            <View style={styles.lft}>
              <Text style={styles.txt}>{type ? 'Seller account already exists' : 'Your seller account created'}</Text>
              <Text style={styles.para}>
              {
                type ? 
                'Seller account already exists  Download the app and start your journey'
                :
                'You have successfully created the seller account. Download the app and start your journey.'
              }
              </Text>
                <CustomButton title={'Download App'} wt={width > 600 ? width/5-10 : width-48} mt={30} bg={appColor.primary} tc={appColor.background} onpress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.gibnuvendor.in')}/>
            </View>
            <View style={styles.rt}>
              <Image source={require('../../constants/img/award.gif')} style={styles.img}/>
            </View>
        </View>
    </WebSafeArea>
  )
}

export default VendorAppPage