import { View, Text, StyleSheet, useWindowDimensions, Image, TouchableOpacity } from 'react-native'
import React from 'react'
import appColor from '../../constants/other/colors'
import appfont from '../../constants/other/font'
import { useNavigation } from '@react-navigation/native'

const WebHeader = () => {
    const {width, height} = useWindowDimensions()
    const token = localStorage.getItem('paytoken');
    const navigation = useNavigation()

    function handleLogout(){
        localStorage.removeItem('paytoken')
        navigation.navigate('CreditMobileVerification')
    }

    const styles = StyleSheet.create({
        mn:{
            paddingLeft: width > 600 ? 50 :24,
            paddingRight: width > 600 ? 50 :24,
            paddingTop:width > 600 ? 30 : 10,
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center'
        },
        img:{
            width: width > 600 ? 110 : 110,
            height:width > 600 ? 80 : 50,
            resizeMode:'contain',
        },
        logtxt:{
            fontFamily:appfont.semibold,
            color:appColor.primary,
            fontSize:10
        }
    })
  return (
    <View style={styles.mn}>
        <Image source={require('../asstes/weblogo.png')} style={styles.img}/>
        {
            token ? 
            <TouchableOpacity onPress={() => handleLogout()}>
                <Text style={styles.logtxt}>Logout</Text>
            </TouchableOpacity>
            :
            null
        }
    </View>
  )
}

export default WebHeader