import { View, Text, useWindowDimensions, StyleSheet, ScrollView, TouchableOpacity, Share } from 'react-native'
import React, { useState, useEffect } from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import CustomTextInput from '../../../components/customTextInput'
import CustomButton from '../../../components/customButton'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'
import axios from 'axios'
import ServerData from '../../../constants/other/serverData'
import { useSelector } from 'react-redux'
import Icon from 'react-native-vector-icons/AntDesign'
import { useNavigation } from '@react-navigation/native'


const CreditCardLinks = () => {
    const {width} = useWindowDimensions()
    const tknsts = useSelector((state) => state.appredux.userToken)
    const [commision, setCommission] = useState('0')
    const [loading, setLoading] = useState(false)
    const [linklists, setLinkLists] = useState([])
    const navigation = useNavigation()

    function createPaymentLink(){
        if(commision > 30){
            alert('Your commision can not be greater than 30%')
        }else{
            setLoading(true)
            axios.post(`${ServerData.uri3}/createcreditpaymentlink/`, {
                commision:commision,
                token:tknsts,
                })
                .then(function (response) {
                    setLoading(false)
                    getLinkLists()
                })
                .catch(function (error) {
                    setLoading(false)
                    console.log(error)
                });
        }
    }

    function getLinkLists(){
        setLoading(true)
        axios.get(`${ServerData.uri3}/agentpaymentlinks/${tknsts}/`)
            .then(function (response) {
                setLoading(false)
                setLinkLists(response.data)
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error)
            });
    }

    useEffect(() => {
        getLinkLists()
    },[])
    
    const handleShare = (link) => {
        const paylink = `https://join.gibnu.com/creditcard/?linkcode=${link}`
        const shareOptions = {
          message: `Transfer money to anyone with your credit card. Clik on this link ${paylink}`,
        };
    
        Share.share(shareOptions)
          .then((result) => {
            if (result.action === Share.sharedAction) {
              console.log('Sharing completed');
            } else if (result.action === Share.dismissedAction) {
              console.log('Sharing cancelled');
            }
          })
          .catch((error) => console.log('Error sharing:', error));
      };

    const styles = StyleSheet.create({
        scrl:{
            flex:1
        },
        mn:{
            flex:1
        },
        hd:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            backgroundColor:appColor.green,
            padding:10,
            marginTop:10
        },
        hd1:{
            flexDirection:'row',
            justifyContent:'space-between',
            alignItems:'center',
            padding:10,
            borderBottomWidth:0.5,
            borderBottomColor:appColor.gray
        },
        htxt:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.background,
            flex:1
        },
        htxt1:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.txheadblack,
            flex:1
        },
        htxt2:{
            fontFamily:appfont.semibold,
            fontSize:10,
            color:appColor.primary,
        },
    })
  return (
    <CustomSafeArea>
        <HeaderComponent hidect={true} title={'Generate Payment Links'} />
        <View>
            <CustomTextInput title={'Your commision %'} keytpe={'number-pad'} onchange={setCommission} value={commision}/>
            <CustomButton title={'Generate Link'} wt={width-20} bg={appColor.primary} tc={appColor.background} ai={loading} onpress={() => createPaymentLink()}/>
        </View>
        <ScrollView style={styles.scrl}>
            <View style={styles.mn}>
                <View style={styles.hd}>
                    <Text style={styles.htxt}>LINK CODE</Text>
                    <Text style={[styles.htxt, {textAlign:'center'}]}>COMMISION</Text>
                    <Text style={[styles.htxt, {textAlign:'right'}]}>SHARE</Text>
                </View>
                {
                    linklists.map((item) => {
                        return(
                            <View style={styles.hd1} key={item.id}>
                                <TouchableOpacity style={{flex:1}} onPress={() => navigation.navigate('AgentCreditCardTransaction', {linkcode:item.linkcode})}><Text style={styles.htxt2}>{item.linkcode}</Text></TouchableOpacity>
                                <Text style={styles.htxt1}>{item.commision}%</Text>
                                <TouchableOpacity onPress={() => handleShare(item.linkcode)}>
                                    <Icon name='export' size={20}/>
                                </TouchableOpacity>
                            </View>
                        )
                    })
                }
            </View>
        </ScrollView>
    </CustomSafeArea>
  )
}

export default CreditCardLinks