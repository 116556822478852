import { View, Text, StyleSheet, TouchableOpacity, useWindowDimensions, ScrollView } from 'react-native'
import React, { useState } from 'react'
import CustomSafeArea from '../../../components/CustomSfview'
import HeaderComponent from '../../../components/Header'
import appColor from '../../../constants/other/colors'
import appfont from '../../../constants/other/font'
import CustomButton from '../../../components/customButton'
import { useNavigation } from '@react-navigation/native'

const BulkWhatsapp = () => {
    const {width} = useWindowDimensions()
    const [loading, setLoading] = useState(false)
    const navigation = useNavigation()

    const styles = StyleSheet.create({
        tp:{
            flexDirection:'row',
            justifyContent:'space-between',
        },
        lf:{
            height:140,
            borderRightWidth:1,
            width:width/2,
            borderBottomWidth:1,
            borderColor:appColor.gray,
            justifyContent:'center',
            paddingLeft:10,
            paddingRight:10
        },
        rt:{
            height:140,
            width:width/2,
            borderBottomWidth:1,
            borderColor:appColor.gray,
            justifyContent:'center',
            paddingLeft:10,
            paddingRight:10
        },
        tx1:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack
        },
        tx2:{
            fontFamily:appfont.semibold,
            fontSize:18,
            color:appColor.txheadblack,
            marginTop:10
        },
        tx3:{
            fontFamily:appfont.semibold,
            fontSize:14,
            color:appColor.txheadblack,
            marginLeft:10,
            marginTop:10
        },
        btmarea:{
            flexDirection:'row',
            justifyContent:'space-between',
            marginLeft:10,
            marginRight:10
        }
    })
  return (
    <CustomSafeArea>
        <HeaderComponent title={'Bulk Whatsapp'} hidect={true} />
        <View style={styles.mn}>
        <View style={styles.tp}>
            <View style={styles.lf}>
                <Text style={styles.tx1}>Available Whatsapp Limit</Text>
                <Text style={styles.tx2}>0</Text>
            </View>
            <View style={styles.rt}>
                <Text style={styles.tx1}>Used Whatsapp Limit</Text>
                <Text style={styles.tx2}>0</Text>
            </View>
        </View>
        </View>
        <ScrollView>
        <Text style={styles.tx3}>Message Logs</Text>
        
        </ScrollView>
        <View style={styles.btmarea}>
            <CustomButton title={'Send Message'} wt={width/2-15} bg={appColor.green} tc={appColor.background} ai={loading} onpress={() => alert("This feature is comming soon")}/>
            <CustomButton title={'Buy Package'} wt={width/2-15} bg={appColor.primary} tc={appColor.background} ai={loading} onpress={() => navigation.navigate('buyWpackage')}/>
        </View>
    </CustomSafeArea>
  )
}

export default BulkWhatsapp